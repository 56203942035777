
import { defineComponent, computed, PropType } from 'vue';
import moment from 'moment';
import OpsController from '@/clients/ops/controller';
import UserController from '@/clients/users/controller';
import { hostname } from '@/clients/utils';
import { SimplifiedState } from '@/clients/model';
import { Action } from '@/clients/action';

export default defineComponent({
  props: {
    assistant: {
      type: Object as PropType<Action>,
      required: true,
    },
    labId: String,
  },
  setup(props, { emit }) {
    const close = () => emit('close');

    const formatDate = (timestamp) => {
      if (!timestamp || timestamp === '') {
        return '--';
      }

      const date = moment(timestamp).format('M/D/YY');
      const time = moment(timestamp).format('h:mm A');
      return `${date} at ${time}`;
    };

    const createBy = computed(() => {
      if (props.assistant.common) {
        return UserController.Instance.getUser(props.assistant.common.createdBy)
          ?.name;
      }
      return '--';
    });

    const handlePreview = (id) => {
      const assistantPlayerUrl = `${hostname(
        process.env.VUE_APP_VISUALIZER_URL
      )}/#/?labId=${props.labId}&assistant=${id}&preview=true`;
      window.open(assistantPlayerUrl, '_blank');
    };

    const handleSkip = (id) => {
      OpsController.Instance.dispatchFinishAssistant(
        id,
        UserController.Instance.currentUser.id,
        new Date().toISOString()
      );
    };

    return {
      close,
      formatDate,
      createBy,
      handleSkip,
      handlePreview,
      SimplifiedState,
    };
  },
});
