
import { defineComponent, computed, PropType } from 'vue';
import { formatDateTime } from '@/pages/utils';
// @ts-ignore
import StateTag, { StateTagSize } from '@/components/StateTag.vue';
import CategorySelector from '@/components/CategorySelector.vue';
import { Job } from '@/clients/ops/model';
import { ActionType } from '@/clients/action/model';
import AssistantWhiteIcon from '@/assets/icons/assistant-white.svg';
import WorkflowWhiteIcon from '@/assets/icons/workflows-white.svg';

export default defineComponent({
  components: {
    StateTag,
    CategorySelector,
  },
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const typeIcon = computed(() =>
      props.job?.action?.actionType === ActionType.WORKFLOW
        ? WorkflowWhiteIcon
        : AssistantWhiteIcon
    );

    const dataRecordTabs = [
      {
        display: 'SUMMARY',
        name: 'summary',
        image: '',
      },
      {
        display: 'RESOURCES',
        name: 'resources',
        image: '',
      },
      {
        display: 'RESULTS',
        name: 'results',
        image: '',
      },
    ];
    const updateSelectedTab = (tab) => {
      emit('updateSelectedDataRecordTab', tab.display);
    };

    return {
      formatDateTime,
      typeIcon,
      AssistantWhiteIcon,
      updateSelectedTab,
      dataRecordTabs,
      StateTagSize,
    };
  },
});
