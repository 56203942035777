import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a875028"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-data-wrapper" }
const _hoisted_2 = { class: "custom-data-wrapper__tabs" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_bar = _resolveComponent("top-filter-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_top_filter_bar, {
        tabNames: _ctx.tabs,
        border: false,
        startingTab: _ctx.tabs[0],
        onSetTabFilter: _ctx.handleTabUpdate
      }, null, 8, ["tabNames", "startingTab", "onSetTabFilter"])
    ]),
    _createElementVNode("iframe", {
      src: `${_ctx.srcUrl}?labId=${_ctx.labId}`,
      frameborder: "0",
      id: "custom-id",
      ref: "iframeRef"
    }, null, 8, _hoisted_3)
  ]))
}