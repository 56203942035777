import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['dots', { blue: _ctx.blue }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["dot first", { blue: _ctx.blue }])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(["dot second", { blue: _ctx.blue }])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(["dot third", { blue: _ctx.blue }])
    }, null, 2)
  ], 2))
}