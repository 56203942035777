import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4f5e114"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['check-circle', { checked: _ctx.value }, { disabled: _ctx.disabled }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
  }, [
    _createElementVNode("img", {
      class: _normalizeClass(['check-circle__icon', { visible: _ctx.value }]),
      src: _ctx.checkIcon,
      alt: "selected"
    }, null, 10, _hoisted_1)
  ], 2))
}