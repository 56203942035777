export const CALCULATE_ROUTE_ON_LOGIN = 'CALCULATE_ROUTE_ON_LOGIN';
export const HOME_ROUTE = 'home';
export const LAB_OPS = 'LabOps';
export const OPS = 'Ops';
export const PLANNING = 'Planning';
export const LOADING_ROUTE = 'loading';
export const RESULTS_ROUTE = 'results';
export const LABS_ROUTE = 'labs';
export const ASSISTANTS_ROUTE = 'assistants';
export const WORKFLOWS_ROUTE = 'workflows';
export const DATA_RECORDS_ROUTE = 'Data Records';
export const REQUEST_PARAMS_ROUTE = 'Request Parameters';
export const ENTRY_SIGN_IN_ROUTE = 'entry.sign-in';
export const ENTRY_SIGN_OUT_ROUTE = 'entry.sign-out';
export const LAB_EDITOR_ROUTE = 'lab-editor';
export const LAB_ROUTE = 'lab';
// export const PROTOCOL_EDITOR_ROUTE = 'protocol-editor';
// export const WORKFLOW_MAPPER_ROUTE = 'workflow-mapper';
export const OPS_EDITOR_ROUTE = 'ops-editor';
export const JOB_DETAILS_ROUTE = 'job-details';
export const DATA_OPERATIONS_ROUTE = 'data-operations';
export const TOKENS_ROUTE = 'tokens';
export const SETTINGS = 'Settings';
export const WORKFLOW_DETAILS = 'Workflow';
export const NO_ACCESS = 'NO_ACCESS';
export const DIAG = 'Diagnostics';
export type OpsQueries =
  | 'lab'
  | 'plan'
  | 'gantt'
  | 'assistants'
  | 'workflows'
  | 'adapters'
  | 'settings'
  | 'labBuilder'
  | 'custom';
