
import { defineComponent, computed, ref, Ref } from 'vue';
import OpsController from '@/clients/ops';
import DataRecordsTable from './components/DataRecordsTable.vue';
import ResultDetails from './components/ResultDetails.vue';
import SearchBar from '@/components/SearchBar.vue';
import { Result } from './models';
export default defineComponent({
  components: {
    DataRecordsTable,
    ResultDetails,
    SearchBar,
  },
  setup() {
    const selectedResult: Ref<Result | null> = ref(null);

    const labs = computed(() => {
      return OpsController.Instance.labs;
    });

    const selectResult = (result: Result) => {
      if (result.id === selectedResult.value?.id) {
        selectedResult.value = null;
      } else {
        selectedResult.value = result;
      }
    };

    const handleRemove = (name) => {
      console.log('remove ', name);
    };

    const handleExport = async (name) => {
      console.log('export ', name);
    };

    return {
      selectedResult,
      selectResult,
      handleExport,
      handleRemove,
      labs,
    };
  },
});
