
import { defineComponent, computed } from 'vue';
import { hostname } from '@/clients/utils';

export default defineComponent({
  setup() {
    const iframeSrc = hostname(process.env.VUE_APP_SEMANTIC_UI_URL);

    return {
      iframeSrc,
      cacheBuster: computed(() => Date.now().toString()),
      title: 'Semantic Demo',
    };
  },
});
