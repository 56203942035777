
import {
  defineComponent,
  computed,
  ref,
  ComputedRef,
  Ref,
  PropType,
} from 'vue';
import { uniq } from 'lodash';
import { formatDateTime } from '@/pages/utils';
import UserController from '@/clients/users/controller';
import SearchBar from '@/components/SearchBar.vue';
// @ts-ignore
import StateTag, { StateTagSize } from '@/components/StateTag.vue';
import OpsJobDetails from '@/pages/Ops/components/OpsJobDetails.vue';
import { Job } from '@/clients/ops';

interface Request extends Job {
  createdBy: string;
  owner: string;
  createdDate: string;
  start: string;
  completed: string;
}

export default defineComponent({
  components: {
    SearchBar,
    StateTag,
    OpsJobDetails,
  },
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true,
    },
  },
  setup(props) {
    const getUser = (userId) => {
      if (userId) {
        return UserController.Instance.getUser(userId).name;
      }
      return '--';
    };

    const selectedRequest: Ref<Request | null> = ref(null);
    const setSelectedRequest = (request: Request | null) => {
      if (
        !request ||
        (selectedRequest.value && selectedRequest.value.id === request.id)
      ) {
        selectedRequest.value = null;
      } else {
        selectedRequest.value = request;
      }
    };

    const requests: ComputedRef<Request[]> = computed(() => {
      if (props.job && props.job.children?.length) {
        const children = props.job.children.map((r): Request => {
          return {
            ...r,
            createdBy: getUser(r.common.createdBy),
            owner: r.common.displayOwnerName,
            createdDate: formatDateTime(r.common.createdTimestamp).datetime,
            start: formatDateTime(r.timelog?.actual?.startTimestamp).datetime,
            completed: formatDateTime(r.timelog?.actual?.endTimestamp).datetime,
          };
        });
        const thisRequest = {
          ...props.job,
          createdBy: getUser(props.job.common.createdBy),
          owner: props.job.common.displayOwnerName,
          createdDate: formatDateTime(props.job.common.createdTimestamp)
            .datetime,
          start: formatDateTime(props.job.timelog?.actual?.startTimestamp)
            .datetime,
          completed: formatDateTime(props.job.timelog?.actual?.endTimestamp)
            .datetime,
        };
        return [thisRequest, ...children];
      }
      return [
        {
          ...props.job,
          createdBy: getUser(props.job.common.createdBy),
          owner: props.job.common.displayOwnerName,
          createdDate: formatDateTime(props.job.common.createdTimestamp)
            .datetime,
          start: formatDateTime(props.job.timelog?.actual?.startTimestamp)
            .datetime,
          completed: formatDateTime(props.job.timelog?.actual?.endTimestamp)
            .datetime,
        },
      ];
    });

    const users = computed(() => {
      const u = requests.value.map((r) => r.createdBy);
      return ['All', ...uniq(u)];
    });

    const requestFilterText = ref('');

    const stateFilter = ref('ALL');
    const stateFilterOpts = [
      { label: 'All', value: 'ALL' },
      { label: 'Pending', value: 'CREATED' },
      { label: 'Scheduled', value: 'INITIALIZED' },
      { label: 'Running', value: 'RUNNING' },
      { label: 'Canceled', value: 'CANCELLED' },
      { label: 'Completed', value: 'FINISHED' },
    ];

    const startDateFilter = ref('');
    const endDateFilter = ref('');

    const createdBy = ref('All');
    const createdDateFilter = ref('');

    const filteredRequests = computed(() => {
      let filtered = requests.value;

      if (requestFilterText.value.length > 0) {
        filtered = filtered.filter((r) =>
          r.name.toLowerCase().includes(requestFilterText.value.toLowerCase())
        );
      }

      if (stateFilter.value !== '' && stateFilter.value !== 'ALL') {
        filtered = filtered.filter((j) => j.state === stateFilter.value);
      }

      if (createdBy.value !== 'All' && createdBy.value !== '') {
        filtered = filtered.filter((j) => j.createdBy === createdBy.value);
      }

      if (createdDateFilter.value) {
        filtered = filtered.filter((r) => {
          if (r.start) {
            const rDate = new Date(r?.createdDate).getDate();
            const sDate = new Date(createdDateFilter.value).getDate();
            return rDate === sDate;
          }
        });
      }

      if (startDateFilter.value) {
        filtered = filtered.filter((r) => {
          if (r.start) {
            const jDate = new Date(r?.start).getDate();
            const sDate = new Date(startDateFilter.value).getDate();
            return jDate === sDate;
          }
        });
      }

      if (endDateFilter.value) {
        filtered = filtered.filter((r) => {
          if (r.completed) {
            const jDate = new Date(r?.completed).getDate();
            const sDate = new Date(endDateFilter.value).getDate();
            return jDate === sDate;
          }
        });
      }

      if (sortAscending.value) {
        filtered.sort((a, b) =>
          a[sortColumn.value].localeCompare(b[sortColumn.value])
        );
      } else {
        filtered.sort((a, b) =>
          b[sortColumn.value].localeCompare(a[sortColumn.value])
        );
      }

      return filtered;
    });

    const sortAscending = ref(true);
    const sortColumn = ref('name');
    const setSortValues = (column) => {
      if (column === sortColumn.value) {
        sortAscending.value = !sortAscending.value;
      } else {
        sortColumn.value = column;
        sortAscending.value = true;
      }
    };

    const sortDirection = (column) => {
      if (column === sortColumn.value && sortAscending.value === false) {
        return 'descending';
      }
      return 'ascending';
    };

    return {
      filteredRequests,
      users,
      selectedRequest,
      setSelectedRequest,
      startDateFilter,
      createdBy,
      createdDateFilter,
      endDateFilter,
      requestFilterText,
      stateFilter,
      stateFilterOpts,
      sortAscending,
      sortDirection,
      setSortValues,
      StateTagSize,
    };
  },
});
