
import { defineComponent } from 'vue';
import BlueCheck from '@/assets/icons/circle-check-blue.svg';
import BlackCheck from '@/assets/icons/black-circle-check.svg';

interface Props {
  value: boolean;
  black: boolean;
  disabled: boolean;
}

export default defineComponent({
  props: {
    value: Boolean,
    black: Boolean,
    disabled: Boolean,
  },
  setup(props: Props, { emit }) {
    const checkIcon = props.black ? BlackCheck : BlueCheck;
    const handleInput = () => {
      if (!props.disabled) {
        emit('input', !props.value);
      }
    };

    return {
      handleInput,
      checkIcon,
    };
  },
});
