
import { defineComponent, computed, ref } from 'vue';
import TokenController from '@/clients/tokens/controller';
import CreationModal from '@/pages/Tokens/CreationModal.vue';

export default defineComponent({
  components: {
    CreationModal,
  },
  setup() {
    TokenController.Instance.dispatchListTokens();
    const tokens = computed(() => TokenController.Instance.tokensList);
    const deleteToken = async (id) =>
      await TokenController.Instance.dispatchDeleteToken(id);
    const openModal = ref(false);

    return {
      tokens,
      deleteToken,
      openModal,
    };
  },
});
