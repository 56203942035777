
import { defineComponent, ref } from 'vue';
import FullPageDialog from '@/components/FullPageDialog.vue';
import FileUpload from '@/components/FileUpload.vue';
import DataController from '@/clients/data';

export default defineComponent({
  components: {
    FullPageDialog,
    FileUpload,
  },
  props: {
    open: {
      default: false,
      required: true,
    },
  },
  setup(_, { emit }) {
    const labName = ref('');
    const createLab = () => {
      if (labName.value) {
        emit('create', {
          name: labName.value,
          location: '',
          description: '',
          version: '',
        });
      } else if (jsonFile.value) {
        DataController.Instance.dispatchImportLab(jsonFile.value);
      }
      close();
    };
    const jsonFile = ref(null);
    // const fileError = ref('');
    const handleFile = (file) => {
      jsonFile.value = file;
      return file;
    };
    const close = () => {
      labName.value = '';
      jsonFile.value = null;
      emit('update:open', false);
    };

    return {
      labName,
      createLab,
      jsonFile,
      handleFile,
      close,
    };
  },
});
