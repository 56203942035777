
import {
  defineComponent,
  computed,
  ref,
  ComputedRef,
  Ref,
  PropType,
} from 'vue';
import { formatDateTime } from '@/pages/utils';
import { Job, PublicError } from '@/clients/ops/model';
import OpsController from '@/clients/ops/controller';

export default defineComponent({
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true,
    },
  },
  setup(props) {
    const workflowName = props.job?.action?.name || '--';
    const labName = OpsController.Instance.getLab(props.job.labId).name || '--';

    const openRows: Ref<string[]> = ref([]);
    const errors: ComputedRef<PublicError[]> = computed(() =>
      OpsController.Instance.getErrorsByJob(props.job.labId, props.job.id).sort(
        (a, b) => {
          if (new Date(a.timestamp) >= new Date(b.timestamp)) {
            return -1;
          } else {
            return 1;
          }
        }
      )
    );

    /**
     * used to know if a row is expanded
     * @param errorId id of the error
     * @return boolean true if row is expanded
     */
    const isRowOpen = (errorId: string): boolean => {
      return !!openRows.value.find((id) => id === errorId);
    };

    /**
     * open or close the row
     * @param errorId id of the error
     * @return none
     */
    const toggleOpenRow = (errorId: string): void => {
      if (openRows.value.find((id) => id === errorId)) {
        const index = openRows.value.findIndex((id) => id === errorId);
        openRows.value.splice(index, 1);
      } else {
        openRows.value = [...openRows.value, errorId];
      }
    };

    /**
     * return the name of the action identified in the error
     * @param actionId id of an action within the job.actions array
     * @return action name string
     */
    const getActionName = (actionId: string): string => {
      if (props.job.actions) {
        return props.job.actions?.find((a) => a.id === actionId)?.name || '--';
      }
      return '--';
    };

    return {
      workflowName,
      labName,
      openRows,
      errors,
      isRowOpen,
      toggleOpenRow,
      getActionName,
      formatDateTime,
    };
  },
});
