import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0be22e49"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "io-list" }
const _hoisted_2 = {
  key: 1,
  class: "no-io-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_MaterialsMetaDataDialog = _resolveComponent("MaterialsMetaDataDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.ios.length)
      ? (_openBlock(), _createBlock(_component_el_table, {
          key: 0,
          data: _ctx.ios,
          height: "100%",
          "row-class-name": _ctx.isRowSelected,
          "cell-class-name": _ctx.cellClass
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, { type: "expand" }, {
              default: _withCtx((props) => [
                _createVNode(_component_el_table, {
                  data: _ctx.materials(props.row),
                  size: "small",
                  "cell-class-name": () => 'job-details__io-list__materials-cell'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      prop: "name",
                      label: "name"
                    }),
                    _createVNode(_component_el_table_column, {
                      prop: "description",
                      label: "description"
                    }),
                    _createVNode(_component_el_table_column, null, {
                      default: _withCtx((mats) => [
                        (mats.row.metadata)
                          ? (_openBlock(), _createBlock(_component_el_button, {
                              key: 0,
                              circle: "",
                              icon: "icon-more-horizontal",
                              size: "small",
                              onClick: ($event: any) => (_ctx.showMaterialsMetaData(mats.row))
                            }, null, 8, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["data", "cell-class-name"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "name",
              label: "name",
              sortable: ""
            }),
            _createVNode(_component_el_table_column, {
              label: "quantity",
              sortable: "",
              "sort-by": "quantity.value"
            }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString(_ctx.getQuantityString(scope.row)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "lotnumber",
              label: "lot #",
              sortable: ""
            })
          ]),
          _: 1
        }, 8, ["data", "row-class-name", "cell-class-name"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, "Empty.")),
    _createVNode(_component_MaterialsMetaDataDialog, {
      visible: _ctx.metadataDialogVisible,
      metadata: _ctx.materialMetaData,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.metadataDialogVisible = false))
    }, null, 8, ["visible", "metadata"])
  ]))
}