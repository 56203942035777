import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-beb28b94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "job-resources" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "empty-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_files = _resolveComponent("files")!
  const _component_params = _resolveComponent("params")!
  const _component_created_jobs = _resolveComponent("created-jobs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_files, { job: _ctx.job }, null, 8, ["job"]),
      _createVNode(_component_params, {
        job: _ctx.job,
        title: "Returned values",
        "empty-text": "No returned values",
        input: false
      }, null, 8, ["job"]),
      _createVNode(_component_created_jobs, {
        jobId: _ctx.job.id,
        onUpdateSelectedJob: _ctx.updateSelectedJob
      }, null, 8, ["jobId", "onUpdateSelectedJob"]),
      _createElementVNode("div", _hoisted_3, "No results " + _toDisplayString(_ctx.isJobFinished ? '' : 'yet'), 1)
    ])
  ]))
}