
import { defineComponent, ref, computed, PropType } from 'vue';
import { formatDateTime } from '@/pages/utils';
import OpsController from '@/clients/ops';
import UserController from '@/clients/users';
import { SimplifiedState } from '@/clients/model';
import { Action, ActionType } from '@/clients/action/model';

interface ActionWithInfo extends Action {
  start?: string;
  completed?: string;
}

// interface Props {
//   action: ActionWithInfo;
//   parent?: ActionWithInfo;
//   step: string;
//   finished?: boolean;
// }

export default defineComponent({
  name: 'ActionItem',
  props: {
    action: {
      type: Object as PropType<ActionWithInfo>,
      required: true,
    },
    parent: {
      type: Object as PropType<ActionWithInfo>,
      required: false,
    },
    step: String,
    finished: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const open = ref(false);
    const toggleOpen = () => (open.value = !open.value);

    const state = computed(() => props.action?.state || props.parent?.state);

    const formattedStep = computed(() => {
      if (props.parent) {
        return props.step;
      }
      return `${props.step}.0`;
    });

    const createdBy = computed(() => {
      if (props.action.common) {
        return UserController.Instance.getUser(props.action.common.createdBy)
          .name;
      } else if (props.parent?.common?.createdBy) {
        return UserController.Instance.getUser(props.parent.common.createdBy)
          .name;
      }
      return '';
    });

    const completedBy = computed(
      () =>
        UserController.Instance.getUser(props.action.completedBy || '').name ||
        ''
    );

    const createdDate = computed(() => {
      if (props.action.common || props.parent?.common) {
        const date =
          props.action?.common?.createdTimestamp ||
          props.parent?.common?.createdTimestamp;
        if (date) {
          return formatDateTime(date).datetime;
        }
      }
      return '';
    });

    const timelogDate = (type) => {
      if (
        props.action?.timelog?.actual?.[type] ||
        props.parent?.timelog?.actual?.[type]
      ) {
        const date =
          props.action?.timelog?.actual?.[type] ||
          props.parent?.timelog?.actual?.[type];
        if (date) {
          return formatDateTime(date).datetime;
        }
      }
      return '';
    };

    const startedOnDate = timelogDate('startTimestamp');
    const completedOnDate = timelogDate('endTimestamp');

    const children = computed(() => {
      const children = OpsController.Instance.getOrderedActions(
        props.action.id
      );

      return children.map((c) => {
        return {
          ...c,
          completedBy: UserController.Instance.getUser(c.completedBy || '')
            .name,
          start: formatDateTime(c.timelog?.actual?.startTimestamp || '')
            .datetime,
          completed: formatDateTime(c.timelog?.actual?.endTimestamp || '')
            .datetime,
        };
      });
    });

    return {
      ActionType,
      SimplifiedState,
      open,
      toggleOpen,
      state,
      formattedStep,
      timelogDate,
      startedOnDate,
      completedOnDate,
      createdDate,
      createdBy,
      completedBy,
      children,
    };
  },
});
