
import { defineComponent, computed, ref, Ref } from 'vue';
import FullPageDialog from '@/components/FullPageDialog.vue';
import FileUpload from '@/components/FileUpload.vue';
import OpsController from '@/clients/ops/controller';
import DataController from '@/clients/data/controller';
import { Lab } from '@/clients/ops';

export default defineComponent({
  components: {
    FullPageDialog,
    FileUpload,
  },
  props: {
    open: {
      default: false,
      required: true,
    },
    labId: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const labRefs = computed(() => {
      return OpsController.Instance.labs;
    });

    const workflowName = ref('');
    const labSearchText = ref('');
    const labSearchFocus = ref(false);
    const selectedLab: Ref<Lab | null> = ref(null);
    const setLab = (labId) => {
      selectedLab.value = labRefs.value.find((l) => l.id === labId) || null;
      labSearchText.value = selectedLab.value?.name || '';
      labSearchFocus.value = false;
    };
    if (props.labId) {
      setLab(props.labId);
    }
    const filteredLabNames = computed(() =>
      labRefs.value
        .filter((l) =>
          l.name.toLowerCase().includes(labSearchText.value.toLowerCase())
        )
        .map((l) => {
          return { name: l.name, id: l.id };
        })
    );

    const checkFocusElement = (event) => {
      if (event?.target?.classList?.contains('tabable')) {
        labSearchFocus.value = true;
      } else {
        labSearchFocus.value = false;
      }
    };

    const checkBlur = (event) => {
      if (event?.relatedTarget?.classList?.contains('tabable')) {
        labSearchFocus.value = true;
      } else {
        labSearchFocus.value = false;
      }
    };

    const moveToPrevious = (event) => {
      if (
        event.target.previousSibling &&
        event.target.previousSibling.classList.contains('tabable')
      ) {
        event.target.previousSibling.focus();
      }
    };

    const moveToNext = (event) => {
      if (
        event.target.nextSibling &&
        event.target.nextSibling.classList.contains('tabable')
      ) {
        event.target.nextSibling.focus();
      }
    };

    const createWorkflow = () => {
      if (workflowName.value && selectedLab.value?.id) {
        emit('create', {
          name: workflowName.value,
          labId: selectedLab.value.id,
          location: '',
          description: '',
          version: '',
        });
      } else if (jsonFile.value) {
        DataController.Instance.dispatchImportWorkflows(jsonFile.value);
      }
      close();
    };

    const jsonFile = ref(null);
    // const fileError = ref('');
    const handleFile = (file) => {
      jsonFile.value = file;
      return file;
    };

    const close = () => {
      workflowName.value = '';
      selectedLab.value = null;
      jsonFile.value = null;
      emit('update:open', false);
    };

    return {
      workflowName,
      labSearchText,
      labSearchFocus,
      filteredLabNames,
      selectedLab,
      // fileError,
      jsonFile,
      setLab,
      checkFocusElement,
      checkBlur,
      moveToPrevious,
      moveToNext,
      createWorkflow,
      handleFile,
      close,
    };
  },
});
