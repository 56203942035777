import { getUserRole } from '@/clients/users/controller';
import { User, UserRole } from '@/clients/users/model';

export const getRoleLabel = (roles: UserRole[]) => {
  if (roles.includes(UserRole.ARTIFICIAL)) {
    return 'Artificial Developer';
  } else if (roles.includes(UserRole.ADMIN)) {
    return 'Admin';
  } else if (roles.includes(UserRole.MEMBER)) {
    return 'Member';
  } else if (roles.includes(UserRole.NONE)) {
    return 'No access';
  } else {
    return 'Unknown';
  }
};

export const getUserRoleLabel = (u: User) => {
  const roles = getUserRole(u);
  return getRoleLabel(roles);
};
