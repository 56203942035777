
import { defineComponent, ref, Ref } from 'vue';
import Adapters from './Adapters.vue';
import ErrorLog from './ErrorLog.vue';

export default defineComponent({
  components: {
    Adapters,
    ErrorLog,
  },
  props: {
    labId: {
      type: String,
      required: true,
    },
    parentFilterText: {
      type: String,
      required: false,
    },
    defaultTab: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const systemMonitor: Ref<HTMLElement | null> = ref(null);
    const close = () => emit('close');

    const activeMonitor = ref(props.defaultTab || '1');
    const handleSelect = (key: string) => (activeMonitor.value = key);

    // GRAB HANDLE LOGIC
    // const mouseTracker = (e) => {
    //   const diff = Math.max(window.innerHeight - e.pageY, 238);

    //   if (systemMonitor.value) {
    //     systemMonitor.value.style.height = `${diff}px`;
    //   }

    //   if (systemMonitor.value) {
    //     const tableHeight =
    //       window.innerHeight - systemMonitor.value.getBoundingClientRect().top;
    //     systemMonitor.value.style.height = `${tableHeight}px`;
    //   }
    // };

    // const handleMouseDown = (e) => {
    //   e.preventDefault();
    //   // In order to track mouse events over an iframe in this document
    //   // we have to turn off pointer events for the iframe. We then have
    //   // to turn pointer events back on in the mouse up handler.
    //   const viz = document.getElementById('vizualizer');
    //   if (viz) {
    //     viz.style.pointerEvents = 'none';
    //   }
    //   window.addEventListener('mousemove', mouseTracker);
    //   window.addEventListener('mouseup', handleMouseUp);
    // };

    // const handleMouseUp = () => {
    //   const viz = document.getElementById('vizualizer');
    //   if (viz) {
    //     viz.style.pointerEvents = 'auto';
    //   }

    //   window.removeEventListener('mousemove', mouseTracker);
    // };

    // watchEffect(() => {
    //   if (props.defaultTab) {
    //     activeMonitor.value = props.defaultTab;
    //   }
    // });

    return {
      systemMonitor,
      close,
      activeMonitor,
      handleSelect,
      // handleMouseDown,
      // handleMouseUp,
    };
  },
});
