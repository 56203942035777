export enum AdapterHealthState {
  ALL_CONNECTIONS_HEALTHY = 'ALL_CONNECTIONS_HEALTHY',
  SOME_CONNECTIONS_HEALTHY = 'SOME_CONNECTIONS_HEALTHY',
  NO_CONNECTIONS_HEALTHY = 'NO_CONNECTIONS_HEALTHY',
  NO_ADAPTORS = 'NO_ADAPTORS_CONNECTED',
  UNKNOWN = 'UNKNOWN',
}

export interface AdapterHealthObject {
  name: string;
  id: string;
  connected: boolean;
  clientHealthInfo: Record<string, string>[];
  aggregateHealth: AdapterHealthState;
}
