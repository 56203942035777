import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/assistant-white.svg'
import _imports_1 from '@/assets/icons/alert-triangle.svg'
import _imports_2 from '@/assets/icons/assistant.svg'
import _imports_3 from '@/assets/icons/canceled.svg'
import _imports_4 from '@/assets/icons/black-circle-check.svg'


const _withScopeId = n => (_pushScopeId("data-v-3dbe03d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_running_dots = _resolveComponent("running-dots")!
  const _component_MoreFilled = _resolveComponent("MoreFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['action-bar', _ctx.state, _ctx.size, _ctx.border ? 'border' : ''])
  }, [
    _createElementVNode("button", {
      class: _normalizeClass([
        'action-bar__action',
        { runnable: _ctx.isRunnable, 'has-actions': _ctx.hasActions },
      ]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.runMainAction && _ctx.runMainAction(...args)))
    }, [
      (_ctx.state === _ctx.SimplifiedState.RUNNING_NEED_ASSISTANCE)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: _normalizeClass(['action__icon', _ctx.size]),
            src: _imports_0
          }, null, 2))
        : (
          _ctx.state === _ctx.SimplifiedState.RUNNING_WITH_ERROR ||
          _ctx.state === _ctx.SimplifiedState.ERROR
        )
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: _normalizeClass(['action__icon', _ctx.size]),
              src: _imports_1
            }, null, 2))
          : (
          _ctx.state === _ctx.SimplifiedState.RUNNING ||
          _ctx.state === _ctx.SimplifiedState.RUNNING_WITH_ASSISTANCE
        )
            ? (_openBlock(), _createBlock(_component_running_dots, { key: 2 }))
            : (_openBlock(), _createElementBlock("img", {
                key: 3,
                class: _normalizeClass(['action__icon', _ctx.size]),
                src: _imports_2
              }, null, 2)),
      (_ctx.state === _ctx.SimplifiedState.CANCELLED)
        ? (_openBlock(), _createElementBlock("img", {
            key: 4,
            class: _normalizeClass(['icon-badge', _ctx.size]),
            src: _imports_3
          }, null, 2))
        : _createCommentVNode("", true),
      (_ctx.state === _ctx.SimplifiedState.FINISHED)
        ? (_openBlock(), _createElementBlock("img", {
            key: 5,
            class: _normalizeClass(['icon-badge', _ctx.size]),
            src: _imports_4
          }, null, 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(['action__label', _ctx.size, _ctx.state])
      }, _toDisplayString(_ctx.label), 3)
    ], 2),
    (_ctx.allowActions && _ctx.hasActions)
      ? (_openBlock(), _createBlock(_component_el_dropdown, {
          key: 0,
          onCommand: _ctx.handleCommand,
          trigger: "click"
        }, {
          dropdown: _withCtx(() => [
            _createVNode(_component_el_dropdown_menu, null, {
              default: _withCtx(() => [
                (_ctx.state === _ctx.SimplifiedState.RUNNING_NEED_ASSISTANCE)
                  ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                      key: 0,
                      command: "run"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Run")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.state === _ctx.SimplifiedState.RUNNING_NEED_ASSISTANCE)
                  ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                      key: 1,
                      command: "skip"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Mark Complete")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.state === _ctx.SimplifiedState.RUNNING_WITH_ERROR)
                  ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                      key: 2,
                      command: "retry"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Retry Action")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.state === _ctx.SimplifiedState.RUNNING_WITH_ERROR)
                  ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                      key: 3,
                      command: "logs"
                    }, {
                      default: _withCtx(() => [
                        (_ctx.isErrorLogOpen)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Close Error Logs"))
                          : (_openBlock(), _createElementBlock("span", _hoisted_2, "Open Error Logs"))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("button", {
              class: _normalizeClass(['action-bar__menu', _ctx.state, _ctx.size])
            }, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_MoreFilled)
                ]),
                _: 1
              })
            ], 2)
          ]),
          _: 1
        }, 8, ["onCommand"]))
      : _createCommentVNode("", true)
  ], 2))
}