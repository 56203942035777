
import {
  defineComponent,
  ref,
  computed,
  Ref,
  onMounted,
  onUnmounted,
  watch,
  PropType,
} from 'vue';
import { isEqual, without, difference, debounce, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import ActionController from '@/clients/action';
import OpsController, { Job } from '@/clients/ops';
import UIController from '@/clients/ui';
import UserController from '@/clients/users';
import { EntityType } from '@/clients/config';
import {
  COLUMNS,
  Columns,
  ColumnLabels,
  OrderByFieldType,
  OrderById,
} from './model';
import { SimplifiedState } from '@/clients/model';
import {
  JobSetChangeMessagePayload,
  JobStateChangeMessagePayload,
  JOB_SET_CHANGE,
  JOB_STATE_CHANGE,
} from '@/clients/ops/messages';
import { Action } from '@/clients/action';
import { formatDateTime, getTimeFromTimelog } from '@/pages/utils';
import SearchBar from '@/components/SearchBar.vue';
// @ts-ignore
import StateTag, { StateTagSize } from '@/components/StateTag.vue';
import ArrayInput from '@/components/ArrayInput.vue';
import CheckSquare from '@/components/CheckSquare.vue';
import OrderBy, { OrderByMessage } from './OrderBy.vue';
import ColumnFilter from './ColumnFilter.vue';
import RunningActivity from './RunningActivity.vue';
import ConfigEditor from '@/components/GenericForm/ConfigEditor.vue';
import FullPageDialog from '@/components/FullPageDialog.vue';
import FiltersController, { stateFilterOptions } from './filtersController';
import { useRoute } from 'vue-router';

export enum JobsTableMode {
  FULL = 'FULL',
  FIXED_FOR_TWIN = 'FIXED_FOR_TWIN',
}

export default defineComponent({
  props: {
    availableStateFilters: { type: Array as PropType<SimplifiedState[]> },
    mode: {
      type: String,
      default: JobsTableMode.FULL,
    },
    filterController: {
      type: Object as PropType<FiltersController>,
      required: true,
    },
    onJobSetChanged: Function as PropType<
      (payload: JobSetChangeMessagePayload) => boolean
    >,
    onJobStateChanged: Function as PropType<
      (payload: JobStateChangeMessagePayload) => boolean
    >,
  },
  emits: [
    'update:selected-jobs',
    'job-clicked',
    'open-logs',
    'retry-action',
    'skip-action',
    'update:job-filters',
    'update:order-by',
    'create-job',
  ],
  components: {
    SearchBar,
    StateTag,
    ArrayInput,
    CheckSquare,
    OrderBy,
    ColumnFilter,
    RunningActivity,
    ConfigEditor,
    FullPageDialog,
  },
  setup(props, { emit }) {
    const route = useRoute();
    const openedJob = computed(() =>
      Array.isArray(route.params.jobId)
        ? route.params.jobId[0]
        : route.params.jobId ?? null
    );

    const initialQueryFulfilled = ref(false);
    const fullTableView = ref(props.mode === JobsTableMode.FULL);
    const visibleColumns = computed({
      get: () => props.filterController.visibleColumns,
      set: (columns) => {
        props.filterController.visibleColumns = columns;
      },
    });
    const pageSizes = [10, 20, 50, 100];
    const pageSize = computed({
      get: () => props.filterController.limit,
      set: (lim) => {
        props.filterController.limit = lim;
      },
    });
    const pagerLayout = fullTableView.value
      ? 'prev, pager, next, sizes, total'
      : 'prev, pager, next, sizes';
    const jobFilters = computed(() => props.filterController.jobFilters);
    const nameSearchText = computed({
      get: () => props.filterController.nameFilter,
      set: (searchText) => {
        props.filterController.nameFilter = searchText;
      },
    });
    const nameSearchArray = computed({
      get: () => props.filterController.nameFilters,
      set: (names) => {
        props.filterController.nameFilters = names;
      },
    });
    const stateFilter = computed({
      get: () => props.filterController.stateFilter,
      set: (states) => {
        props.filterController.stateFilter = states;
      },
    });
    const dateCreatedFilter = computed({
      get: () => props.filterController.getTimestampFilter('createdTimestamp'),
      set: (dateRange: Date[]) => {
        props.filterController.setTimeStampFilter(
          'createdTimestamp',
          dateRange
        );
      },
    });
    const startDateFilter = computed({
      get: () =>
        props.filterController.getTimestampFilter('timelogActualStart'),
      set: (dateRange: Date[]) => {
        props.filterController.setTimeStampFilter(
          'timelogActualStart',
          dateRange
        );
      },
    });
    const startDateEstimateFilter = computed({
      get: () =>
        props.filterController.getTimestampFilter('timelogEstimateStart'),
      set: (dateRange: Date[]) => {
        props.filterController.setTimeStampFilter(
          'timelogEstimateStart',
          dateRange
        );
      },
    });
    const endDateFilter = computed({
      get: () => props.filterController.getTimestampFilter('timelogActualEnd'),
      set: (dateRange: Date[]) => {
        props.filterController.setTimeStampFilter(
          'timelogActualEnd',
          dateRange
        );
      },
    });
    const endDateEstimateFilter = computed({
      get: () =>
        props.filterController.getTimestampFilter('timelogEstimateEnd'),
      set: (dateRange: Date[]) => {
        props.filterController.setTimeStampFilter(
          'timelogEstimateEnd',
          dateRange
        );
      },
    });
    const modifiedDateFilter = computed({
      get: () => props.filterController.getTimestampFilter('modifiedTimestamp'),
      set: (dateRange: Date[]) => {
        props.filterController.setTimeStampFilter(
          'modifiedTimestamp',
          dateRange
        );
      },
    });
    const userFilter = computed({
      get: () => props.filterController.createdByList,
      set: (ids) => (props.filterController.createdByList = ids),
    });
    const workflowFilter = computed({
      get: () => props.filterController.workflowIds,
      set: (ids) => (props.filterController.workflowIds = ids),
    });
    const labFilter = computed({
      get: () => props.filterController.labIds,
      set: (ids) => (props.filterController.labIds = ids),
    });
    const offset = computed({
      get: () => props.filterController.offset,
      set: (os) => (props.filterController.offset = os),
    });
    const localOrderBy = computed({
      get: () => props.filterController.orderBy,
      set: (ob) => (props.filterController.orderBy = ob),
    });

    const isColumnDateFiltered = (filter: Date[] | undefined): boolean => {
      return !!filter && filter?.length > 0;
    };

    const clearAllFilters = () => {
      props.filterController.clearAllFilters();
      filtersChanged();
    };

    /**
     * COLUMN MANAGEMENT
     */
    const updateVisibleColumns = (updatedColumns: Columns[]) => {
      // if the user removed a column that we had previously ordered by, we need to remove
      // it from orderBy or it would be quite confusing.
      let needsRequery = false;
      const rawOrderBy = without(localOrderBy.value, 'asc', 'desc');
      const removedColumns = difference(rawOrderBy, updatedColumns);
      removedColumns.forEach((col) => {
        needsRequery = props.filterController.removeOrderBy(col as Columns);
      });
      if (needsRequery) {
        queryJobs();
      }
    };

    const isColumnVisible = (column: Columns): boolean => {
      if (!fullTableView.value) {
        // we hard code state as the first column in place of the checkboxes
        return (
          column === Columns.NAME ||
          column === Columns.ACTION ||
          column === Columns.SELECT
        );
      }
      return visibleColumns.value.includes(column);
    };

    const isColumnFilterable = (column: Columns): boolean =>
      props.filterController.isColumnFilterable(column);

    const queryJobs = () => {
      return OpsController.Instance.dispatchGetJobsByPage(
        offset.value,
        pageSize.value,
        props.filterController.jobFilters,
        localOrderBy.value
      );
    };

    queryJobs().then(() => (initialQueryFulfilled.value = true));

    const checkAndRequeryOnJobSetChange = (
      message: JobSetChangeMessagePayload
    ) => {
      if (props.onJobSetChanged?.(message)) {
        queryJobs();
      }
    };

    const checkAndRequeryOnJobStateChange = (
      message: JobStateChangeMessagePayload
    ) => {
      if (props.onJobStateChanged?.(message)) {
        queryJobs();
      }
    };

    onMounted(() => {
      if (props.onJobSetChanged) {
        UIController.Instance.messageBus?.on(
          JOB_SET_CHANGE,
          checkAndRequeryOnJobSetChange
        );
      }
      if (props.onJobStateChanged) {
        UIController.Instance.messageBus?.on(
          JOB_STATE_CHANGE,
          checkAndRequeryOnJobStateChange
        );
      }
    });

    onUnmounted(() => {
      // so we don't get flicker when we switch labs from jobs leftover from the last lab
      OpsController.Instance.clearPaginatedJobs();
      if (props.onJobSetChanged) {
        UIController.Instance.messageBus?.off(
          JOB_SET_CHANGE,
          checkAndRequeryOnJobSetChange
        );
      }
      if (props.onJobStateChanged) {
        UIController.Instance.messageBus?.off(
          JOB_STATE_CHANGE,
          checkAndRequeryOnJobStateChange
        );
      }
    });

    const focusedAction = (jobId: string): Action | null => {
      return OpsController.Instance.getFocusedAction(jobId) || null;
    };

    const openLogs = (job: Job) => {
      emit('open-logs', job);
    };

    const searchIcon: Ref<HTMLElement | null> = ref(null);

    /**
     * FILTERING
     */
    const defaultDate = DateTime.now()
      .minus({ months: 1 })
      .startOf('month')
      .toJSDate();

    const filtersChanged = () => {
      offset.value = 0;
      queryJobs();
      // clear the selection since the set will have changed
      selectedJobs.value.splice(0, selectedJobs.value.length);
      emit('update:job-filters', props.filterController.jobFilters);
      emit('update:selected-jobs', selectedJobs.value);
    };

    const handleNameSearchInput = debounce(() => {
      filtersChanged();
    }, 500);

    // USER FILTERING OPTS
    const users = computed(() => {
      const allUsers = [...UserController.Instance.users];
      const currentUserIdx = allUsers.findIndex(
        (u) => u.id === UserController.Instance.currentUser.id
      );
      if (currentUserIdx >= 0) {
        // bubble the current user (me) up to the top
        allUsers.splice(currentUserIdx, 1);
      }
      return [UserController.Instance.currentUser, ...sortBy(allUsers, 'name')];
    });

    // WORKFLOW FILTERING OPTS
    const workflows = computed(() => {
      let allWorkflows = ActionController.Instance.workflows;
      if (props.filterController.labId) {
        allWorkflows = allWorkflows.filter(
          (w) => w.constraint?.labId === props.filterController.labId
        );
      }
      return [...sortBy(allWorkflows, 'name')];
    });

    /**
     * JOBS COLLECTION
     */
    const jobs = computed(() => {
      return OpsController.Instance.currentJobsPage;
    });

    const labs = computed(() => OpsController.Instance.labs);

    const getLabName = (labId: string): string =>
      OpsController.Instance.getLab(labId).name;

    const mappedJobs = computed(() => {
      return jobs.value.map((j) => {
        const startDate =
          formatDateTime(getTimeFromTimelog(j, 'startTimestamp', 'actual'))
            .datetime || '--';
        const endDate =
          formatDateTime(getTimeFromTimelog(j, 'endTimestamp', 'actual'))
            .datetime || '--';
        const startDateEstimate =
          formatDateTime(getTimeFromTimelog(j, 'startTimestamp', 'estimate'))
            .datetime || '--';
        const endDateEstimate =
          formatDateTime(getTimeFromTimelog(j, 'endTimestamp', 'estimate'))
            .datetime || '--';
        const createdDate =
          formatDateTime(j.common?.createdTimestamp).datetime || '--';
        const modifiedDate =
          formatDateTime(j.common?.modifiedTimestamp).datetime || '--';
        const createdBy = UserController.Instance.getUser(
          j.common?.createdBy
        )?.name;
        const workflowName = j?.action?.name;

        return {
          ...j,
          startDate,
          startDateEstimate,
          modifiedDate,
          endDate,
          endDateEstimate,
          createdDate,
          createdBy,
          workflowName,
          StateTagSize,
        };
      });
    });

    const isJobTerminal = (jobId: string): boolean => {
      const job = OpsController.Instance.getJob(jobId);
      if (job.id) {
        return (
          job.state === SimplifiedState.CANCELLED ||
          job.state === SimplifiedState.FINISHED
        );
      }
      return false;
    };

    /**
     * PAGINATION
     */
    const totalJobCount = computed(
      () => OpsController.Instance.currentJobsPageTotalCount
    );

    const currentJobsOffset = computed(
      () => OpsController.Instance.currentJobsPageOffset
    );

    const currentPage = computed(
      () => (offset.value + pageSize.value) / pageSize.value
    );

    const pageChanged = (page: number) => {
      offset.value = (page - 1) * pageSize.value;
      queryJobs();
    };

    const pageSizeChanged = () => {
      offset.value = 0;
      queryJobs();
    };

    /**
     * ORDER BY/SORTING
     */
    /**
     * sanity check the orderBy array since there's no real structure enforced on it
     * @param orderByArr the array to check
     * @returns true if the array is sane, false otherwise
     */
    const validateOrderBy = (orderByArr: string[]): boolean => {
      const allowableOrders = ['asc', 'desc'];
      const allowableFields = COLUMNS.map((c) => c.orderById);
      for (let i = 0; i < orderByArr.length; i += 1) {
        if (i % 2 === 0 && !allowableOrders.includes(orderByArr[i])) {
          console.error('orderBy array is insane', orderByArr);
          return false;
        } else if (
          i % 2 === 1 &&
          !allowableFields.includes(orderByArr[i] as OrderById)
        ) {
          console.error('orderBy array is insane', orderByArr);
          return false;
        }
      }
      return true;
    };

    const handleOrderBy = (message: OrderByMessage) => {
      // do all our work on a copy so we don't flicker the UI
      const tempOrderBy = [...localOrderBy.value];
      const i = tempOrderBy.indexOf(message.field);

      if (i > 0) {
        if (message.direction === tempOrderBy[i - 1]) {
          // the user removed/cleared the orderby
          tempOrderBy.splice(i - 1, 2);
        } else {
          // the user changed the sort direction
          tempOrderBy.splice(i - 1, 1, message.direction);
        }
      } else {
        // the user added a new orderby
        tempOrderBy.push(message.direction, message.field);
      }
      if (tempOrderBy.length > 1) {
        // now go through the array and reorder based on fieldType, prioritizing
        // fields that are marked as discrete (e.g. state) over continuous fields (e.g. createdTimestamp)
        // because it doesn't make sense to sort by a continuous field _before_ a discrete one
        const discreteOrderBy: string[] = [];
        const continuousOrderBy: string[] = [];
        for (let j = 1; j < tempOrderBy.length; j += 2) {
          const field = tempOrderBy[j];
          const fieldType = COLUMNS.find(
            (c) => c.orderById === field
          )?.fieldType;
          if (fieldType === OrderByFieldType.DISCRETE) {
            discreteOrderBy.push(tempOrderBy[j - 1], field);
          } else if (fieldType === OrderByFieldType.CONTINUOUS) {
            continuousOrderBy.push(tempOrderBy[j - 1], field);
          }
        }
        tempOrderBy.splice(
          0,
          tempOrderBy.length,
          ...discreteOrderBy,
          ...continuousOrderBy
        );
      }

      if (validateOrderBy(tempOrderBy)) {
        localOrderBy.value = tempOrderBy;
        queryJobs();
        emit('update:order-by', localOrderBy.value);
      }
    };

    /**
     * JOB SELECTION
     */
    const selectedJobs: Ref<string[]> = ref([]);
    const toggleSelectedJobs = (jobId: string) => {
      const index = selectedJobs.value.indexOf(jobId);
      if (index > -1) {
        selectedJobs.value.splice(index, 1);
      } else {
        selectedJobs.value.push(jobId);
      }
      emit('update:selected-jobs', selectedJobs.value);
    };

    const isJobSelected = (jobId: string) => selectedJobs.value.includes(jobId);

    const toggleSelectAll = (select: boolean) => {
      if (select) {
        selectedJobs.value.splice(
          0,
          selectedJobs.value.length,
          ...mappedJobs.value.map((j) => j.id)
        );
        emit('update:selected-jobs', selectedJobs.value);
      } else {
        clearSelection();
      }
    };

    const clearSelection = () => {
      selectedJobs.value.splice(0, selectedJobs.value.length);
      emit('update:selected-jobs', []);
    };

    const allSelected = computed(
      // TODO: Need a secret key if the user explicitly clicked select all that
      // indicates that the selection spans all the pages
      () =>
        mappedJobs.value.length > 0 &&
        mappedJobs.value.length === selectedJobs.value.length
    );

    const someSelected = computed(() => {
      return (
        selectedJobs.value.length > 0 &&
        selectedJobs.value.length !== mappedJobs.value.length
      );
    });

    /**
     * EXPERT OPTIONS
     */
    const copyJobIdToClipboard = (jobId: string, event: Event) => {
      event.preventDefault();
      event.stopPropagation(); // prevent details from opening
      navigator.clipboard.writeText(jobId);
    };

    const isExpert = computed(() => UIController.Instance.expert);

    /**
     * JOB CONFIG
     */
    const isConfigEditorVisible = ref(false);
    const editConfigId: Ref<null | string> = ref(null);
    const editConfigJobName = computed(() => {
      if (editConfigId.value) {
        return jobs.value.find((j) => j.id === editConfigId.value)?.name || '';
      }
      return '';
    });
    const editConfigDialogTitle = computed(() => {
      if (editConfigId.value) {
        let title = `Configuration for ${editConfigJobName.value}`;
        if (!isJobTerminal(editConfigId.value)) {
          title = 'Edit ' + title;
        }
        return title;
      }
      return '';
    });
    const handleUpdateEditConfigId = (id: string) => {
      editConfigId.value = id;
      isConfigEditorVisible.value = true;
    };

    /**
     * WATCHES
     */
    watch(
      jobFilters,
      (newFilters, oldFilters) => {
        // if the only thing that changed was the name search text, we need to debounce it
        if (newFilters.name !== oldFilters.name) {
          handleNameSearchInput();
        } else if (!isEqual(oldFilters, newFilters)) {
          filtersChanged();
        }
      },
      { deep: true }
    );
    watch(
      mappedJobs,
      () => {
        // update our selectedJobs when the set of displayed jobs changes
        // i.e. - deselect jobs that are no longer in the set
        const displayedJobIds = mappedJobs.value.map((j) => j.id);
        const jobsToDeselect = difference(selectedJobs.value, displayedJobIds);
        if (jobsToDeselect.length > 0) {
          selectedJobs.value = without(selectedJobs.value, ...jobsToDeselect);
          emit('update:selected-jobs', selectedJobs.value);
        }
      },
      { deep: true }
    );

    watch(
      visibleColumns,
      () => {
        updateVisibleColumns(visibleColumns.value);
      },
      { deep: true }
    );

    return {
      openedJob,
      initialQueryFulfilled,
      fullTableView,
      Columns,
      visibleColumns,
      updateVisibleColumns,
      isColumnVisible,
      isColumnFilterable,
      pageSize,
      pageSizes,
      pagerLayout,
      totalJobCount,
      currentJobsOffset,
      currentPage,
      localOrderBy,
      handleOrderBy,
      handleNameSearchInput,
      pageChanged,
      pageSizeChanged,
      nameSearchText,
      nameSearchArray,
      searchIcon,
      stateFilter,
      stateFilterOptions,
      labFilter,
      mappedJobs,
      isJobTerminal,
      focusedAction,
      // canSkipOrRetry,
      openLogs,
      selectedJobs,
      users,
      defaultDate,
      userFilter,
      workflows,
      labs,
      getLabName,
      workflowFilter,
      dateCreatedFilter,
      startDateFilter,
      startDateEstimateFilter,
      modifiedDateFilter,
      endDateFilter,
      endDateEstimateFilter,
      filtersChanged,
      clearAllFilters,
      isColumnDateFiltered,
      copyJobIdToClipboard,
      isExpert,
      toggleSelectedJobs,
      isJobSelected,
      editConfigId,
      editConfigJobName,
      editConfigDialogTitle,
      isConfigEditorVisible,
      handleUpdateEditConfigId,
      allSelected,
      someSelected,
      toggleSelectAll,
      SimplifiedState,
      StateTagSize,
      EntityType,
      ColumnLabels,
    };
  },
});
