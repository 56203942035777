
import { defineComponent, ref, Ref, PropType } from 'vue';
import MaterialsMetaDataDialog from './MaterialsMetaDataDialog.vue';
import { IO, Sample, Asset } from '@/clients/model';

export default defineComponent({
  props: {
    ios: {
      type: Array as PropType<IO[]>,
      required: true,
    },
    selectedIos: {
      true: Array as PropType<string[]>,
      required: true,
    },
  },
  components: {
    MaterialsMetaDataDialog,
  },
  setup(props) {
    const metadataDialogVisible = ref(false);
    const materialMetaData: Ref<Record<string, string>> = ref({});
    function materials(io: IO): Sample[] | Asset[] {
      if (io.samples?.length) {
        return io.samples;
      } else if (io.assets?.length) {
        return io.assets;
      }
      return [];
    }
    function showMaterialsMetaData(material: Sample) {
      // TODO: We need Assets to also have metadata
      try {
        materialMetaData.value = JSON.parse(material.metadata);
        metadataDialogVisible.value = true;
      } catch (_) {
        // couldn't parse the JSON in metadata
        console.error('Could not parse sample metadata!');
      }
    }
    return {
      metadataDialogVisible,
      materialMetaData,
      showMaterialsMetaData,
      getQuantityString(io: IO): string {
        if (io.samples?.length) {
          return `x${io.samples.length}`;
        }
        if (io.actualquantity && io.actualquantity.value) {
          if (
            // @ts-ignore
            io.actualquantity.unit === 'count' ||
            // @ts-ignore
            io.actualquantity.unit === ''
          ) {
            return `x${io.actualquantity.value}`;
          }
          return `${io.actualquantity.value} ${io.actualquantity.unit}`;
        }
        return '-';
      },
      isRowSelected({ row }): string {
        // @ts-ignore
        if (props.selectedIos.includes(row.id)) {
          return 'job-details__io-list__selected-io';
        }
        return '';
      },
      materials,
      cellClass(cellProps) {
        if (cellProps.columnIndex === 0 && !materials(cellProps.row).length) {
          return 'job-details__io-list__cell-hidden';
        }
        return '';
      },
    };
  },
});
