
import { defineComponent, computed, ref, watch } from 'vue';
import MessageController from '@/clients/messenger/controller';

export default defineComponent({
  setup(_, { emit }) {
    const hover = ref(false);

    const message = computed(() => MessageController.Instance.message);
    // watch for recieved message updates
    watch(message, (newValue) => {
      if (newValue?.context === 'equipment-list') {
        emit('toggleNav');
      }
    });

    const openList = () => {
      emit('toggleNav');
      MessageController.Instance.send({
        to: 'visualizer',
        context: 'equipment-list',
        data: { state: true },
      });
    };

    return {
      hover,
      openList,
      message,
    };
  },
});
