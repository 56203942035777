
import { defineComponent } from 'vue';
import TokenController from '@/clients/tokens';

function resetState() {
  return {
    loading: false,
    error: null as Error | null,
    state: {
      name: '',
    },
    token: '',
  };
}

export default defineComponent({
  props: {
    open: Boolean,
  },
  data() {
    return resetState();
  },
  methods: {
    create() {
      this.error = null;
      this.loading = true;

      const savedState = {
        ...this.state,
      };

      TokenController.Instance.dispatchNewToken(this.state.name)
        .then((hash) => {
          this.loading = false;
          this.token = hash;
        })
        .catch((err) => {
          Object.assign(this.$data, savedState);
          this.error = err;
          this.loading = false;
        });
    },
    copyToClipboard(string) {
      navigator.clipboard.writeText(string);
    },
    clear() {
      Object.assign(this.$data, resetState());
    },
    close() {
      this.clear();
      this.$emit('update:open', false);
    },
  },
});
