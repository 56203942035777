import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/equipment-list-black.svg'
import _imports_1 from '@/assets/icons/equipment-list-blue.svg'


const _withScopeId = n => (_pushScopeId("data-v-7f2a7954"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  alt: "open equipment list"
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  alt: "open equipment list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "ops-action",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openList && _ctx.openList(...args))),
    onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hover = true)),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hover = false))
  }, [
    (!_ctx.hover)
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : (_openBlock(), _createElementBlock("img", _hoisted_2))
  ], 32))
}