
import { defineComponent, ref } from 'vue';
import CategorySelector from '@/components/CategorySelector.vue';
import Account from './Account.vue';
import Tokens from './Tokens.vue';
import UserImage from '@/assets/icons/user.svg';
import KeyImage from '@/assets/icons/key.svg';

export default defineComponent({
  components: {
    CategorySelector,
    Account,
    Tokens,
  },
  setup() {
    const categories = [
      {
        name: 'account',
        display: 'Account',
        image: UserImage,
      },
      {
        name: 'tokens',
        display: 'Tokens',
        image: KeyImage,
      },
    ];

    const selectedCategory = ref(categories[0]);
    const selectedCategoryUpdate = (category) =>
      (selectedCategory.value = category);

    return {
      categories,
      selectedCategory,
      selectedCategoryUpdate,
    };
  },
});
