
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    deleteEnabled: Boolean,
    pauseEnabled: Boolean,
    resumeEnabled: Boolean,
    cancelEnabled: Boolean,
    unscheduleEnabled: Boolean,
    retryEnabled: Boolean,
  },
  emits: ['delete', 'pause', 'resume', 'cancel', 'unschedule', 'retry'],
  setup() {
    return {
      isOpen: ref(false),
      cancelConfirmationVisible: ref(false),
      deleteConfirmationVisible: ref(false),
    };
  },
});
