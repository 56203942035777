
import { defineComponent, computed } from 'vue';
import { Duration } from 'luxon';
import { formatDateTime } from '@/pages/utils';

export default defineComponent({
  props: {
    event: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const duration = Duration.fromObject({
      minutes: props.event?.duration,
    }).toFormat('h:mm');
    const date = computed(() => {
      let start = '';
      let end = '';
      if (props.event && (props.event?.startTimestamp || props.event?.start)) {
        start =
          formatDateTime(props.event.startTimestamp).time ||
          formatDateTime(props.event.start).time;
      }
      if (props.event && (props.event?.endTimestamp || props.event?.end)) {
        end =
          formatDateTime(props.event?.endTimestamp).time ||
          formatDateTime(props.event?.end).time;
      }
      return start && end ? `${start}-${end}` : '';
    });

    const handleEventZoom = () => {
      if (props.event && (props.event?.startTimestamp || props.event?.start)) {
        if (props.event.start.isLuxonDateTime) {
          emit('zoomEvent', props.event?.start.toJSDate());
        } else {
          emit('zoomEvent', props.event?.startTimestamp || props.event?.start);
        }
      }
    };

    const getEventColor = () => {
      if (props.event?.locked) {
        return '#d9d9d9';
      }
      if (props.event?.color) {
        return props.event.color;
      }
      return '#0b83ff';
    };

    return {
      duration,
      date,
      handleEventZoom,
      getEventColor,
    };
  },
});
