
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    label: {
      type: String,
      required: false,
      default: 'Go to',
    },
    link: {
      type: String,
      required: false,
    },
    action: {
      type: Function,
      required: false,
    },
    newPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const actionWrapper = () => {
      if (props.action) {
        props.action();
      }
    };

    const target = computed(() => (props.newPage ? '_blank' : ''));

    return { actionWrapper, target };
  },
});
