import {
  ApolloLink,
  NextLink,
  Operation,
  Observable,
  FetchResult,
} from '@apollo/client/core';
import UIController from '@/clients/ui';
import { OperationDefinitionNode } from 'graphql';

class TimerLink extends ApolloLink {
  private threshold: number;
  private timeout: number;
  // constructor: pass in the number of ms that should constitute a "long" call
  constructor(threshold = 500, timeout = 25000) {
    super();
    this.threshold = threshold;
    this.timeout = timeout;
  }

  request(
    operation: Operation,
    forward: NextLink | undefined
  ): Observable<FetchResult> | null {
    if (!operation.operationName) {
      console.warn('Unnamed apollo query! Trace me back and name me!');
    }
    const def = operation.query.definitions.find(
      (d) => d.kind === 'OperationDefinition' && d.operation
    ) as OperationDefinitionNode;
    // don't track subscription connection time for now, only queries/mutations
    let t: NodeJS.Timeout;
    let failedTimeout: NodeJS.Timeout;
    const context = operation.getContext();

    if (def?.operation !== 'subscription' && !context.ignoreLongCalls) {
      t = setTimeout(() => {
        UIController.Instance.addLongNetworkCall(operation.operationName);
      }, this.threshold);
      failedTimeout = setTimeout(() => {
        clearTimeout(t);
        console.error(`Network call timed out: ${operation.operationName}`);
        UIController.Instance.finishLongNetworkCall(operation.operationName);
      }, this.timeout);

      // track requests/mutations in flight but not timed
      UIController.Instance.addGqlInFlight(operation.operationName);
    }

    if (forward) {
      return forward(operation).map((data) => {
        if (failedTimeout) {
          clearTimeout(failedTimeout);
        }
        if (t) {
          clearTimeout(t);
          UIController.Instance.finishLongNetworkCall(operation.operationName);
        }
        // remove inflight call
        if (def?.operation !== 'subscription' && !context.ignoreLongCalls) {
          UIController.Instance.removeGqlInFlight(operation.operationName);
        }
        console.log(operation, data);
        return data;
      });
    }
    return null;
  }
}

export default TimerLink;
