
import {
  defineComponent,
  onUnmounted,
  computed,
  ref,
  Ref,
  PropType,
} from 'vue';

// interface Props {
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   options: Record<string, any>;
//   selected: string;
//   disabled: boolean;
// }

export default defineComponent({
  props: {
    options: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    selected: String,
    disabled: Boolean,
  },
  setup(props, { emit }) {
    const selectSearch: Ref<HTMLElement | null> = ref(null);
    const searchInput: Ref<HTMLElement | null> = ref(null);
    const showOptions = ref(false);
    const inputFocus = ref(false);
    const inputString = ref('');
    const searchGUID = Math.floor(Math.random() * 99999);

    // close the options list if the user clicks outside of it
    const closeOnOutsideClick = (event: MouseEvent) => {
      const target = event.target as Element;
      if (
        // @ts-ignore
        !target.closest('#select-search')
      ) {
        showOptions.value = false;
        inputString.value = '';
      }
    };

    const toggleShowOptions = () => {
      if (!props.disabled) {
        showOptions.value = !showOptions.value;
        if (showOptions.value) {
          setOptionsLocation();
          document.addEventListener('click', closeOnOutsideClick);

          // @ts-ignore
          setTimeout(() => searchInput.value.focus(), 0);
        }
      }
    };

    const selectOption = (option) => {
      emit('change', option);
      inputString.value = '';
      toggleShowOptions();
    };

    const setOptionsLocation = () => {
      // need to use a setTimeout here to give the element time to open up
      setTimeout(() => {
        if (selectSearch.value) {
          const top = selectSearch.value.getBoundingClientRect().top;
          const left = selectSearch.value.getBoundingClientRect().left;
          const options = document.getElementById(
            `search-options-${searchGUID}`
          );
          if (options) {
            options.style.top = `${top + 25}px`;
            options.style.left = `${left}px`;
          }
        }
      }, 0);
    };

    const app = document.getElementById('app');
    const resizeObserver = new ResizeObserver(() => {
      setOptionsLocation();
    });
    if (app) {
      resizeObserver.observe(app);
    }

    const moveToPrevious = (event) => {
      if (
        event.target.previousSibling &&
        event.target.previousSibling.classList.contains('tabable')
      ) {
        event.target.previousSibling.focus();
      }
    };

    const moveToNext = (event) => {
      if (
        event.target.nextSibling &&
        event.target.nextSibling.classList.contains('tabable')
      ) {
        event.target.nextSibling.focus();
      }
    };

    const filteredOptions = computed(() => {
      if (inputString.value) {
        return Object.fromEntries(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          Object.entries(props.options).filter(([key, value]) =>
            value.toLowerCase().includes(inputString.value.toLowerCase())
          )
        );
      }
      return props.options;
    });

    onUnmounted(() => {
      document.removeEventListener('click', closeOnOutsideClick);
      if (app) {
        resizeObserver.unobserve(app);
      }
    });

    return {
      selectSearch,
      searchInput,
      searchGUID,
      showOptions,
      selectOption,
      toggleShowOptions,
      inputFocus,
      inputString,
      filteredOptions,
      moveToPrevious,
      moveToNext,
    };
  },
});
