
import { defineComponent, computed, ref, onMounted } from 'vue';
import UIController from '@/clients/ui/controller';
import { Tab } from '../pages/Home/types';
import { useRouter, useRoute } from 'vue-router';
import ArtificialLogo from '@/assets/AppLogo.svg';
import TecanLogo from '@/assets/tecan_logo.svg';

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const path = computed(() => route.path);
    const selectedTab = ref('/');
    onMounted(() => {
      switch (path.value) {
        case '/results':
          selectedTab.value = Tab.DataRecords;
          return;
        case '/labs':
          selectedTab.value = Tab.Labs;
          return;
        case '/assistants':
          selectedTab.value = Tab.Assistants;
          return;
        case '/workflows':
          selectedTab.value = Tab.Workflows;
          return;
        case '/settings':
          selectedTab.value = Tab.Settings;
          return;
        default:
          selectedTab.value = Tab.Ops;
          return;
      }
    });

    const useTecanBranding =
      UIController.Instance.hasFeatureFlag('USE_TECAN_BRANDING');

    const brandLogo = useTecanBranding ? TecanLogo : ArtificialLogo;

    const isActive = (tab) => {
      if (tab === Tab.Ops && path.value === '/') {
        return true;
      }
      return path.value.match(tab.toLowerCase());
    };

    const tabClicked = (index) => {
      switch (index) {
        case Tab.DataRecords:
          router.push('/results');
          return;
        case Tab.Labs:
          router.push('/labs');
          return;
        case Tab.Assistants:
          router.push('/assistants');
          return;
        case Tab.Workflows:
          router.push('/workflows');
          return;
        case Tab.Settings:
          router.push('/settings');
          return;
        default:
          router.push('/');
          return;
      }
    };

    const expert = computed(() => {
      return UIController.Instance.expert;
    });

    const goHome = () => {
      router.push('/');
    };

    const goToSettings = () => {
      tabClicked(Tab.Settings);
      router.push('/settings');
    };

    const goToArtificial = () => {
      window.open('https://www.artificial.com/', '_blank');
    };

    return {
      Tab,
      selectedTab,
      useTecanBranding,
      brandLogo,
      isActive,
      tabClicked,
      expert,
      goHome,
      goToSettings,
      goToArtificial,
    };
  },
});
