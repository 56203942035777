import axios from 'axios';
import { User } from '@/clients/users';
import { getCurrentUser } from '@/clients/users/service.apollo';
import { COGNITO_CLIENT_ID, AUTH_URL, DEPLOYMENT_ORG_ID } from '../utils';

export async function userInfo(): Promise<User> {
  // first refresh
  await axios.get(`${AUTH_URL}/refresh`, {
    params: COGNITO_CLIENT_ID ? { client_id: COGNITO_CLIENT_ID } : {},
    withCredentials: true,
  });

  let org = DEPLOYMENT_ORG_ID;

  // multitenant
  if (!DEPLOYMENT_ORG_ID) {
    const response = await axios.get(
      `${process.env.VUE_APP_BASE_URL}/getOrgs`,
      {
        withCredentials: true,
      }
    );
    org = response.data?.orgs?.[0];
  }

  await axios.get(`${process.env.VUE_APP_BASE_URL}/setOrg`, {
    withCredentials: true,
    params: { org },
  });
  return getCurrentUser();
}
