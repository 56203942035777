
import { defineComponent, computed, onMounted } from 'vue';
import { formatDateTime } from '@/pages/utils';
import OpsController from '@/clients/ops/controller';
import { Job } from '@/clients/ops';

export default defineComponent({
  props: {
    jobId: {
      type: String,
      required: true,
    },
  },
  emits: ['updateSelectedJob'],
  setup(props, { emit }) {
    onMounted(() => {
      const jobsToFetch: string[] = [];
      spawnedJobIds.value.forEach((id) => {
        if (!OpsController.Instance.getJob(id).name) {
          jobsToFetch.push(id);
        }
      });
      if (jobsToFetch.length > 0) {
        OpsController.Instance.dispatchGetJobNames(jobsToFetch);
      }
    });

    const spawnedJobIds = computed(
      () => OpsController.Instance.getJob(props.jobId).spawnedJobs || []
    );

    const spawnedJobs = computed(() =>
      spawnedJobIds.value
        .map((id) => OpsController.Instance.getJob(id))
        .filter((j) => j.id)
        .sort((a, b) =>
          a.common.createdTimestamp < b.common.createdTimestamp ? 1 : -1
        )
    );

    const updateSelectedJob = (job: Job) => emit('updateSelectedJob', job);

    return {
      spawnedJobs,
      formatDateTime,
      updateSelectedJob,
    };
  },
});
