<template>
  <el-skeleton animated style="--el-skeleton-circle-size: 16px">
    <template #template>
      <div style="display: flex">
        <el-skeleton-item variant="circle"></el-skeleton-item>
        <div style="width: 100%; margin-left: 15px">
          <el-skeleton-item variant="p"></el-skeleton-item>
          <el-skeleton-item variant="text"></el-skeleton-item>
        </div>
      </div>
    </template>
  </el-skeleton>
</template>
<script setup></script>
