
import { sortBy } from 'lodash';
import { defineComponent, computed, ref, PropType } from 'vue';
import { Action } from '@/clients/action';
import { SimplifiedState } from '@/clients/model';
import UserController from '@/clients/users';
import { getStartTimeFromTimeLog, getEndTimeFromTimeLog } from '../utils';

export default defineComponent({
  props: {
    actions: {
      type: Array as PropType<Action[]>,
      required: true,
    },
    labId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selectedActionId = ref('');

    function getTimestamp(action: Action): string {
      const endtime = getEndTimeFromTimeLog(action);
      if (endtime?.time && !endtime?.estimate) {
        return `Completed on: ${endtime.time.toLocaleString()}`;
      }
      const starttime = getStartTimeFromTimeLog(action);
      if (starttime?.time && starttime?.estimate) {
        return `Estimated start: ${starttime.time.toLocaleString()}`;
      }
      if (starttime?.time && !starttime?.estimate) {
        return `Started at: ${starttime.time.toLocaleString()}`;
      }
      return '--';
    }

    function getActivityColor(action: Action): string {
      if (action.state === SimplifiedState.RUNNING) {
        return '#2bca52';
      } else if (action.state === SimplifiedState.FINISHED) {
        if (action.assistant) {
          return '#a143c0';
        } else {
          return '#2c267f';
        }
      } else if (action.state === SimplifiedState.ERROR) {
        return '#97005b';
      } else {
        return '#909399';
      }
    }

    function getCompletedBy(action: Action): string {
      const user = UserController.Instance.getUser(action.completedBy || '');
      const displayString = `${
        action.state === SimplifiedState.FINISHED
          ? 'Completed by '
          : 'Assigned to '
      }${user.name || action.completedBy || 'unknown'}`;
      return displayString;
    }

    function toggleSelectAction(action: Action) {
      if (selectedActionId.value === action.id) {
        selectedActionId.value = '';
        emit('actionSelected', '');
      } else {
        selectedActionId.value = action.id;
        emit('actionSelected', action.id);
      }
    }

    const sortedActions = computed(() => {
      const actionsWithTimestamps = props.actions
        // .filter(a => a.timelog)
        .map((a) => {
          let relevantTimestamp = new Date();
          const endtime = getEndTimeFromTimeLog(a);
          if (endtime?.time && !endtime?.estimate) {
            relevantTimestamp = endtime.time;
          } else {
            const starttime = getStartTimeFromTimeLog(a);
            if (starttime?.time) {
              relevantTimestamp = starttime.time;
            }
          }
          return {
            ...a,
            relevantTimestamp,
          };
        });
      return sortBy(actionsWithTimestamps, [
        'relevantTimestamp',
        'assistant.entrypoint',
      ]).reverse();
    });

    return {
      sortedActions,
      getActivityColor,
      getCompletedBy,
      getTimestamp,
      toggleSelectAction,
      selectedActionId,
    };
  },
});
