import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ff2e48f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "job-errors" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_timeline_item = _resolveComponent("el-timeline-item")!
  const _component_el_timeline = _resolveComponent("el-timeline")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_timeline, { reverse: "" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
          return (_openBlock(), _createBlock(_component_el_timeline_item, {
            key: error.time,
            type: error.error ? 'danger' : 'warning',
            timestamp: new Date(error.time).toLocaleString()
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(error.message), 1)
            ]),
            _: 2
          }, 1032, ["type", "timestamp"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}