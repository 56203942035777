
import { defineComponent, PropType } from 'vue';

interface MappedFile {
  id: string;
  name: string;
  sourceTimestamp: string;
  createdDate: string;
  fileSize: number;
  displayFileSize: string;
  contentType: string;
  jobId: string;
  labId: string;
  sourceFilename: string;
  adaptor: string;
  relativeDownloadPath: string;
  description: string;
}

export default defineComponent({
  props: {
    file: {
      type: Object as PropType<MappedFile>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const previewUrl = `${process.env.VUE_APP_BASE_URL}${props.file?.relativeDownloadPath}?preview=true`;
    const close = () => emit('update:file', null);

    const openInNewTab = () => window.open(previewUrl, '_blank');
    const preview = () => emit('preview', props.file.id);
    const download = () => emit('download', [props.file.id]);

    return {
      previewUrl,
      close,
      openInNewTab,
      preview,
      download,
    };
  },
});
