import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, Teleport as _Teleport, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/sort.svg'


const _withScopeId = n => (_pushScopeId("data-v-1d2be6c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "assistants-table" }
const _hoisted_2 = { class: "filters" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  id: "assistant-name",
  class: "filter"
}
const _hoisted_5 = { class: "name" }
const _hoisted_6 = {
  id: "state",
  class: "filter"
}
const _hoisted_7 = { class: "name" }
const _hoisted_8 = { class: "filter" }
const _hoisted_9 = { class: "name" }
const _hoisted_10 = { class: "filter" }
const _hoisted_11 = { class: "name" }
const _hoisted_12 = { class: "filter" }
const _hoisted_13 = { class: "name" }
const _hoisted_14 = { class: "filter" }
const _hoisted_15 = { class: "name" }
const _hoisted_16 = { class: "rows" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = {
  id: "assistant-name",
  class: "data"
}
const _hoisted_19 = {
  id: "state",
  class: "data"
}
const _hoisted_20 = { class: "data" }
const _hoisted_21 = { class: "data" }
const _hoisted_22 = { class: "data" }
const _hoisted_23 = { class: "data" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_state_tag = _resolveComponent("state-tag")!
  const _component_assistant_details = _resolveComponent("assistant-details")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createTextVNode(" Assistant Name "),
            _createElementVNode("img", {
              class: _normalizeClass(['sort-icon', _ctx.sortDirection('name')]),
              src: _imports_0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setSortValues('name')))
            }, null, 2)
          ]),
          _createVNode(_component_el_input, {
            modelValue: _ctx.assistantsFilterText,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.assistantsFilterText) = $event)),
            prefixIcon: "Search"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createTextVNode(" State "),
            _createElementVNode("img", {
              class: _normalizeClass(['sort-icon', _ctx.sortDirection('state')]),
              src: _imports_0,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setSortValues('state')))
            }, null, 2)
          ]),
          _createVNode(_component_el_select, {
            modelValue: _ctx.stateFilter,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.stateFilter) = $event))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stateFilterOpts, (opt) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: opt.value,
                  label: opt.label,
                  value: opt.value
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createTextVNode(" Completed by "),
            _createElementVNode("img", {
              class: _normalizeClass(['sort-icon', _ctx.sortDirection('completedBy')]),
              src: _imports_0,
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setSortValues('completedBy')))
            }, null, 2)
          ]),
          _createVNode(_component_el_select, {
            modelValue: _ctx.completedBy,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.completedBy) = $event))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: user,
                  label: user,
                  value: user
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createTextVNode(" Start time "),
            _createElementVNode("img", {
              class: _normalizeClass(['sort-icon', _ctx.sortDirection('start')]),
              src: _imports_0,
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setSortValues('start')))
            }, null, 2)
          ]),
          _createVNode(_component_el_date_picker, {
            modelValue: _ctx.startDateFilter,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.startDateFilter) = $event)),
            type: "date",
            placeholder: "All",
            clearable: true,
            format: "ddd M/d/yyyy"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createTextVNode(" End time "),
            _createElementVNode("img", {
              class: _normalizeClass(['sort-icon', _ctx.sortDirection('end')]),
              src: _imports_0,
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setSortValues('end')))
            }, null, 2)
          ]),
          _createVNode(_component_el_date_picker, {
            modelValue: _ctx.endDateFilter,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.endDateFilter) = $event)),
            type: "date",
            placeholder: "All",
            clearable: true,
            format: "ddd M/d/yyyy"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createTextVNode(" Duration "),
            _createElementVNode("img", {
              class: _normalizeClass(['sort-icon', _ctx.sortDirection('duration')]),
              src: _imports_0,
              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setSortValues('duration')))
            }, null, 2)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredAssistants, (assistant) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "row data-row",
          key: assistant.id,
          onClick: ($event: any) => (_ctx.selectAssistant(assistant))
        }, [
          _createElementVNode("div", _hoisted_18, _toDisplayString(assistant.name), 1),
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_state_tag, {
              stateTag: assistant.state,
              "is-lab": false,
              size: _ctx.StateTagSize.sm
            }, null, 8, ["stateTag", "size"])
          ]),
          _createElementVNode("div", _hoisted_20, _toDisplayString(assistant.completedBy), 1),
          _createElementVNode("div", _hoisted_21, _toDisplayString(assistant.start), 1),
          _createElementVNode("div", _hoisted_22, _toDisplayString(assistant.completed), 1),
          _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.formatDuration(assistant.duration)), 1)
        ], 8, _hoisted_17))
      }), 128))
    ]),
    (_ctx.selectedAssistant)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#drawer-right-target"
        }, [
          _createVNode(_component_assistant_details, {
            assistant: _ctx.selectedAssistant,
            "lab-id": _ctx.job.labId,
            onClose: _cache[11] || (_cache[11] = ($event: any) => (_ctx.selectAssistant(null)))
          }, null, 8, ["assistant", "lab-id"])
        ]))
      : _createCommentVNode("", true)
  ]))
}