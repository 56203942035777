
import { computed, defineComponent, PropType } from 'vue';
import { formatDateTime } from '@/pages/utils';
import OpsController from '@/clients/ops';
import UserController from '@/clients/users';
import { SimplifiedState } from '@/clients/model';
import { Job } from '@/clients/ops/model';

// interface Props {
//   job: Job;
// }

export default defineComponent({
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true,
    },
  },
  emits: ['updateSelectedJob'],
  setup(props, { emit }) {
    if (
      props.job.spawnedBy &&
      (!OpsController.Instance.getJob(props.job.spawnedBy).name ||
        !OpsController.Instance.getJob(props.job.spawnedBy).labId)
    ) {
      // we may not have the job that spawned this job in memory yet, if not
      // we need to just fetch its name and labId for display here
      OpsController.Instance.dispatchGetJobNames([props.job.spawnedBy]);
    }
    const formatDate = (date: string) => formatDateTime(date).datetime;

    const spawnedByName = computed(() => {
      if (props.job.spawnedBy) {
        return OpsController.Instance.getJob(props.job.spawnedBy).name;
      }
      return '';
    });

    const user = computed(() =>
      UserController.Instance.getUser(props.job.common.createdBy)
    );

    const handleUserName = computed(() => {
      return user.value.name || '';
    });

    const handleUserInitial = computed(() => {
      return user.value?.name[0]?.toUpperCase() || '';
    });

    const updateSelectedJob = () => {
      const parent = OpsController.Instance.getJob(props.job.spawnedBy || '');
      if (parent) {
        emit('updateSelectedJob', parent);
      }
    };

    return {
      formatDate,
      user,
      handleUserName,
      handleUserInitial,
      spawnedByName,
      updateSelectedJob,
      SimplifiedState,
    };
  },
});
