
import {
  defineComponent,
  computed,
  ref,
  ComputedRef,
  Ref,
  PropType,
} from 'vue';
import moment from 'moment';
import { orderBy } from 'lodash';
import { Job } from '@/clients/ops';
import OpsController from '@/clients/ops/controller';
import SearchBar from '@/components/SearchBar.vue';
import FileDetails from './FileDetails.vue';
import FilePreview from './FilePreview.vue';

interface MappedFile {
  id: string;
  name: string;
  sourceTimestamp: string;
  createdDate: string;
  fileSize: number;
  displayFileSize: string;
  contentType: string;
  jobId: string;
  labId: string;
  sourceFilename: string;
  adaptor: string;
  relativeDownloadPath: string;
  description: string;
}

export default defineComponent({
  components: {
    SearchBar,
    FileDetails,
    FilePreview,
  },
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true,
    },
  },
  setup(props) {
    OpsController.Instance.dispatchGetJobFiles(props.job.id);
    const convertFileSize = (size: string) => {
      const fs = parseInt(size);
      if (fs < MB) {
        return `${Math.floor(fs / 1024)}KB`;
      } else if (fs < GB) {
        return `${Math.floor(fs / MB)}MB`;
      } else {
        return `${Math.floor(fs / GB)}GB`;
      }
    };

    const files: ComputedRef<MappedFile[]> = computed(() => {
      const files = OpsController.Instance.getFilesByJob(props.job.id);
      if (files) {
        return files.map((f): MappedFile => {
          return {
            id: f.id.toString(),
            jobId: f.jobId,
            labId: f.labId,
            sourceFilename: f.sourceFilename,
            name: f.sourceFilename,
            sourceTimestamp: moment(f.sourceTimestamp).format(
              'MM/DD/YYYY, h:mm:ss A'
            ),
            createdDate: f.sourceTimestamp,
            fileSize: f.fileSize ? parseInt(f.fileSize) : 0,
            displayFileSize: convertFileSize(f.fileSize || '0'),
            contentType: f.contentType,
            adaptor: '--',
            relativeDownloadPath: f.relativeDownloadPath,
            description: f.description || '',
          };
        });
      }
      return [];
    });

    const filenameFilterText = ref('');
    const selectedFiletypeFilter = ref('All');
    const filetypeFilterOptions = [
      'All',
      'JPEG',
      'JSON',
      'PDF',
      'PNG',
      'TXT',
      'YAML',
    ];
    const fileTypeMap = {
      TXT: 'text/plain',
      JPEG: 'image/jpg',
      JSON: 'application/json',
      PDF: 'application/pdf',
      PNG: 'image/png',
      YAML: 'text/yaml',
    };

    const fileDateFilter: Ref<Date | null> = ref(null);
    const fileadaptorFilterText = ref('');
    const filesizeFilter = ref('All');
    const filesizeFilterOptions = [
      {
        label: 'All',
        value: 'All',
      },
      {
        label: 'Empty (0Kb)',
        value: 'EMPTY',
      },
      {
        label: 'Tiny (0-16KB)',
        value: 'TINY',
      },
      {
        label: 'Small (16KB-1MB)',
        value: 'SMALL',
      },
      {
        label: 'Medium (1MB-128MB)',
        value: 'MEDIUM',
      },
      {
        label: 'Large (128MB-1GB)',
        value: 'LARGE',
      },
      {
        label: 'Huge (1GB-4GB)',
        value: 'HUGE',
      },
      {
        label: 'Gigantic (>4GB)',
        value: 'GIGANTIC',
      },
    ];
    const KB = 1024;
    const MB = KB * 1024;
    const GB = MB * 1024;
    const fileSizeMap = {
      TINY: {
        lower: 0,
        upper: 16 * KB,
      },
      SMALL: {
        lower: 16 * KB,
        upper: MB,
      },
      MEDIUM: {
        lower: MB,
        upper: 128 * MB,
      },
      LARGE: {
        lower: 128 * MB,
        upper: GB,
      },
      HUGE: {
        lower: GB,
        upper: 4 * GB,
      },
      GIGANTIC: {
        lower: 4 * GB,
        upper: null,
      },
    };

    const sortAscending = ref(true);
    const sortColumn = ref('name');
    const updateSortColumn = (column: string) => {
      if (column === sortColumn.value) {
        sortAscending.value = !sortAscending.value;
      } else {
        sortColumn.value = column;
        sortAscending.value = true;
      }
    };

    // Applies filters and sort
    const filteredFiles = computed(() => {
      let filtered = [...files.value];

      // name filter
      if (filenameFilterText.value) {
        filtered = filtered.filter((f) =>
          f.name.toLowerCase().includes(filenameFilterText.value.toLowerCase())
        );
      }

      // filetype filter
      if (selectedFiletypeFilter.value !== 'All') {
        filtered = filtered.filter(
          (f) => f.contentType === fileTypeMap[selectedFiletypeFilter.value]
        );
      }

      // date filter
      if (fileDateFilter.value) {
        filtered = filtered.filter(
          (f) =>
            moment(f.createdDate).day() ===
              moment(fileDateFilter.value).day() &&
            moment(f.createdDate).month() ===
              moment(fileDateFilter.value).month() &&
            moment(f.createdDate).year() === moment(fileDateFilter.value).year()
        );
      }

      //file size filter
      if (filesizeFilter.value !== 'All') {
        if (filesizeFilter.value === 'EMPTY') {
          filtered = filtered.filter((f) => f.fileSize === 0);
        } else if (filesizeFilter.value === 'GIGANTIC') {
          filtered = filtered.filter(
            (f) => f.fileSize >= fileSizeMap.GIGANTIC.lower
          );
        } else {
          filtered = filtered.filter((f) => {
            return (
              f.fileSize >= fileSizeMap[filesizeFilter.value].lower &&
              f.fileSize < fileSizeMap[filesizeFilter.value].upper
            );
          });
        }
      }

      filtered = orderBy(
        filtered,
        [sortColumn.value],
        [sortAscending.value ? 'asc' : 'desc']
      );

      return filtered;
    });

    const selectedFiles: Ref<string[]> = ref([]);
    const handleFileSelect = (fileId: string) => {
      const fileIdIndex: number = selectedFiles.value.indexOf(fileId);
      if (fileIdIndex >= 0) {
        selectedFiles.value.splice(fileIdIndex, 1);
      } else {
        selectedFiles.value = [...selectedFiles.value, fileId];
      }
    };
    const isSelectedFile = (fileId: string) =>
      selectedFiles.value.indexOf(fileId) > -1;

    const selectAllFiles = () => {
      selectedFiles.value = files.value.map((f) => f.id);
    };

    const deselectAllFiles = () => (selectedFiles.value = []);

    const downloadFile = (ids) => {
      const id = ids.pop();
      const file = files.value?.find((f) => f.id === id);
      if (file) {
        const url = `${process.env.VUE_APP_BASE_URL}${file?.relativeDownloadPath}`;
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.remove();
        setTimeout(() => {
          if (ids.length > 0) {
            downloadFile(ids);
          }
        }, 500);
      }
      return;
    };

    const downloadFiles = () => {
      downloadFile([...selectedFiles.value]);
    };

    const openedFile: Ref<MappedFile | null> = ref(null);

    const handleFileOption = (command: string) => {
      const [action, id] = command.split('-');
      if (action === 'open') {
        openedFile.value = files.value.find((file) => file.id === id) || null;
      } else if (action === 'preview') {
        preview.value = files.value.find((file) => file.id === id) || null;
      } else if (action === 'download') {
        downloadFile([id]);
      }
    };

    const preview: Ref<MappedFile | null> = ref(null);
    const handlePreviewSelect = (id: string) => {
      preview.value = files.value.find((f) => f.id === id) || null;
    };

    return {
      files,
      filteredFiles,
      filenameFilterText,
      selectedFiletypeFilter,
      filetypeFilterOptions,
      fileDateFilter,
      fileadaptorFilterText,
      filesizeFilter,
      filesizeFilterOptions,
      handleFileSelect,
      selectedFiles,
      isSelectedFile,
      selectAllFiles,
      deselectAllFiles,
      downloadFiles,
      downloadFile,
      openedFile,
      handleFileOption,
      preview,
      handlePreviewSelect,
      updateSortColumn,
    };
  },
});
