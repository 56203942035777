import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/circle-check-black.svg'


const _withScopeId = n => (_pushScopeId("data-v-627116e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  src: _imports_0
}
const _hoisted_2 = {
  key: 1,
  class: "gantt-event__info"
}
const _hoisted_3 = { class: "info-label" }
const _hoisted_4 = { class: "info-date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.date)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['gantt-event', { locked: _ctx.event.locked, 'no-pad': _ctx.width < 15 }]),
        style: _normalizeStyle({ background: _ctx.getEventColor(), width: `${_ctx.width}px` }),
        onDblclick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleEventZoom && _ctx.handleEventZoom(...args)))
      }, [
        (!_ctx.event.noCheckmark && !_ctx.event.locked && _ctx.width > 35)
          ? (_openBlock(), _createElementBlock("img", _hoisted_1))
          : _createCommentVNode("", true),
        (_ctx.width > 25)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.event.actionName || _ctx.event.name), 1),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.date), 1)
            ]))
          : _createCommentVNode("", true)
      ], 38))
    : _createCommentVNode("", true)
}