import { Action } from '@/clients/action';
import { cloneDeep, indexOf, isEmpty, isUndefined } from 'lodash';
import { InteractivityHookStage, RequestJSONSchema } from './types';

export function propertyHasHooks(property: RequestJSONSchema): boolean {
  return !!(
    property['ui:options']?.['hooks:preCreate'] ||
    property['ui:options']?.['hooks:postCreate'] ||
    property['ui:options']?.['hooks:preBatch'] ||
    property['ui:options']?.['hooks:postBatch'] ||
    property['ui:options']?.['hooks:preSchedule'] ||
    property['ui:options']?.['hooks:postSchedule'] ||
    property['ui:hooks:preCreate'] ||
    property['ui:hooks:postCreate'] ||
    property['ui:hooks:preBatch'] ||
    property['ui:hooks:postBatch'] ||
    property['ui:hooks:preSchedule'] ||
    property['ui:hooks:postSchedule']
  );
}

export function schemaHasHooks(schema: RequestJSONSchema): boolean {
  if (schema.properties) {
    for (const value of Object.values(schema.properties)) {
      if (propertyHasHooks(value as RequestJSONSchema)) {
        return true;
      }
    }
  }
  return false;
}

function propertyHasPrecreateHook(property: RequestJSONSchema): boolean {
  // if no hooks, default to preCreate
  return !!(
    !propertyHasHooks(property) ||
    property['ui:options']?.['hooks:preCreate'] ||
    property['ui:hooks:preCreate']
  );
}

function propertyHasPostCreateHook(property: RequestJSONSchema): boolean {
  return !!(
    property['ui:options']?.['hooks:postCreate'] ||
    property['ui:hooks:postCreate']
  );
}

function propertyHasPreBatchHook(property: RequestJSONSchema): boolean {
  return !!(
    property['ui:options']?.['hooks:preBatch'] || property['ui:hooks:preBatch']
  );
}

function propertyHasPostBatchHook(property: RequestJSONSchema): boolean {
  return !!(
    property['ui:options']?.['hooks:postBatch'] ||
    property['ui:hooks:postBatch']
  );
}

function propertyHasPreScheduleHook(property: RequestJSONSchema): boolean {
  return !!(
    property['ui:options']?.['hooks:preSchedule'] ||
    property['ui:hooks:preSchedule']
  );
}

function propertyHasPostScheduleHook(property: RequestJSONSchema): boolean {
  return !!(
    property['ui:options']?.['hooks:postSchedule'] ||
    property['ui:hooks:postSchedule']
  );
}

export function schemaHasPreCreateHooks(
  schema: RequestJSONSchema | null
): boolean {
  if (schema?.properties) {
    for (const value of Object.values(schema.properties)) {
      if (propertyHasPrecreateHook(value as RequestJSONSchema)) {
        return true;
      }
    }
  }
  return false;
}

export function schemaHasPreScheduleHooks(
  schema: RequestJSONSchema | null
): boolean {
  if (schema?.properties) {
    for (const value of Object.values(schema.properties)) {
      if (propertyHasPreScheduleHook(value as RequestJSONSchema)) {
        return true;
      }
    }
  }
  return false;
}

function propertyHasHook(
  property: RequestJSONSchema,
  hook: InteractivityHookStage
): boolean {
  switch (hook) {
    default:
    case InteractivityHookStage.INTERACTIVITY_HOOK_PRE_CREATE: {
      return propertyHasPrecreateHook(property);
    }
    case InteractivityHookStage.INTERACTIVITY_HOOK_POST_CREATE: {
      return propertyHasPostCreateHook(property);
    }
    case InteractivityHookStage.INTERACTIVITY_HOOK_PRE_BATCH: {
      return propertyHasPreBatchHook(property);
    }
    case InteractivityHookStage.INTERACTIVITY_HOOK_POST_BATCH: {
      return propertyHasPostBatchHook(property);
    }
    case InteractivityHookStage.INTERACTIVITY_HOOK_PRE_SCHEDULE: {
      return propertyHasPreScheduleHook(property);
    }
    case InteractivityHookStage.INTERACTIVITY_HOOK_POST_SCHEDULE: {
      return propertyHasPostScheduleHook(property);
    }
  }
}

function propertyMatchesDirection(
  property: RequestJSONSchema,
  input: boolean
): boolean {
  return property.input === input;
}

/**
 * Remove all fields in the schema that aren't part of the given hook
 * @param schema the schema
 * @param hook the interactivity hook
 * @returns the schema with any property that doesn't match hook removed
 */
export function filterSchemaToHook(
  schema: RequestJSONSchema,
  hook: InteractivityHookStage
): RequestJSONSchema {
  const s = cloneDeep(schema);
  if (s.properties) {
    const fields = Object.keys(schema.properties || {});
    for (let i = 0; i < fields.length; i += 1) {
      if (
        !propertyHasHook(s.properties[fields[i]] as RequestJSONSchema, hook)
      ) {
        delete s.properties[fields[i]];
        const ri = s.required?.findIndex((f) => f === fields[i]);
        if (ri !== undefined && ri >= 0) {
          s.required?.splice(ri, 1);
        }
      }
    }
  }
  return s;
}

/**
 * Remove all fields in the schema that don't match the given direction (input/output)
 * @param schema the schema
 * @param input true if input, false if output
 * @returns the filtered schema
 */
export function filterSchemaToDirection(
  schema: RequestJSONSchema,
  input?: boolean
): RequestJSONSchema {
  if (isUndefined(input)) {
    return schema;
  }
  const s = cloneDeep(schema);
  if (s.properties) {
    const fields = Object.keys(schema.properties || {});
    for (let i = 0; i < fields.length; i += 1) {
      if (
        !propertyMatchesDirection(
          s.properties[fields[i]] as RequestJSONSchema,
          input
        )
      ) {
        delete s.properties[fields[i]];
        const ri = s.required?.findIndex((f) => f === fields[i]);
        if (ri !== undefined && ri >= 0) {
          s.required?.splice(ri, 1);
        }
      }
    }
  }
  return s;
}

/**
 * Fix up an action's schema and filter fields based on hook
 * @param action the action
 * @param hook the interactivity hook
 * @returns The schema
 */
export function parseAndFilterSchemaToHook(
  action: Action,
  hook?: InteractivityHookStage
): RequestJSONSchema {
  if (action?.schema) {
    try {
      let schema = JSON.parse(action.schema) as RequestJSONSchema;
      schema.$schema = 'http://json-schema.org/draft-07/schema#';
      if (hook) {
        schema = filterSchemaToHook(schema, hook);
      }
      // check to see if we've removed ALL of the properties (none matched the hook)
      if (isEmpty(schema.properties)) {
        return {};
      }
      return schema;
    } catch (_) {
      return {};
    }
  }
  return {};
}

function traverseSchema(
  schema: RequestJSONSchema,
  cb: (node: RequestJSONSchema, level: number) => boolean,
  level = 0
) {
  if (schema.properties) {
    const fields = Object.keys(schema.properties);
    let cont = true;
    let i = 0;
    while (cont && i < fields.length) {
      // @ts-ignore
      cont = cb(schema.properties[fields[i]], level);
      if (cont) {
        // @ts-ignore
        if (schema.properties[fields[i]].type === 'array') {
          // @ts-ignore
          traverseSchema(schema.properties[fields[i]].items, cb, level + 1);
          // @ts-ignore
        } else if (schema.properties[fields[i]].type === 'object') {
          // @ts-ignore
          traverseSchema(schema.properties[fields[i]], cb, level + 1);
        }
      }
      i += 1;
    }
  }
}

/**
 * Figure out whether a given arbitrary schema can be rendered by the current
 * UI GenericForm implementation. Ex: the current implementation cannot render
 * nested structs inside arrays
 * @param schema the schema to check
 * @returns true if it can be rendered, false if not
 */
export function canSchemaBeRendered(schema: RequestJSONSchema): boolean {
  let renderable = true;
  const typeStack: string[] = [];
  let traversableSchema = schema;
  let startingLevel = 0;
  if (schema.type === 'array') {
    typeStack.push('array');
    // @ts-ignore
    traversableSchema = schema.items;
    startingLevel = 1;
  }
  traverseSchema(
    traversableSchema,
    (node, level) => {
      if (level >= typeStack.length && node.type) {
        // @ts-ignore
        typeStack.push(node.type);
      } else {
        typeStack.pop();
      }
      // check for objects nested underneath an array
      const arrayIndex = typeStack.findIndex((t) => t === 'array');
      if (arrayIndex >= 0) {
        const objIndex = indexOf(typeStack, 'object', arrayIndex);
        if (objIndex > arrayIndex) {
          const nestedObjIdx = indexOf(typeStack, 'object', objIndex);
          renderable = nestedObjIdx > objIndex;
        }
      }
      return renderable;
    },
    startingLevel
  );
  return renderable;
}
