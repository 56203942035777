import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02f1ed32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "request-param-details" }
const _hoisted_2 = {
  key: 0,
  class: "table-container"
}
const _hoisted_3 = { class: "table" }
const _hoisted_4 = { class: "table-title" }
const _hoisted_5 = { class: "table-headers" }
const _hoisted_6 = { class: "table-data" }
const _hoisted_7 = { class: "rows" }
const _hoisted_8 = { class: "download-banner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.uiOptionsFromSchema(_ctx.schema).widget === 'table')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.property), 1),
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schema.items.properties, (value, key, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "header",
                  key: `${key}-${index}`
                }, _toDisplayString(_ctx.uiOptionsFromSchema(_ctx.schema.items.properties[key]).title), 1))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mappedValues, (row) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "row",
                    key: row.id
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.values, (item, name) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "item",
                        key: `${name}-${row.id}`
                      }, _toDisplayString(item), 1))
                    }), 128))
                  ]))
                }), 128))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              class: "btn",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleJSONDownload && _ctx.handleJSONDownload(...args)))
            }, " Download as .json "),
            _createElementVNode("button", {
              class: "btn",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleCSVDownload && _ctx.handleCSVDownload(...args)))
            }, "Download as .csv")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}