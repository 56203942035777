import {
  ApolloLink,
  NextLink,
  Operation,
  Observable,
  FetchResult,
} from '@apollo/client/core';
import { OperationDefinitionNode } from 'graphql';

/**
 * Don't allow multiple outstanding mutations to the same operation
 * This will throw an exception on subsequent calls so make sure to wrap mutation calls
 * in the controller with try...catch
 * You can exempt a particular mutation from this link by setting the "allowMultipleOutstanding"
 * key to true on the mutation's starting context
 */
class ProtectionLink extends ApolloLink {
  private outstandingMutations: string[];

  constructor() {
    super();
    this.outstandingMutations = [];
  }

  request(
    operation: Operation,
    forward?: NextLink
  ): Observable<FetchResult> | null {
    const def = operation.query.definitions.find(
      (d) => d.kind === 'OperationDefinition' && d.operation
    ) as OperationDefinitionNode;

    const context = operation.getContext();
    if (def?.operation === 'mutation' && !context.allowMultipleOutstanding) {
      const index = this.outstandingMutations.findIndex(
        (m) => m === operation.operationName
      );
      if (index < 0) {
        this.outstandingMutations.push(operation.operationName);
      } else {
        // we already have an outstanding call: cancel the operation
        console.warn(
          `ProtectionLink: there is already an outstanding call to ${operation.operationName}; ignoring new request`
        );
        throw operation;
      }
    }

    if (forward) {
      return forward(operation).map((data) => {
        // remove inflight call
        if (def?.operation === 'mutation') {
          const index = this.outstandingMutations.findIndex(
            (m) => m === operation.operationName
          );
          if (index >= 0) {
            this.outstandingMutations.splice(index, 1);
          }
        }
        return data;
      });
    }
    return null;
  }
}

export default ProtectionLink;
