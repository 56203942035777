import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/upload.svg'
import _imports_1 from '@/assets/icons/close.svg'


const _withScopeId = n => (_pushScopeId("data-v-022f8c79"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "upload file"
}
const _hoisted_3 = {
  key: 1,
  class: "el-icon-document"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 3,
  class: "file-name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['file-upload__drop-zone', { dragover: _ctx.isOverDropZone }]),
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
    onDragstart: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
    onDragend: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"])),
    onDragover: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"])),
    onDragenter: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.handleDragChange($event, true)), ["stop","prevent"])),
    onDragleave: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.handleDragChange($event, false)), ["stop","prevent"])),
    onDrop: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.handleFile($event, false)), ["stop","prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.fileName)
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : (_openBlock(), _createElementBlock("i", _hoisted_3)),
      (!_ctx.fileName)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(`Select or Drag & Drop ${_ctx.fileType} File `), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.fileName), 1))
    ]),
    (_ctx.fileName)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _imports_1,
          alt: "remove file",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clearFile && _ctx.clearFile(...args)), ["stop"]))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      class: "file-input",
      type: "file",
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleFile($event, true))),
      ref: "fileInput"
    }, null, 544)
  ], 34))
}