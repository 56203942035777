
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    blue: Boolean,
  },
  setup() {
    return {};
  },
});
