
import { defineComponent, computed, ref } from 'vue';
import HourSelection from './HourSelection.vue';
import CheckCircle from '@/components/CheckCircle.vue';

export default defineComponent({
  components: {
    HourSelection,
    CheckCircle,
  },
  props: {
    lab: Object,
  },
  setup(_, { emit }) {
    const hours = ref([
      {
        id: Math.floor(Math.random() * 999999999).toString(),
        startTime: '',
        endTime: '',
        timezone: '',
        days: [],
      },
    ]);

    const addNewHours = () => {
      const newHours = {
        id: Math.floor(Math.random() * 999999999).toString(),
        startTime: '',
        endTime: '',
        timezone: '',
        days: [],
      };
      hours.value = [...hours.value, newHours];
    };

    const updateHours = (hourData) => {
      const index = hours.value.findIndex((h) => h.id === hourData.id);
      if (index > -1) {
        const hrs = hours.value;
        hrs.splice(index, 1, hourData);
        hours.value = hrs;
      }
    };

    const removeHours = (hourId) => {
      const index = hours.value.findIndex((h) => h.id === hourId);
      if (index > -1) {
        const hrs = hours.value;
        hrs.splice(index, 1);
        hours.value = hrs;
      }
    };

    const isTimezoneSet = computed(() => hours.value.some((h) => h.timezone));

    const activeSelector = ref('0');
    const isActiveSelector = (id: string) => id === activeSelector.value;
    const setSelectorActive = (id: string) => {
      activeSelector.value = id;
    };

    const save = () => {
      // TODO: Hookup to api once it exists
      console.log(hours);
      emit('close');
    };

    const cancel = () => emit('close');

    // validate the hour values if not 24/7 or Unavailable
    const validHours = computed(() => {
      if (activeSelector.value === '1' || activeSelector.value === '2') {
        return true;
      }

      let isValid = true;
      hours.value.forEach((h) => {
        console.log(h);
        if (!h.startTime || !h.endTime || !h.days.length) {
          isValid = false;
        }
      });
      return isValid;
    });

    return {
      hours,
      addNewHours,
      isTimezoneSet,
      activeSelector,
      isActiveSelector,
      setSelectorActive,
      updateHours,
      removeHours,
      save,
      cancel,
      validHours,
    };
  },
});
