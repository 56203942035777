
import { defineComponent, ref, watchEffect } from 'vue';
import { SimplifiedState } from '@/clients/model';

export default defineComponent({
  props: {
    labState: String,
  },
  setup(props) {
    const stateClass = ref('gray');
    const stateText = ref('Ready for Work');

    watchEffect(() => {
      if (!props.labState) {
        stateClass.value = 'gray';
        stateText.value = 'Ready for Work';
        return;
      }

      switch (props.labState) {
        case SimplifiedState.UNKNOWN:
        case SimplifiedState.CANCELLING:
        case SimplifiedState.CANCELLED:
        case SimplifiedState.CREATED:
        case SimplifiedState.INITIALIZED: {
          stateClass.value = 'gray';
          stateText.value = 'Ready for Work';
          return;
        }
        case SimplifiedState.STARTING:
        case SimplifiedState.RUNNING:
        case SimplifiedState.RUNNING_WITH_ASSISTANCE:
        case SimplifiedState.STOPPING:
        case SimplifiedState.PAUSING:
        case SimplifiedState.RESUMING: {
          stateClass.value = 'blue';
          stateText.value = 'Running';
          return;
        }
        case SimplifiedState.PAUSED: {
          stateClass.value = 'dark-blue';
          stateText.value = 'Paused';
          return;
        }
        case SimplifiedState.RUNNING_NEED_ASSISTANCE: {
          stateClass.value = 'yellow';
          stateText.value = 'Needs Assistance';
          return;
        }
        case SimplifiedState.ERROR:
        case SimplifiedState.RUNNING_WITH_ERROR: {
          stateClass.value = 'red';
          stateText.value = 'Error';
          return;
        }
        default:
          stateClass.value = 'gray';
          stateText.value = 'Unknown';
          return;
      }
    });

    return {
      stateClass,
      stateText,
    };
  },
});
