import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    title: "Additional material data",
    visible: _ctx.visible,
    "before-close": _ctx.handleClose,
    "custom-class": "job-details__materials-dialog"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table, {
        data: _ctx.metadataArray,
        "show-header": false,
        size: "small",
        "max-height": "400"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, { prop: "key" }),
          _createVNode(_component_el_table_column, { prop: "value" })
        ]),
        _: 1
      }, 8, ["data"])
    ]),
    _: 1
  }, 8, ["visible", "before-close"]))
}