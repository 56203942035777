
import { defineComponent } from 'vue';
export default defineComponent({});
// import { saveAs } from 'file-saver';
// import { FileInstance } from '@/protos/common/messages_pb';
// import OpsController from '@/clients/ops';
//
// interface Props {
//   files: FileInstance.AsObject[];
// }
//
// export default defineComponent({
//   props: {
//     files: Array,
//   },
//   setup() {
//     return {
//       async downloadFile(file: FileInstance.AsObject) {
//         const f = await OpsController.Instance.dispatchDownloadAttachment(
//           file.id
//         );
//         const blob = new Blob([f.getContent()]);
//         saveAs(blob, file.name || 'AttachedFile.txt');
//       },
//     };
//   },
// });
