import { Common } from '@/clients/model';

export interface Resource {
  id: string;
  modified?: string;
  label?: string;
  name: string;
  description: string;
  createdBy?: string;
  previewImage?: string;
  tab?: string;
  common?: Common;
}

export enum Tab {
  AllDesign = 'all',
  AllOverview = 'overview',
  DataRecords = 'dataRecords',
  Labs = 'labs',
  Assistants = 'assistants',
  Protocols = 'protocols',
  Workflows = 'workflows',
  Users = 'users',
  Tokens = 'tokens',
  Ops = 'ops',
  Results = 'results',
  Recent = 'recent',
  Starred = 'starred',
  Settings = 'settings',
}

export interface TabResource extends Resource {
  tab: Tab;
  icon?: string;
  previewImage: string;
  removeFn: (string) => void;
  exportFn: (Resource) => Promise<void>;
  selectFn: (string) => void;
}

export interface Dimensions {
  width: number;
  height: number;
}
