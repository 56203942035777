import { gql } from '@apollo/client/core';

export const EVENT_TEMPLATE_FIELDS = gql`
  fragment EventTemplateFields on EventTemplate {
    id
    labId
    operationType
    operationDetails
    recurrenceType
    startDate
    startTime
    endDate
    title
    description
  }
`;
