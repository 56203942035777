
import { defineComponent, computed, PropType } from 'vue';
import RequestsDetails from './RequestsDetails.vue';
import Params from './Params.vue';
import ConfigEditor from '@/components/GenericForm/ConfigEditor.vue';
import { SimplifiedState } from '@/clients/model';
import { Job } from '@/clients/ops/model';
import { Action } from '@/clients/action/model';
import JobLifecycleSummary from './JobLifecycleSummary.vue';
import PendingSummary from './PendingSummary.vue';
import { EntityType } from '@/clients/config';

// interface Props {
//   job: Job;
//   actions: Action[];
//   jobState: string;
// }

export default defineComponent({
  components: {
    JobLifecycleSummary,
    PendingSummary,
    RequestsDetails,
    Params,
    ConfigEditor,
  },
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true,
    },
    actions: {
      type: Array as PropType<(Action | undefined)[]>,
      required: false,
    },
    jobState: String,
  },
  emits: ['updateSelectedJob', 'execute-command'],
  setup(props, { emit }) {
    const updateSelectedJob = (job: Job) => emit('updateSelectedJob', job);

    const isRequest = computed(
      () => props.job.state === SimplifiedState.CREATED || props.job.parentId
    );

    const isJobFinished = computed(
      () =>
        props.job.state === SimplifiedState.FINISHED ||
        props.job.state === SimplifiedState.CANCELLING ||
        props.job.state === SimplifiedState.CANCELLED
    );

    const handleCreateJob = () => {
      emit('execute-command', 'SCHEDULE');
    };

    return {
      EntityType,
      isRequest,
      updateSelectedJob,
      isJobFinished,
      handleCreateJob,
    };
  },
});
