import { isArray, isString } from 'lodash';
import pointer from 'json-pointer';
import {
  RequestJSONSchema,
  SimpleResponse,
} from '@/components/GenericForm/types';
import { Action } from './action';

export type TraverseFunction = (
  obj: Record<string, unknown>,
  prop: string,
  value: unknown,
  scope: string[]
) => void;

/**
 * Recursively visits every field in an object
 * @param object the object to traverse
 * @param fn the function to invoke on every field
 */
export const traverseObject = (
  object: Record<string, unknown>,
  fn: TraverseFunction
): void => traverseInternal(object, fn, []);

const traverseInternal = (
  object: Record<string, unknown>,
  fn: TraverseFunction,
  scope: string[] = []
): void => {
  Object.entries(object).forEach(([key, value]) => {
    fn.apply(this, [object, key, value, scope]);
    if ((value !== null && typeof value === 'object') || isArray(value)) {
      traverseInternal(value as Record<string, unknown>, fn, scope.concat(key));
    }
  });
};

const convertSingleValue = (val: unknown): number => {
  if (isString(val)) {
    const i = Number.parseInt(val);
    if (!Number.isSafeInteger(i)) {
      console.warn(`Found an unsafe integer in a parameter value: ${val}`);
    }
    return i;
  }
  return val as number;
};

/**
 * Given the current scope of traversal in a value object
 * cross reference the schema that represents that object and build
 * a JSON pointer path to find the matching schema entry
 * @param scope the path to the current field in the traversal
 * @param schema the schema
 * @returns a path to the item in the schema that describes the value entry
 */
const buildSchemaPath = (
  scope: string[],
  schema: RequestJSONSchema
): string[] => {
  const scopePath = ['properties'];
  scope.forEach((s, index) => {
    if (Number.isNaN(Number.parseInt(s))) {
      scopePath.push(s);
    } else if (index < scope.length) {
      scopePath.push('items');
      const schemaPtr = pointer.compile(scopePath);
      const subSchema = pointer.get(schema, schemaPtr);
      // @ts-ignore
      if (subSchema?.type === 'object') {
        scopePath.push('properties');
      }
    }
  });

  return scopePath;
};

const convertInternal = (
  values: SimpleResponse,
  schema: RequestJSONSchema
): SimpleResponse => {
  traverseObject(
    values as Record<string, unknown>,
    (_obj, prop, val, scope) => {
      try {
        // from our current scope, find the pointer into the schema to find out what type of thing
        // val points to.
        const valuePath = [...scope, prop.toString()];
        const schemaPath = buildSchemaPath(valuePath, schema);
        const schemaPtr = pointer.compile(schemaPath);
        const schemaEntry = pointer.get(schema, schemaPtr);
        // @ts-ignore
        if (schemaEntry?.type === 'integer') {
          const valuePtr = pointer.compile(valuePath);
          pointer.set(values, valuePtr, convertSingleValue(val));
        }
      } catch (_) {
        console.error(`Error converting value ${val} at path ${scope}`);
      }
    }
  );
  return values;
};

/**
 * Recursively traverses all an action's parameter values and looks for
 * entries that _should_ be integers but are instead passed as strings
 * and replaces any such items with their numeric values
 * @param action the action to check
 * @returns the action parameter values with all relevant entries converted to numbers
 */
export const convertActionParameterIntegerValues = (
  action: Action
): SimpleResponse => {
  if (action.parameterValues) {
    try {
      const vals = JSON.parse(action.parameterValues) as SimpleResponse;
      if (!action.schema) {
        // if we don't have a schema, we can't derive the value type so just return
        return vals;
      }
      const schema = JSON.parse(action.schema) as RequestJSONSchema;
      return convertInternal(vals, schema);
    } catch (_) {
      console.error(
        `Action ${action.id} has invalid parameter value or schema JSON`
      );
      return {};
    }
  }
  return {};
};
