/* eslint-disable @typescript-eslint/no-explicit-any */
export default class DeferredPromise<T> implements Promise<T> {
  private promiseInternal: Promise<T>;
  private resolveFunc?: (value: T | PromiseLike<T>) => void;
  private rejectFunc?: (reason?: any) => void;
  private _state: 'pending' | 'fulfilled' | 'rejected' = 'pending';

  constructor() {
    this.promiseInternal = new Promise((resolve, reject) => {
      this.resolveFunc = resolve;
      this.rejectFunc = reject;
    });
  }

  resolve(value: T | PromiseLike<T>): void {
    if (this.resolveFunc) {
      this.resolveFunc(value);
      this._state = 'fulfilled';
    } else {
      console.error('Failed to resolve DeferredPromise.');
    }
  }

  reject(val?: any) {
    if (this.rejectFunc) {
      this.rejectFunc(val);
      this._state = 'rejected';
    } else {
      console.error('Failed to reject DeferredPromise.');
    }
  }

  get state() {
    return this._state;
  }

  // implement the standard Promise interface below
  then<TResult1, TResult2>(
    onfulfilled?: (value: T) => TResult1 | PromiseLike<TResult1>,
    onrejected?: (reason: any) => TResult2 | PromiseLike<TResult2>
  ): Promise<TResult1 | TResult2> {
    return this.promiseInternal.then(onfulfilled, onrejected);
  }

  catch<TResult = never>(
    onrejected?: (reason: any) => TResult | PromiseLike<TResult>
  ): Promise<T | TResult> {
    return this.promiseInternal.catch(onrejected);
  }

  finally(onfinally?: () => void): Promise<T> {
    return this.promiseInternal.finally(onfinally);
  }

  get [Symbol.toStringTag]() {
    return 'DeferredPromise';
  }
}
