import { reactive } from 'vue';

export enum RequestStates {
  CREATE_WORKFLOW_REQUEST,
  CREATE_ASSISTANT_REQUEST,
  CREATE_REQUEST_FILE,
  CREATE_JOB,
  QUICK_JOB,
  ASSISTANT_LIBRARY,
}

interface StateInterface {
  requestState: null | RequestStates;
}

export default class Controller {
  private static instance: Controller;
  private state: StateInterface;

  private constructor() {
    this.state = reactive({
      requestState: null,
    });
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  set requestState(newState: RequestStates | null) {
    this.state.requestState = newState;
  }

  get requestState(): RequestStates | null {
    return this.state.requestState;
  }
}
