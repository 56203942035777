import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-561d3dad"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kv-table-container" }
const _hoisted_2 = { class: "table-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createVNode(_component_el_table, {
      data: _ctx.keys,
      height: "100%"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, { label: "Name" }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.keys[scope.$index],
              "onUpdate:modelValue": ($event: any) => ((_ctx.keys[scope.$index]) = $event),
              onChange: _ctx.kvPairsChanged,
              readonly: _ctx.forceReadOnly,
              placeholder: "Enter a new key...",
              autocomplete: "off"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "readonly"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, { label: "Value" }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.values[scope.$index],
              "onUpdate:modelValue": ($event: any) => ((_ctx.values[scope.$index]) = $event),
              onChange: _ctx.kvPairsChanged,
              readonly: _ctx.forceReadOnly,
              autocomplete: "off"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "readonly"])
          ]),
          _: 1
        }),
        (!_ctx.forceReadOnly)
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 0,
              width: "50px"
            }, {
              default: _withCtx((scope) => [
                (_ctx.keys[scope.$index])
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      icon: "Close",
                      circle: "",
                      text: "",
                      size: "small",
                      onClick: ($event: any) => (_ctx.deleteRow(scope.$index))
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}