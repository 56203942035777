
import { Ref, defineComponent, ref, watch, PropType } from 'vue';

// interface Props {
//   tabNames: string[];
//   startingTab: string;
//   border?: boolean;
//   size?: string;
//   leftAlign?: boolean;
//   transparent?: boolean;
//   whiteText?: boolean;
// }

export default defineComponent({
  props: {
    tabNames: {
      type: Array as PropType<string[]>,
      required: true,
    },
    startingTab: {
      type: String,
      required: true,
    },
    border: {
      required: false,
      type: Boolean,
      default: true,
    },
    size: {
      required: false,
      type: String,
      default: '',
    },
    leftAlign: {
      required: false,
      type: Boolean,
      default: false,
    },
    transparent: {
      required: false,
      type: Boolean,
      default: false,
    },
    whiteText: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const activeName: Ref<string> = ref(props.startingTab || '');
    const isActive = (tabName: string) => {
      return tabName === activeName.value;
    };

    const handleClick = (clickedTab) => {
      activeName.value = clickedTab;
      emit('setTabFilter', clickedTab);
    };

    const getSize = () => props.size || '';

    watch(
      () => props.tabNames,
      (newNames) => {
        if (newNames) {
          activeName.value = props.startingTab;
        }
      }
    );

    return {
      activeName,
      isActive,
      handleClick,
      getSize,
    };
  },
});
