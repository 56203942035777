import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39866560"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile" }
const _hoisted_2 = { class: "profile__categories" }
const _hoisted_3 = { class: "profile__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_category_selector = _resolveComponent("category-selector")!
  const _component_account = _resolveComponent("account")!
  const _component_tokens = _resolveComponent("tokens")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_category_selector, {
        categories: _ctx.categories,
        onSetCategory: _ctx.selectedCategoryUpdate
      }, null, 8, ["categories", "onSetCategory"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.selectedCategory.name === 'account')
        ? (_openBlock(), _createBlock(_component_account, { key: 0 }))
        : (_openBlock(), _createBlock(_component_tokens, { key: 1 }))
    ])
  ]))
}