
import { defineComponent, computed, watchEffect } from 'vue';
import OpsController from '@/clients/ops/controller';
import RunNowButton from '@/components/RunNowButton.vue';
import ArrowLink from '@/components/ArrowLink.vue';
import { useRoute } from 'vue-router';

// interface Props {
//   job: Job;
// }

export default defineComponent({
  components: {
    ArrowLink,
    RunNowButton,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  emits: ['create-job', 'updateSelectedJob'],
  setup(props, { emit }) {
    const route = useRoute();

    const goToParentJob = () => emit('updateSelectedJob', parentJob.value);

    watchEffect(() => {
      if (props.job.parentId) {
        OpsController.Instance.dispatchGetJobNames([props.job.parentId]);
      }
    });

    const parentJob = computed(() => {
      if (props.job.parentId) {
        return OpsController.Instance.getJob(props.job.parentId);
      }
      return null;
    });

    const isPlanningPage = computed(() => route.path.includes('/ops/'));

    return {
      goToParentJob,
      parentJob,
      isPlanningPage,
    };
  },
});
