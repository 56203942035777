
import { defineComponent, ref, computed, PropType } from 'vue';
import { formatDateTime } from '@/pages/utils';
import UserController from '@/clients/users/controller';
import UIController from '@/clients/ui/controller';
import { SimplifiedState } from '@/clients/model';
import { Action, ActionType } from '@/clients/action/model';
import RunningDots from '@/components/RunningDots.vue';

// interface Props {
//   action: Action;
//   parent?: Action;
//   step: string;
//   finished?: boolean;
// }

export default defineComponent({
  name: 'ActionItem',
  components: {
    RunningDots,
  },
  props: {
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    parent: Object,
    step: String,
    finished: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isExpert = UIController.Instance.expert;
    const open = ref(false);
    const toggleOpen = () => (open.value = !open.value);

    const state = computed(() => props.action.state || props.parent?.state);

    const formattedStep = computed(() => {
      if (props.parent) {
        return props.step;
      }
      return `${props.step}.0`;
    });

    const createdBy = computed(() => {
      if (props.action.common) {
        return UserController.Instance.getUser(props.action.common.createdBy)
          .name;
      } else if (props.parent?.common?.createdBy) {
        return UserController.Instance.getUser(props.parent.common.createdBy)
          .name;
      }
      return '';
    });

    const startedOnDate = computed(() => {
      if (props.action.common || props.parent?.common) {
        const date = props.action?.timelog?.actual?.endTimestamp;
        if (date) {
          return ` on ${formatDateTime(date).datetime}`;
        }
      }
      return '';
    });

    const completedDate = computed(() => {
      if (props.action?.timelog?.actual?.endTimestamp) {
        const date = props.action?.timelog?.actual?.endTimestamp;
        if (date) {
          return ` on ${formatDateTime(date).datetime}`;
        }
      }
      return '';
    });

    const copyActionId = () => {
      navigator.clipboard.writeText(props.action.id);
    };

    return {
      SimplifiedState,
      ActionType,
      open,
      toggleOpen,
      state,
      formattedStep,
      completedDate,
      startedOnDate,
      createdBy,
      isExpert,
      copyActionId,
    };
  },
});
