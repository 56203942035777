import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0766784a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['check-square', { disabled: _ctx.disabled }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
  }, [
    _createElementVNode("img", {
      class: "check-square__icon",
      src: _ctx.checkIcon,
      alt: "selected"
    }, null, 8, _hoisted_1)
  ], 2))
}