export enum Columns {
  SELECT = 'select',
  NAME = 'names',
  STATE = 'state',
  ACTION = 'action',
  CREATED = 'createdTimestamp',
  CREATED_BY = 'createdByList',
  WORKFLOW = 'workflowIds',
  START = 'timelogActualStart',
  START_ESTIMATE = 'timelogEstimateStart',
  MODIFIED = 'modifiedTimestamp',
  END = 'timelogActualEnd',
  END_ESTIMATE = 'timelogEstimateEnd',
  LAB = 'labIds',
  CONFIG = 'config',
}

export enum OrderById {
  SELECT = '',
  NAME = 'name',
  STATE = 'state',
  ACTION = '',
  CREATED = 'createdTimestamp',
  CREATED_BY = 'createdBy',
  WORKFLOW = 'workflowId',
  START = 'timelogActualStart',
  START_ESTIMATE = 'timelogEstimateStart',
  MODIFIED = 'modifiedTimestamp',
  END = 'timelogActualEnd',
  END_ESTIMATE = 'timelogEstimateEnd',
  LAB = 'labId',
  CONFIG = '',
}

export const ColumnLabels = {
  select: '',
  name: 'Name',
  state: 'State',
  action: 'Current Activity',
  createdTimestamp: 'Date Created',
  createdBy: 'Created By',
  workflow: 'Workflow',
  startTime: 'Start Time',
  startTimeEstimate: 'Estimated Start Time',
  modifiedTime: 'Last Updated',
  endTime: 'End Time',
  endTimeEstimate: 'Estimated End Time',
  labId: 'Lab',
  config: '',
};

export enum OrderByFieldType {
  DISCRETE, // more than one row can have the same value, orderBy should always prioritize these fields
  CONTINUOUS, // generally speaking, only one row can have this value
  NONE, // cannot sort on this field at all
}

export interface ColumnDescription {
  id: Columns;
  orderById: OrderById;
  label: string;
  fieldType: OrderByFieldType;
}

export const COLUMNS: ColumnDescription[] = [
  {
    id: Columns.SELECT,
    orderById: OrderById.SELECT,
    label: ColumnLabels.select,
    fieldType: OrderByFieldType.NONE,
  },
  {
    id: Columns.NAME,
    orderById: OrderById.NAME,
    label: ColumnLabels.name,
    fieldType: OrderByFieldType.CONTINUOUS,
  },
  {
    id: Columns.STATE,
    orderById: OrderById.STATE,
    label: ColumnLabels.state,
    fieldType: OrderByFieldType.DISCRETE,
  },
  {
    id: Columns.ACTION,
    orderById: OrderById.ACTION,
    label: ColumnLabels.action,
    fieldType: OrderByFieldType.NONE,
  },
  {
    id: Columns.CREATED,
    orderById: OrderById.CREATED,
    label: ColumnLabels.createdTimestamp,
    fieldType: OrderByFieldType.CONTINUOUS,
  },
  {
    id: Columns.CREATED_BY,
    orderById: OrderById.CREATED_BY,
    label: ColumnLabels.createdBy,
    fieldType: OrderByFieldType.DISCRETE,
  },
  {
    id: Columns.WORKFLOW,
    orderById: OrderById.WORKFLOW,
    label: ColumnLabels.workflow,
    fieldType: OrderByFieldType.DISCRETE,
  },
  {
    id: Columns.START,
    orderById: OrderById.START,
    label: ColumnLabels.startTime,
    fieldType: OrderByFieldType.CONTINUOUS,
  },
  {
    id: Columns.START_ESTIMATE,
    orderById: OrderById.START_ESTIMATE,
    label: ColumnLabels.startTimeEstimate,
    fieldType: OrderByFieldType.CONTINUOUS,
  },
  {
    id: Columns.MODIFIED,
    orderById: OrderById.MODIFIED,
    label: ColumnLabels.modifiedTime,
    fieldType: OrderByFieldType.CONTINUOUS,
  },
  {
    id: Columns.END,
    orderById: OrderById.END,
    label: ColumnLabels.endTime,
    fieldType: OrderByFieldType.CONTINUOUS,
  },
  {
    id: Columns.END_ESTIMATE,
    orderById: OrderById.END_ESTIMATE,
    label: ColumnLabels.endTimeEstimate,
    fieldType: OrderByFieldType.CONTINUOUS,
  },
  {
    id: Columns.LAB,
    orderById: OrderById.LAB,
    label: ColumnLabels.labId,
    fieldType: OrderByFieldType.DISCRETE,
  },
  {
    id: Columns.CONFIG,
    orderById: OrderById.CONFIG,
    label: ColumnLabels.config,
    fieldType: OrderByFieldType.NONE,
  },
];
