
import {
  defineComponent,
  ref,
  Ref,
  onMounted,
  computed,
  watch,
  onUnmounted,
  PropType,
} from 'vue';
import * as d3 from 'd3';
import { DateTime } from 'luxon';
import GanttEvent from './GanttEvent.vue';
import EventDetails from './EventDetails.vue';
import TimeTag from './time-tag.svg';
import { ScaleTime } from 'd3';

interface ZoomValue {
  id: number;
  label: string;
  scaleValue: number;
  rangeValue: number;
}

// interface Props {
//   // TODO: the interface for data will change once we start getting the real data
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   data: any;
//   startDate: Date;
//   endDate: Date;
//   zoom: ZoomValue;
// }

export default defineComponent({
  components: {
    GanttEvent,
    EventDetails,
  },
  props: {
    data: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<any>,
      required: true,
    },
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
    zoom: {
      type: Object as PropType<ZoomValue>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const ganttChart: Ref<HTMLElement | null> = ref(null);
    const ticks: Ref<Date[]> = ref([]);
    // @ts-ignore
    const xScale: Ref<ScaleTime | null> = ref(null);

    const currentTime = ref(DateTime.now().toFormat('h:mm'));
    const getCurrentTime = () => {
      currentTime.value = DateTime.now().toFormat('h:mm');
      setTimeout(getCurrentTime, 60000);
    };
    getCurrentTime();

    const formatTick = (tickDate: Date): string => {
      return props.zoom.scaleValue >= 7
        ? DateTime.fromJSDate(tickDate).toFormat('d MMM')
        : DateTime.fromJSDate(tickDate).toFormat('h:mm a');
    };

    const getEventWidth = (end: Date, start: Date): number => {
      const width = xScale.value(end) - xScale.value(start);
      const leftShift = xScale.value(start);
      if (leftShift < 0) {
        return width + leftShift;
      }
      return width;
    };

    const getEventLeftShift = (start: Date): number => {
      const leftShift = xScale.value(start);
      return leftShift < 0 ? -2 : leftShift;
    };

    const displayPast = computed(
      () => DateTime.now() > DateTime.fromJSDate(props.startDate)
    );

    const scale = () => {
      if (ganttChart.value) {
        xScale.value = d3
          .scaleTime()
          .domain([props.startDate, props.endDate])
          .nice()
          .range([0, ganttChart.value.getBoundingClientRect().width - 190]);
        ticks.value = xScale.value.ticks(8);
      }
    };

    const handleEventZoom = (startTime: string) => {
      emit('zoomEvent', startTime);
    };

    onMounted(() => {
      setTimeout(() => scale(), 1); // otherwise for whatever reason it doesn't have the props data events
      addEventListener('resize', () => scale());
    });

    onUnmounted(() => removeEventListener('resize', () => scale()));

    watch(
      () => props.startDate,
      () => {
        scale();
      }
    );

    return {
      ganttChart,
      xScale,
      ticks,
      currentTime,
      formatTick,
      getEventWidth,
      getEventLeftShift,
      displayPast,
      TimeTag,
      handleEventZoom,
    };
  },
});
