import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43e33acb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "config-editor" }
const _hoisted_2 = {
  key: 0,
  class: "config-editor-header"
}
const _hoisted_3 = {
  key: 0,
  class: "config-editor-header-btns"
}
const _hoisted_4 = {
  key: 1,
  class: "config-editor-no-config"
}
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 0,
  class: "config-editor-header"
}
const _hoisted_7 = { class: "config-editor-header-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_generic_form = _resolveComponent("generic-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.handleUpdate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, _toDisplayString(_ctx.headerTitle), 1),
          (!_ctx.noConfig && _ctx.saveButtonPosition === 'top')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_Transition, { name: "fade" }, {
                  default: _withCtx(() => [
                    _withDirectives(_createVNode(_component_el_button, {
                      class: _normalizeClass(['header-btn', 'save-btn', { 'is-disabled': !_ctx.canSave }]),
                      size: "small",
                      onClick: _ctx.handleButtonClick,
                      disabled: !_ctx.canSave
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.saveButtonText), 1)
                      ]),
                      _: 1
                    }, 8, ["class", "onClick", "disabled"]), [
                      [_vShow, 
              !_ctx.readOnlyMode &&
              (!_ctx.hideButtonOnChange || (_ctx.hideButtonOnChange && _ctx.canSave))
            ]
                    ])
                  ]),
                  _: 1
                }),
                _withDirectives(_createVNode(_component_el_button, {
                  class: "header-btn edit-btn",
                  size: "small",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.readOnlyMode = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Edit ")
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _ctx.readOnlyMode]
                ]),
                (!_ctx.readOnlyMode && _ctx.confirmEdit)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      class: "header-btn",
                      size: "small",
                      onClick: _ctx.cancelChanges
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Cancel ")
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.noConfig)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, "No configuration"))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_generic_form, {
            schema: _ctx.configSchema,
            data: _ctx.configValues,
            "field-key": _ctx.configId || _ctx.config?.entityId || 'config',
            "onUpdate:responseData": _ctx.handleUpdateValues,
            "onError:responseData": _ctx.handleValidationError,
            "force-read-only": _ctx.readOnlyMode
          }, null, 8, ["schema", "data", "field-key", "onUpdate:responseData", "onError:responseData", "force-read-only"]),
          (_ctx.handleUpdate && _ctx.saveButtonPosition === 'bottom')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_Transition, { name: "fade" }, {
                    default: _withCtx(() => [
                      _withDirectives(_createVNode(_component_el_button, {
                        class: _normalizeClass(['header-btn', 'save-btn', { 'is-disabled': !_ctx.canSave }]),
                        onClick: _ctx.handleButtonClick,
                        disabled: !_ctx.canSave
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.saveButtonText), 1)
                        ]),
                        _: 1
                      }, 8, ["class", "onClick", "disabled"]), [
                        [_vShow, 
                !_ctx.readOnlyMode &&
                (!_ctx.hideButtonOnChange || (_ctx.hideButtonOnChange && _ctx.canSave))
              ]
                      ])
                    ]),
                    _: 1
                  }),
                  (!_ctx.readOnlyMode && _ctx.confirmEdit)
                    ? (_openBlock(), _createBlock(_component_el_button, {
                        key: 0,
                        class: "header-btn",
                        onClick: _ctx.cancelChanges
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Cancel ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}