import { computed, Ref } from 'vue';
import { saveAs } from 'file-saver';
import { isString } from 'lodash';
import { DateTime } from 'luxon';
import { Action } from '@/clients/action';
import { IoType, SimplifiedState, Unit } from '@/clients/model';
import { Job, ScheduleTactic } from '@/clients/ops';
import UserController from '@/clients/users';
interface Titleable {
  name: string;
}

export function setupTitle(obj: Readonly<Ref<Readonly<Titleable>>>) {
  return {
    title: computed(() => obj.value.name || ''),
  };
}

export function getJobStateDisplayString(job: Job): string {
  const state = job.state;
  switch (state) {
    case SimplifiedState.INITIALIZED:
      return 'Scheduled';
    case SimplifiedState.CANCELLED:
      return 'Canceled';
    case SimplifiedState.CREATED:
      return 'Pending';
    case SimplifiedState.FINISHED:
      return 'Completed';
    case SimplifiedState.RUNNING_NEED_ASSISTANCE:
      return 'Assistance Needed';
    case SimplifiedState.RUNNING_WITH_ASSISTANCE:
      return 'Assistant in Progress';
    case SimplifiedState.ON_HOLD:
      return 'On Hold';
    case SimplifiedState.ERROR:
    case SimplifiedState.RUNNING_WITH_ERROR:
      return 'In Error';
    case SimplifiedState.UNKNOWN:
      return 'Unknown';
    default:
      return state[0].toUpperCase() + state.substring(1).toLowerCase();
  }
}

export function isAssistantReady(state?: SimplifiedState): boolean {
  return (
    state === SimplifiedState.RUNNING_WITH_ASSISTANCE ||
    state === SimplifiedState.RUNNING_NEED_ASSISTANCE
  );
}

export function isRunning(state?: SimplifiedState): boolean {
  return state === SimplifiedState.RUNNING || isAssistantReady(state);
}

export function isInError(state?: SimplifiedState): boolean {
  return (
    state === SimplifiedState.ERROR ||
    state === SimplifiedState.RUNNING_WITH_ERROR
  );
}

export function isJobCancelable(state: SimplifiedState): boolean {
  return (
    state === SimplifiedState.RUNNING ||
    state === SimplifiedState.RUNNING_WITH_ASSISTANCE ||
    state === SimplifiedState.RUNNING_NEED_ASSISTANCE ||
    state === SimplifiedState.RESUMING ||
    state === SimplifiedState.ERROR ||
    state === SimplifiedState.RUNNING_WITH_ERROR ||
    state === SimplifiedState.PAUSED ||
    state === SimplifiedState.PAUSING ||
    state === SimplifiedState.ON_HOLD
  );
}

export function isJobPausable(state: SimplifiedState): boolean {
  return (
    state === SimplifiedState.RUNNING ||
    state === SimplifiedState.RUNNING_WITH_ASSISTANCE ||
    state === SimplifiedState.RUNNING_NEED_ASSISTANCE ||
    state === SimplifiedState.RESUMING
  );
}

export function isJobResumable(state: SimplifiedState): boolean {
  return state === SimplifiedState.PAUSED || state === SimplifiedState.PAUSING;
}

export function isJobUnschedulable(state: SimplifiedState): boolean {
  return (
    state === SimplifiedState.INITIALIZED ||
    (UserController.Instance.isActingAsArtificialUser && isJobCancelable(state))
  );
}

export function isJobDebuggable(state: SimplifiedState): boolean {
  return (
    state !== SimplifiedState.FINISHED && state !== SimplifiedState.CANCELLED
  );
}

export function isJobDeleteable(state: SimplifiedState): boolean {
  return state === SimplifiedState.CREATED;
}

interface EstimatedTimestamp {
  time: Date;
  estimate: boolean;
}

export function getStartTimeFromTimeLog(
  timedEntity: Job | Action
): EstimatedTimestamp | null {
  if (timedEntity?.timelog?.actual?.startTimestamp) {
    return {
      time: new Date(timedEntity.timelog.actual.startTimestamp),
      estimate: false,
    };
  }
  if (timedEntity?.timelog?.estimate?.startTimestamp) {
    return {
      time: new Date(timedEntity.timelog.estimate.startTimestamp),
      estimate: true,
    };
  }
  return null;
}

export function getTimeFromTimelog(
  timedEntity: Job | Action,
  startOrEnd: 'startTimestamp' | 'endTimestamp',
  precision: 'actual' | 'estimate'
) {
  if (timedEntity.timelog?.[precision]?.[startOrEnd]) {
    // @ts-ignore
    const date = new Date(timedEntity.timelog[precision][startOrEnd]);
    if (!Number.isNaN(date.valueOf())) {
      return date;
    }
  }
  return null;
}

export function getEndTimeFromTimeLog(
  timedEntity: Job | Action
): EstimatedTimestamp | null {
  if (timedEntity?.timelog?.actual?.endTimestamp) {
    return {
      time: new Date(timedEntity.timelog.actual.endTimestamp),
      estimate: false,
    };
  }
  if (timedEntity?.timelog?.estimate?.endTimestamp) {
    return {
      time: new Date(timedEntity.timelog.estimate.endTimestamp),
      estimate: true,
    };
  }
  return null;
}

interface FormattedDateTime {
  datetime: string;
  date: string;
  time: string;
  iso: string;
  ms: number;
  relative: string;
}

/**
 * takes in a js date and returns an object with datetime, date, and times formatted
 * @param date ISO string or JS Date object
 * @returns FormattedDateTime
 */
export function formatDateTime(
  date: string | Date | null | undefined,
  includeSeconds = false
): FormattedDateTime {
  if (date) {
    let dt: DateTime;
    if (isString(date)) {
      dt = DateTime.fromISO(date);
    } else {
      dt = DateTime.fromJSDate(date);
    }
    if (dt && dt.isValid) {
      return {
        datetime: dt.toLocaleString(
          includeSeconds
            ? DateTime.DATETIME_SHORT_WITH_SECONDS
            : DateTime.DATETIME_SHORT
        ),
        date: dt.toLocaleString(),
        time: dt.toLocaleString(DateTime.TIME_SIMPLE),
        iso: dt.toUTC().toString(),
        ms: dt.toMillis(),
        relative: dt.toRelative() || '',
      };
    }
  }

  return {
    datetime: '',
    date: '',
    time: '',
    iso: '',
    ms: 0,
    relative: '',
  };
}

// A function to use as a comparator for Array.sort on an array of action instances
// PRE: You've either already removed actionInstances that are not set to RUNNING, NOT_STARTED or DEFAULT
// OR you always want to prioritize RUNNING actions over all others
// This will sort RUNNING actions to the front of the array (in order of actual start time)
// Followed by all other actions sorted in order of earliest estimated start time
export function assistantSortFunc(left: Action, right: Action): number {
  if (left.state !== right.state) {
    return left.state === SimplifiedState.RUNNING ? -1 : 1;
  } else {
    if (
      left.timelog?.actual?.startTimestamp &&
      right.timelog?.actual?.startTimestamp
    ) {
      return left.timelog.actual.startTimestamp.localeCompare(
        right.timelog.actual.startTimestamp
      );
    }
    if (
      left.timelog?.actual?.startTimestamp &&
      !right.timelog?.actual?.startTimestamp
    ) {
      return -1;
    }
    if (
      right.timelog?.actual?.startTimestamp &&
      !left.timelog?.actual?.startTimestamp
    ) {
      return 1;
    }
    if (
      left.timelog?.estimate?.startTimestamp &&
      right.timelog?.estimate?.startTimestamp
    ) {
      return left.timelog.estimate.startTimestamp.localeCompare(
        right.timelog.estimate.startTimestamp
      );
    }
    return 0;
  }
}

export function calculateSamples(item: Job): number {
  return item.numSamples || 0;
  // if (item?.inputsList?.length) {
  //   return item.inputsList
  //     .map((input) =>
  //       input.name.toLowerCase().includes('tissue samples')
  //         ? input.quantity?.value || 0
  //         : 0
  //     )
  //     .reduce((a, b) => a + b);
  // }
  // return 0;
}

export function includeInAssistantActions(
  action: Action,
  showAll = false
): boolean {
  if (!action) {
    // Not sure what is causing this...
    console.warn('yo, figure out why this could be null then delete me');
    return false;
  }

  let include = !!action.assistant;
  if (!showAll) {
    include = include && action.state === SimplifiedState.RUNNING;
  } else {
    include =
      include &&
      (action.state === SimplifiedState.UNKNOWN ||
        action.state === SimplifiedState.RUNNING ||
        action.state === SimplifiedState.CREATED);
  }
  // we NEED either an estimated or actual start time
  // TODO: we don't get proper time estimates for Actions from the BE anymore
  // if (include) {
  //   include = !!getStartTimeFromTimeLog(action);
  // }
  return include;
}

export function tacticToName(tactic?: ScheduleTactic) {
  switch (tactic) {
    case ScheduleTactic.DEFAULT:
      return 'DEFAULT';
    case ScheduleTactic.APPEND:
      return 'APPEND';
    case ScheduleTactic.AFTER_JOB:
      return 'AFTER JOB';
    case ScheduleTactic.NOW:
      return 'ASAP';
    case ScheduleTactic.TIME:
      return 'CUSTOM';
    default:
      return 'UNKNOWN';
  }
}

export const saveObject = (json: string, name: string) => {
  const blob = new Blob([JSON.stringify(json, null, 2)], {
    type: 'text/json;charset=utf-8',
  });
  saveAs(
    blob,
    `${(name || 'ExportedItem').slice(0, 255).replace(/\s/g, '-')}.json`
  );
};

export function getIoTypeDisplayString(type: IoType): string {
  return type[0] + type.toLowerCase().slice(1).replaceAll('_', ' ');
}

export function getUnitDisplayString(unit: Unit): string {
  switch (unit) {
    case Unit.MICROLITER:
      return 'µl';
    case Unit.MILLILITER:
      return 'ml';
    case Unit.LITER:
      return 'l';
    default:
    case Unit.COUNT:
      return 'count';
  }
}

export function isTextOverflowed(element: HTMLElement | null): boolean {
  if (element) {
    return element.scrollWidth > element.clientWidth;
  }
  return false;
}
