import * as d3 from 'd3';

export function customWheelPan(
  selection: d3.Selection<SVGSVGElement, number, SVGGElement, number>,
  zoom: d3.ZoomBehavior<SVGSVGElement, number>,
  event: WheelEvent
) {
  const node = selection.node();
  if (node) {
    const zoomScale = d3.zoomTransform(node).k;
    const xScroll = -event.deltaX / zoomScale;
    const yScroll = -event.deltaY / zoomScale;
    // these seem to do the same thing... but the docs suggest you should use the .call()?
    // zoom.translateBy(selection, xScroll, yScroll);
    selection.call(zoom.translateBy, xScroll, yScroll);
    // prevent default or chrome might try to do more stuff
    event.preventDefault();
  }
}
