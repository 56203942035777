
import { computed, defineComponent } from 'vue';
import Checked from '@/assets/icons/black-checkbox-checked.svg';
import Unchecked from '@/assets/icons/black-checkbox-unchecked.svg';
import Indeterminate from '@/assets/icons/black-checkbox-indeterminate.svg';

interface Props {
  value: boolean;
  disabled: boolean;
  indeterminate: boolean;
}

export default defineComponent({
  props: {
    value: Boolean,
    disabled: Boolean,
    indeterminate: Boolean,
  },
  setup(props: Props, { emit }) {
    const checkIcon = computed(() => {
      if (props.indeterminate) {
        return Indeterminate;
      } else if (props.value) {
        return Checked;
      }
      return Unchecked;
    });
    const handleInput = () => {
      if (!props.disabled) {
        let newVal = false;
        if (props.indeterminate || (!props.indeterminate && !props.value)) {
          newVal = true;
        }
        emit('input', newVal);
      }
    };

    return {
      handleInput,
      checkIcon,
    };
  },
});
