
import { defineComponent, computed } from 'vue';
import GanttChartContainer from './GanttChartContainer.vue';

export default defineComponent({
  components: {
    GanttChartContainer,
  },
  props: {
    data: Array,
    labId: String,
    newEvent: Object,
    customDate: Date,
    initialZoom: {
      type: Number,
      default: 3,
    },
  },
  setup(props) {
    const formattedData = computed(() => props.data);

    return {
      formattedData,
    };
  },
});
