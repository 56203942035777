import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35bf20e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tree-view" }
const _hoisted_2 = { class: "item-header" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { class: "item-children" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "tree-view__item",
        key: item.id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(item.label), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (child) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(['child', { selected: _ctx.isSelected(child.id) }]),
              key: child.id,
              onClick: ($event: any) => (_ctx.handleClickItem(child.id))
            }, _toDisplayString(child.label), 11, _hoisted_5))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}