
import { defineComponent, Ref, ref } from 'vue';
import { saveObject } from '../utils';
import DataController from '@/clients/data';

export default defineComponent({
  setup() {
    const labImportRef: Ref<HTMLInputElement | null> = ref(null);
    const labExportRef: Ref<HTMLInputElement | null> = ref(null);
    const importLabDisabled = ref(true);
    const exportLabDisabled = ref(true);
    const workflowsImportRef: Ref<HTMLInputElement | null> = ref(null);
    const workflowsExportRef: Ref<HTMLInputElement | null> = ref(null);
    const importWorkflowsDisabled = ref(true);
    return {
      labImportRef,
      importLabDisabled,
      labExportRef,
      exportLabDisabled,
      uploadLab(e) {
        console.log(e);
        importLabDisabled.value = e.target.files.length !== 1;
      },
      submitLab() {
        console.log(labImportRef.value);
        const file = labImportRef.value?.files?.[0];
        if (file) {
          DataController.Instance.dispatchImportLab(file);
        }
      },
      pickLab(e) {
        console.log(e);
        exportLabDisabled.value = e.target.value === '';
      },
      async exportLab() {
        console.log(labExportRef.value);
        const id = labExportRef.value?.value;
        if (id) {
          const json = await DataController.Instance.dispatchExportLab(id);
          if (json) {
            saveObject(json, 'exportedLab');
          }
        }
      },

      workflowsImportRef,
      importWorkflowsDisabled,
      workflowsExportRef,
      uploadWorkflows(e) {
        importWorkflowsDisabled.value = e.target.files.length !== 1;
      },
      submitWorkflows() {
        const file = workflowsImportRef.value?.files?.[0];
        if (file) {
          DataController.Instance.dispatchImportWorkflows(file);
        }
      },
      async exportWorkflows() {
        console.log(workflowsExportRef.value);
        const id = workflowsExportRef.value?.value || '';
        const json = await DataController.Instance.dispatchExportWorkflows(id);
        if (json) {
          saveObject(json, 'exportedWorkflows');
        }
      },
    };
  },
});
