import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-81ec1f9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "gantt",
  ref: "ganttChart"
}
const _hoisted_2 = {
  key: 0,
  class: "timeline"
}
const _hoisted_3 = { class: "tick-label" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "label" }
const _hoisted_6 = {
  key: 1,
  class: "data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_event_details = _resolveComponent("event-details")!
  const _component_gantt_event = _resolveComponent("gantt-event")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.xScale)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ticks, (tick) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "tick",
              key: tick.valueOf(),
              style: _normalizeStyle(`left: ${_ctx.xScale(tick)}px;`)
            }, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formatTick(tick)), 1)
            ], 4))
          }), 128)),
          _createElementVNode("div", {
            class: "current-time",
            style: _normalizeStyle(`left: ${_ctx.xScale(Date.now()) - 8}px`)
          }, [
            _createElementVNode("img", { src: _ctx.TimeTag }, null, 8, _hoisted_4),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.currentTime), 1)
          ], 4)
        ]))
      : _createCommentVNode("", true),
    (_ctx.xScale)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_ctx.displayPast)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "past",
                style: _normalizeStyle(`width: ${_ctx.xScale(Date.now())}px`)
              }, null, 4))
            : _createCommentVNode("", true),
          (_ctx.displayPast)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "current",
                style: _normalizeStyle(`left: ${_ctx.xScale(Date.now())}px`)
              }, null, 4))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ticks, (line) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "grid-line",
              key: line.valueOf(),
              style: _normalizeStyle(`left: ${_ctx.xScale(line)}px;`)
            }, null, 4))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (category) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "category",
              key: category.actorId
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.events, (event) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(['event', { top: !event.locked }]),
                  key: event.id,
                  style: _normalizeStyle(`left: ${_ctx.getEventLeftShift(event.start)}px;`)
                }, [
                  _createVNode(_component_el_popover, {
                    trigger: "hover",
                    placement: "bottom",
                    width: "380",
                    "show-after": 500
                  }, {
                    reference: _withCtx(() => [
                      _createVNode(_component_gantt_event, {
                        event: event,
                        width: _ctx.getEventWidth(event.end, event.start),
                        onZoomEvent: _ctx.handleEventZoom
                      }, null, 8, ["event", "width", "onZoomEvent"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_event_details, { event: event }, null, 8, ["event"])
                    ]),
                    _: 2
                  }, 1024)
                ], 6))
              }), 128))
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 512))
}