import { unionBy } from 'lodash';
import { SimplifiedState } from '../model';
import { BusMessagePayload } from '../ui/controller';

export const JOB_SET_CHANGE = 'job-set-change';
export const JOB_STATE_CHANGE = 'job-state-change';

export enum JobSetChangeType {
  ADD,
  DELETE,
}

export interface JobSetChange {
  id: string;
  type: JobSetChangeType;
}

export class JobSetChangeMessagePayload implements BusMessagePayload {
  jobs: JobSetChange[];
  constructor(jobs: JobSetChange[]) {
    this.jobs = jobs;
  }
  merge(payload: BusMessagePayload, buffered: BusMessagePayload) {
    const newPayload = payload as unknown as JobSetChangeMessagePayload;
    const bufferedPayload = buffered as unknown as JobSetChangeMessagePayload;
    const newJobsPayload = unionBy(newPayload.jobs, bufferedPayload.jobs, 'id');
    return new JobSetChangeMessagePayload(newJobsPayload);
  }
}

export interface JobStateChange {
  id: string;
  oldState: SimplifiedState;
  newState: SimplifiedState;
}

export class JobStateChangeMessagePayload implements BusMessagePayload {
  jobs: JobStateChange[];
  constructor(jobs: JobStateChange[]) {
    this.jobs = jobs;
  }
  merge(payload: BusMessagePayload, buffered: BusMessagePayload) {
    const newPayload = payload as unknown as JobStateChangeMessagePayload;
    const bufferedPayload = buffered as unknown as JobStateChangeMessagePayload;
    const newJobsPayload: JobStateChange[] = [];
    newPayload.jobs.forEach((job) => {
      const existing = bufferedPayload.jobs.find((j) => j.id === job.id);
      if (existing) {
        newJobsPayload.push({
          id: existing.id,
          oldState: existing.oldState,
          newState: job.newState,
        });
      } else {
        newJobsPayload.push(job);
      }
    });
    return new JobStateChangeMessagePayload(newJobsPayload);
  }
  add(job: JobStateChange) {
    this.jobs.push(job);
  }
}
