
import { computed, defineComponent } from 'vue';
import downArrowIcon from '@/assets/icons/arrow-down.svg';
import downArrowActiveIcon from '@/assets/icons/arrow-down-bold.svg';
import upArrowIcon from '@/assets/icons/arrow-up.svg';
import upArrowActiveIcon from '@/assets/icons/arrow-up-bold.svg';
import { Columns } from './model';

// orderBy is structured as follows:
// an array of strings in pairs where orderBy[N] is either "asc" or "desc"
// followed by the field name in orderBy[N + 1]
export interface OrderByMessage {
  direction: 'desc' | 'asc';
  field: Columns;
}

export default defineComponent({
  props: {
    orderBy: {
      type: Array,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isOrderedDesc = computed(() => {
      const i = props.orderBy.indexOf(props.fieldName);
      if (i <= 0) {
        return false;
      }
      return props.orderBy[i - 1] === 'desc';
    });
    const isOrderedAsc = computed(() => {
      const i = props.orderBy.indexOf(props.fieldName);
      if (i <= 0) {
        return false;
      }
      return props.orderBy[i - 1] === 'asc';
    });
    const downArrow = computed(() =>
      isOrderedDesc.value ? downArrowActiveIcon : downArrowIcon
    );
    const upArrow = computed(() =>
      isOrderedAsc.value ? upArrowActiveIcon : upArrowIcon
    );
    const emitOrderBy = (direction: 'desc' | 'asc') => {
      emit('order-by', { direction, field: props.fieldName });
    };
    const order = computed(() => {
      const i = props.orderBy.indexOf(props.fieldName);
      if (i <= 0) {
        return null;
      }
      return (i - 1) / 2 + 1;
    });

    const orderSuffix = computed(() => {
      // TODO: this will have to change when we internationalize and this won't handle larger column counts
      if (order.value === 1) {
        return 'st';
      } else if (order.value === 2) {
        return 'nd';
      } else if (order.value === 3) {
        return 'rd';
      }
      return 'th';
    });

    return {
      isOrderedDesc,
      isOrderedAsc,
      downArrow,
      upArrow,
      emitOrderBy,
      order,
      orderSuffix,
    };
  },
});
