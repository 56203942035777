import { gql } from '@apollo/client/core';
import { apollo } from '../service.apollo';
import { TypedGroup, TypedGroupType } from './model';

export async function getGroups(labId: string): Promise<TypedGroup[]> {
  const response = await apollo.query<{ typedGroups: TypedGroup[] }>({
    variables: {
      labId,
      type: TypedGroupType.LAB_USERS,
    },
    query: gql`
      query GetTypedGroups($labId: ID!, $type: TypedGroupType!) {
        typedGroups(labId: $labId, type: $type) {
          groupId
          name
          type
          members
        }
      }
    `,
  });
  return response.data.typedGroups;
}

export async function createGroup(
  group: TypedGroup
): Promise<TypedGroup | undefined> {
  const response = await apollo.mutate<{ addTypedGroup: TypedGroup }>({
    variables: {
      labId: group.labId,
      name: group.name,
      members: group.members,
      type: TypedGroupType.LAB_USERS,
    },
    mutation: gql`
      mutation AddTypedGroup(
        $labId: ID!
        $name: String!
        $type: TypedGroupType!
        $members: [ID]
      ) {
        addTypedGroup(
          labId: $labId
          name: $name
          type: $type
          members: $members
        ) {
          groupId
          labId
          name
          type
          members
        }
      }
    `,
  });
  return response.data?.addTypedGroup;
}

export async function deleteGroup(
  groupId: string,
  labId: string
): Promise<boolean> {
  const response = await apollo.mutate<{ removeTypedGroup: boolean }>({
    variables: {
      labId,
      groupId,
    },
    mutation: gql`
      mutation RemoveTypedGroup($labId: ID!, $groupId: ID!) {
        removeTypedGroup(labId: $labId, groupId: $groupId)
      }
    `,
  });
  return !!response.data?.removeTypedGroup;
}

export async function updateGroup(
  group: TypedGroup
): Promise<TypedGroup | undefined> {
  const response = await apollo.mutate<{ updateTypedGroup: TypedGroup }>({
    variables: {
      groupId: group.groupId,
      labId: group.labId,
      name: group.name,
      members: group.members,
      type: TypedGroupType.LAB_USERS,
    },
    mutation: gql`
      mutation UpdateTypedGroup(
        $groupId: ID!
        $labId: ID!
        $name: String
        $type: TypedGroupType!
        $members: [ID]
      ) {
        updateTypedGroup(
          groupId: $groupId
          labId: $labId
          name: $name
          type: $type
          members: $members
        ) {
          groupId
          labId
          name
          type
          members
        }
      }
    `,
  });
  return response.data?.updateTypedGroup;
}
