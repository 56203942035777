import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4935b057"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-by" }
const _hoisted_2 = { class: "order-by-controls" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "order-by-badge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.downArrow,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitOrderBy('desc'))),
        style: {"margin-right":"6px"}
      }, null, 8, _hoisted_3),
      _createElementVNode("img", {
        src: _ctx.upArrow,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitOrderBy('asc')))
      }, null, 8, _hoisted_4)
    ]),
    (_ctx.order)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
          _createTextVNode(_toDisplayString(_ctx.order), 1),
          _createElementVNode("sup", null, _toDisplayString(_ctx.orderSuffix), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}