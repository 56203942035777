import graphql from './tokens.gql';
import { apollo } from '../service.apollo';
import { Token } from './model';

const tokensQuery = { query: graphql.tokens };

export async function listTokens(): Promise<Token[]> {
  const { data } = await apollo.query<{
    tokens: Token[];
  }>(tokensQuery);
  return data.tokens;
}

type CreateTokenResponse = { token: Token; hash: string };
export async function createNewToken(
  name: string
): Promise<CreateTokenResponse> {
  const { data, errors } = await apollo.mutate<{
    newToken: CreateTokenResponse;
  }>({
    mutation: graphql.newToken,
    variables: {
      name,
    },
    update(cache, { data }) {
      if (data) {
        let currentData = cache.readQuery<{
          tokens: Token[];
        }>(tokensQuery);
        if (!currentData) currentData = { tokens: [] };
        cache.writeQuery({
          ...tokensQuery,
          data: {
            tokens: [...currentData.tokens, data.newToken.token],
          },
        });
      }
    },
  });

  if (data) {
    return data.newToken;
  } else {
    throw errors;
  }
}

export async function deleteToken(id: string): Promise<Token> {
  const { data, errors } = await apollo.mutate<{
    deleteToken: Token;
  }>({
    mutation: graphql.deleteToken,
    variables: {
      id,
    },
    update(cache, { data }) {
      if (data) {
        let currentData = cache.readQuery<{
          tokens: Token[];
        }>(tokensQuery);
        if (!currentData) currentData = { tokens: [] };
        cache.writeQuery({
          ...tokensQuery,
          data: {
            tokens: [...currentData.tokens, data.deleteToken].filter(
              (token) => token.id !== id
            ),
          },
        });
      }
    },
  });

  if (data) {
    return data.deleteToken;
  } else {
    throw errors;
  }
}
