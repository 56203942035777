import { gql } from '@apollo/client/core';
import { apollo } from '../service.apollo';
import {
  EventOperationType,
  EventTemplate,
  EventTemplateBackend,
  mapFromBackend,
  mapToBackend,
  nullEventTemplate,
} from './model';
import { EVENT_TEMPLATE_FIELDS } from './queries';

export async function getCronJobs(labId: string): Promise<EventTemplate[]> {
  interface EventTemplatesQuery {
    eventTemplates: EventTemplateBackend[];
  }
  const result = await apollo.query<{
    eventTemplatesQuery: EventTemplatesQuery;
  }>({
    variables: {
      filters: { operationType: EventOperationType.SCHEDULE_JOB, labId },
    },
    query: gql`
      query EventTemplatesQuery($filters: EventTemplateFilter) {
        eventTemplatesQuery(filters: $filters) {
          eventTemplates {
            ...EventTemplateFields
          }
        }
      }
      ${EVENT_TEMPLATE_FIELDS}
    `,
  });
  if (result.data?.eventTemplatesQuery?.eventTemplates) {
    return result.data.eventTemplatesQuery.eventTemplates.map((e) =>
      mapFromBackend(e)
    );
  }
  return [];
}

export async function createCronJob(
  cronJob: EventTemplate
): Promise<EventTemplate> {
  const be = mapToBackend(cronJob);
  const result = await apollo.mutate<{
    createEventTemplate: EventTemplateBackend;
  }>({
    variables: {
      durationSecs: 1,
      labId: be.labId,
      operationDetails: be.operationDetails,
      operationType: be.operationType,
      recurrenceType: be.recurrenceType,
      startDate: be.startDate,
      startTime: be.startTime,
      title: be.title,
    },
    mutation: gql`
      mutation CreateEventTemplate(
        $durationSecs: Int!
        $labId: ID!
        $operationDetails: String!
        $operationType: OperationType!
        $recurrenceType: RecurrenceType!
        $startDate: String
        $startTime: String!
        $title: String!
      ) {
        createEventTemplate(
          durationSecs: $durationSecs
          labId: $labId
          operationDetails: $operationDetails
          operationType: $operationType
          recurrenceType: $recurrenceType
          startDate: $startDate
          startTime: $startTime
          title: $title
        ) {
          ...EventTemplateFields
        }
      }
      ${EVENT_TEMPLATE_FIELDS}
    `,
  });
  if (result.data?.createEventTemplate) {
    return mapFromBackend(result.data.createEventTemplate);
  }
  return nullEventTemplate();
}

export async function updateCronJob(
  cronJob: EventTemplate
): Promise<EventTemplate> {
  const be = mapToBackend(cronJob);
  const result = await apollo.mutate<{
    updateEventTemplate: EventTemplateBackend;
  }>({
    variables: {
      id: be.id,
      labId: be.labId,
      operationDetails: be.operationDetails,
      operationType: be.operationType,
      recurrenceType: be.recurrenceType,
      startDate: be.startDate,
      startTime: be.startTime,
      title: be.title,
    },
    mutation: gql`
      mutation UpdateEventTemplate(
        $id: ID!
        $labId: ID
        $operationDetails: String
        $operationType: OperationType
        $recurrenceType: RecurrenceType
        $startDate: String
        $startTime: String
        $title: String
      ) {
        updateEventTemplate(
          id: $id
          labId: $labId
          operationDetails: $operationDetails
          operationType: $operationType
          recurrenceType: $recurrenceType
          startDate: $startDate
          startTime: $startTime
          title: $title
        ) {
          ...EventTemplateFields
        }
      }
      ${EVENT_TEMPLATE_FIELDS}
    `,
  });
  if (result.data?.updateEventTemplate) {
    return mapFromBackend(result.data.updateEventTemplate);
  }
  return nullEventTemplate();
}

export async function deleteCronJob(id: string): Promise<boolean> {
  const result = await apollo.mutate<{ deleteEventTemplate: boolean }>({
    variables: {
      id,
    },
    mutation: gql`
      mutation DeleteEventTemplate($id: ID!) {
        deleteEventTemplate(id: $id)
      }
    `,
  });
  return !!result.data?.deleteEventTemplate;
}
