
import { defineComponent, ref, computed, Ref } from 'vue';
import TopFilterBar from '@/components/TopFilterBar.vue';

export default defineComponent({
  props: {
    src: String,
    labId: String,
  },
  components: {
    TopFilterBar,
  },
  setup(props) {
    const iframeRef: Ref<null | HTMLIFrameElement> = ref(null);
    const tabs = ['Refeeds', 'Images'];
    const selectedTab = ref(tabs[0]);
    const handleTabUpdate = (tab: string) => {
      selectedTab.value = tab;
      console.log(iframeRef.value);
      if (iframeRef.value) {
        console.log('reloaded');
        iframeRef.value.contentWindow?.location.reload();
      }
    };

    const srcUrl = computed(() => {
      let selectedUrl = `${props.src}/orders/refeeds`;
      if (selectedTab.value === 'Images') {
        selectedUrl = `${props.src}/orders/images`;
      }
      return selectedUrl;
    });

    return {
      tabs,
      handleTabUpdate,
      selectedTab,
      srcUrl,
      iframeRef,
    };
  },
});
