
import { computed, defineComponent, ref, watch, PropType } from 'vue';
import { RequestJSONSchema, SimpleResponse } from './types';
import { titleFromSchema } from './parse';

export default defineComponent({
  props: {
    initialValues: {
      type: Object as PropType<SimpleResponse>,
      required: true,
    },
    field: {
      type: Object as PropType<RequestJSONSchema>,
      required: true,
    },
    forceReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const label = computed(() => titleFromSchema(props.field));
    const keys = ref([...Object.keys(props.initialValues)]);
    const values = ref([...Object.values(props.initialValues)]);

    function initializeColumns() {
      keys.value = [...Object.keys(props.initialValues)];
      values.value = [...Object.values(props.initialValues)];
      if (!props.forceReadOnly) {
        keys.value.push('');
        values.value.push('');
      }
    }

    function objectifyResponse() {
      const result = {};
      for (let i = 0; i < keys.value.length; i += 1) {
        if (keys.value[i]) {
          result[keys.value[i]] = values.value[i];
        }
      }
      return result;
    }

    function kvPairsChanged() {
      const result = objectifyResponse();
      if (!props.forceReadOnly && keys.value[keys.value.length - 1] !== '') {
        keys.value.push('');
        values.value.push('');
      }
      emit('update:value', result);
    }

    function deleteRow(index: number) {
      keys.value.splice(index, 1);
      values.value.splice(index, 1);
      kvPairsChanged();
    }

    watch(
      () => props.initialValues,
      () => {
        initializeColumns();
      }
    );
    watch(
      () => props.forceReadOnly,
      (newVal, oldVal) => {
        if (!newVal && oldVal) {
          keys.value.push('');
          values.value.push('');
        } else if (
          newVal &&
          !oldVal &&
          keys.value[keys.value.length - 1] === '' &&
          values.value[values.value.length - 1] === ''
        ) {
          keys.value.splice(keys.value.length - 1, 1);
          values.value.splice(values.value.length - 1, 1);
        }
      }
    );
    initializeColumns();

    return { label, keys, values, kvPairsChanged, deleteRow };
  },
});
