
import { defineComponent, computed, ref, PropType, ComputedRef } from 'vue';
import moment from 'moment';
import { uniq } from 'lodash';
import OpsController from '@/clients/ops';
import UserController from '@/clients/users/controller';
import SearchBar from '@/components/SearchBar.vue';
// @ts-ignore
import StateTag from '@/components/StateTag.vue';
import ActionItem from './ActionItem.vue';
import { Job } from '@/clients/ops/model';
import { Action, ActionType } from '@/clients/action/model';

interface ActionWithInfo extends Action {
  start?: string;
  completed?: string;
}

export default defineComponent({
  components: {
    SearchBar,
    StateTag,
    ActionItem,
  },
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true,
    },
  },
  setup(props) {
    const formatDate = (timestamp) => {
      if (!timestamp || timestamp === '') {
        return '--';
      }

      const date = moment(timestamp).format('M/D/YY');
      const time = moment(timestamp).format('h:mm A');
      return `${date}, ${time}`;
    };

    const formatDuration = (duration) => {
      if (duration === 0) {
        return '--';
      } else if (duration.asDays() > 1) {
        const days = Math.ceil(duration.asDays());
        const hours = Math.ceil(duration.asHours() % 24);
        const minutes = Math.ceil(duration.asMinutes() % (24 * 60));
        return `${days} days, ${hours} hours, ${minutes} minutes`;
      } else if (duration.asHours() > 1) {
        const hours = Math.ceil(duration.asHours() % 24);
        const minutes = Math.ceil(duration.asMinutes() % (24 * 60));
        return `${hours} hours, ${minutes} minutes`;
      }
      const minutes = Math.ceil(duration.asMinutes());
      return `${minutes} minutes`;
    };

    const getUser = (userId) => {
      if (userId === 'Substrate') {
        return userId;
      } else if (userId) {
        return UserController.Instance.getUser(userId).name;
      }
      return '--';
    };

    const actions: ComputedRef<ActionWithInfo[]> = computed(() => {
      let list: Action[] = [];
      if (
        props.job.action &&
        props.job.action?.actionType === ActionType.WORKFLOW
      ) {
        // HANDLE ACTIONS ON A WORKFLOW
        const orderedActions = OpsController.Instance.getOrderedActions(
          props.job.action?.id || ''
        );
        list = OpsController.Instance.getActionsByJob(props.job.id).filter(
          (a) =>
            orderedActions.find((ja) => a.id === ja.id) &&
            (a.actionType === ActionType.ASSISTANT ||
              a.actionType === ActionType.SUBSTRATE ||
              a.actionType === ActionType.OTHER)
        );

        return (
          orderedActions
            ?.filter((action) => {
              return list.find((ua) => ua.id === action.id);
            })
            .filter((a) => !a?.assistant?.hideStep)
            .filter((a) => a) || []
        );
      } else if (props.job.action) {
        // HANDLE AN ACTION ITSELF
        list = [
          {
            ...props.job.action,
          },
        ];
      }

      return list
        .filter(
          (a) =>
            (a.actionType === ActionType.ASSISTANT ||
              a.actionType === ActionType.SUBSTRATE ||
              a.actionType === ActionType.ASSISTANT_STEP) &&
            a.name !== ''
        )
        .map((a) => {
          return {
            ...a,
            completedBy: getUser(a.completedBy),
            start: formatDate(a.timelog?.actual?.startTimestamp),
            completed: formatDate(a.timelog?.actual?.endTimestamp),
          };
        });
    });

    const users = computed(() => {
      if (actions.value) {
        const uniqueUsers = uniq(actions.value.map((a) => a.completedBy)).map(
          (u) => ({
            label: UserController.Instance.getUser(u || '').name,
            value: u,
          })
        );
        return [{ label: 'All', value: 'All' }, ...uniqueUsers];
      }
      return [];
    });

    const assistantsFilterText = ref('');

    const stateFilter = ref('ALL');
    const stateFilterOpts = [
      { label: 'All', value: 'ALL' },
      { label: 'Pending', value: 'CREATED' },
      { label: 'Scheduled', value: 'INITIALIZED' },
      { label: 'Running', value: 'RUNNING' },
      { label: 'Canceled', value: 'CANCELLED' },
      { label: 'Completed', value: 'FINISHED' },
    ];

    const startDateFilter = ref('');
    const endDateFilter = ref('');

    const completedBy = ref('All');

    const filteredActions = computed(() => {
      let filtered = actions.value;

      if (assistantsFilterText.value.length > 0) {
        filtered = filtered.filter((a) =>
          a.name
            .toLowerCase()
            .includes(assistantsFilterText.value.toLowerCase())
        );
      }

      if (completedBy.value !== 'All' && completedBy.value !== '') {
        filtered = filtered.filter((j) => j.completedBy === completedBy.value);
      }

      if (startDateFilter.value) {
        filtered = filtered.filter((j) => {
          if (j.start) {
            const jDate = new Date(j?.start).getDate();
            const sDate = new Date(startDateFilter.value).getDate();
            return jDate === sDate;
          }
        });
      }

      if (endDateFilter.value) {
        filtered = filtered.filter((j) => {
          if (j.completed) {
            const jDate = new Date(j?.completed).getDate();
            const sDate = new Date(endDateFilter.value).getDate();
            return jDate === sDate;
          }
        });
      }
      return filtered;
    });

    return {
      formatDuration,
      filteredActions,
      users,
      startDateFilter,
      completedBy,
      endDateFilter,
      assistantsFilterText,
      stateFilter,
      stateFilterOpts,
    };
  },
});
