import { uniqueId } from 'lodash';
import { reactive } from 'vue';
import { Toast } from './model';
import { PublicErrorSeverity } from '../ops/model';

interface ToastMap {
  [key: string]: Toast;
}

interface StateInterface {
  toasts: ToastMap;
}

export default class Controller {
  private static instance: Controller;
  private state: StateInterface;

  private constructor() {
    this.state = reactive({
      toasts: {},
    });
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  private severityToString(severity: PublicErrorSeverity): string {
    switch (severity) {
      case PublicErrorSeverity.CRITICAL: {
        return 'Critical error';
      }
      case PublicErrorSeverity.ERROR: {
        return 'Error';
      }
      case PublicErrorSeverity.WARNING: {
        return 'Warning';
      }
      default:
      case PublicErrorSeverity.INFO: {
        return 'Message';
      }
    }
  }

  private severityToPluralString(severity: PublicErrorSeverity): string {
    return this.severityToString(severity) + 's';
  }

  // GETTERS
  get toasts(): Toast[] {
    return Object.values(this.state.toasts);
  }

  //SETTERS
  addNewToast(
    message: string,
    context: string,
    persistent: boolean,
    action?: () => void,
    actionName?: string,
    severity?: PublicErrorSeverity
  ): void {
    let existingToast: Toast | undefined = undefined;
    let formattedMessage = message;
    if (severity) {
      // group by severity
      formattedMessage = `${this.severityToString(severity)}: ${message}`;
      existingToast = Object.values(this.state.toasts).find(
        (t) => t.severity === severity && t.context === context
      );
    }

    if (existingToast) {
      existingToast.count += 1;
      existingToast.message = this.severityToPluralString(
        severity || PublicErrorSeverity.UNKNOWN
      );
    } else {
      const id = uniqueId();
      const toast: Toast = {
        id,
        message: formattedMessage,
        context,
        severity,
        count: 1,
        actionName: actionName || '',
      };
      if (action !== undefined) {
        toast.action = () => {
          action();
          this.removeToast(id);
        };
      }
      if (!persistent) {
        // if not persistent, show for 4 seconds
        setTimeout(() => {
          this.removeToast(id);
        }, 4000);
      }
      this.state.toasts[id] = toast;
    }
  }

  removeToast(id: string) {
    delete this.state.toasts[id];
  }

  removeAllByToastContext(context: string) {
    const toastsToRemove = Object.values(this.state.toasts).filter(
      (t) => t.context === context
    );

    toastsToRemove.forEach((t) => delete this.state.toasts[t.id]);
  }

  removeAllErrorToasts() {
    const toastsToRemove = Object.values(this.state.toasts).filter(
      (t) => t.severity
    );

    toastsToRemove.forEach((t) => delete this.state.toasts[t.id]);
  }
}
