
import { defineComponent, computed } from 'vue';
import AuthController from '@/clients/auth';
import UserController from '@/clients/users';
import UIController from '@/clients/ui';
import ArrowLink from '@/components/ArrowLink.vue';

export default defineComponent({
  setup() {
    const user = computed(() => UserController.Instance.currentUser);
    const logout = () => AuthController.Instance.dispatchLogout();
    const apiDocsUrl = new URL(
      '/api/v1/docs',
      window.location.origin
    ).toString();
    const isArtificialUser = computed(() =>
      UserController.Instance.isCurrentUserArtificialDeveloper()
    );
    const isActingAsArtificialUser = computed({
      get: () => UserController.Instance.isActingAsArtificialUser,
      set: (val) => (UserController.Instance.isActingAsArtificialUser = val),
    });
    const devMode = computed({
      get: () => UIController.Instance.expert,
      set: (val) => {
        UIController.Instance.expert = val;
      },
    });
    return {
      user,
      logout,
      apiDocsUrl,
      devMode,
      isArtificialUser,
      isActingAsArtificialUser,
    };
  },
  components: { ArrowLink },
});
