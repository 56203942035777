import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "job-config" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_config_editor = _resolveComponent("config-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_Teleport, { to: "#center-portal" }, [
      _createVNode(_component_config_editor, {
        configId: _ctx.jobId,
        configType: _ctx.EntityType.ENTITY_TYPE_JOB,
        "onUpdate:responseData": _ctx.handleUpdateValues,
        "onError:responseData": _ctx.handleValidationError
      }, null, 8, ["configId", "configType", "onUpdate:responseData", "onError:responseData"])
    ]))
  ]))
}