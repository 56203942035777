
import { defineComponent, computed, PropType } from 'vue';
import TopFilterBar from '@/components/TopFilterBar.vue';
import Params from '../Summary/Params.vue';
import Files from './Files.vue';
import CreatedJobs from './CreatedJobs.vue';
import { SimplifiedState } from '@/clients/model';
import { Job } from '@/clients/ops/model';

export default defineComponent({
  components: {
    TopFilterBar,
    Params,
    CreatedJobs,
    Files,
  },
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isJobFinished = computed(
      () =>
        props.job.state === SimplifiedState.FINISHED ||
        props.job.state === SimplifiedState.CANCELLING ||
        props.job.state === SimplifiedState.CANCELLED
    );

    const updateSelectedJob = (job: Job) => emit('updateSelectedJob', job);

    return {
      SimplifiedState,
      updateSelectedJob,
      isJobFinished,
    };
  },
});
