
import { defineComponent, computed, ref, PropType } from 'vue';
import { DateTime } from 'luxon';
import { Action } from '@/clients/action';

// interface Props {
//   selectedAction: Action;
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   action: (args: any) => void;
// }

export default defineComponent({
  props: {
    isWorkflow: Boolean,
    selectedAction: {
      type: Object as PropType<Action>,
      required: true,
    },
    action: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const selectedConstraint = ref('asap');
    const date = ref(new Date());
    const time = ref(new Date());

    const actionList = computed(() => {
      if (props.selectedAction?.assistant) {
        return [props.selectedAction];
      }
      return props.selectedAction.children;
    });

    const disabledDate = (date: Date): boolean => {
      if (date) {
        return date.getTime() < DateTime.now().startOf('day').toMillis();
      }
      return false;
    };
    const disabledHours = (): number[] => {
      const now = DateTime.now();
      if (DateTime.fromJSDate(date.value).day === now.day) {
        return Array.from({ length: now.hour }).map((_, i) => i);
      }
      return [];
    };
    const disabledMinutes = (hour: number): number[] => {
      const now = DateTime.now();
      if (DateTime.fromJSDate(time.value).day === now.day) {
        if (hour === now.hour) {
          return Array.from({ length: now.minute }).map((_, i) => i);
        }
      }
      return [];
    };

    const handleConstraintSelection = (constraint) => {
      selectedConstraint.value = constraint;
      props.action({
        constraint: selectedConstraint.value,
        date: date.value,
        time: time.value,
      });
    };

    const handleDateUpdate = () => {
      props.action({
        constraint: selectedConstraint.value,
        date: date.value,
        time: time.value,
      });
    };

    return {
      selectedConstraint,
      date,
      time,
      actionList,
      disabledDate,
      disabledHours,
      disabledMinutes,
      handleConstraintSelection,
      handleDateUpdate,
    };
  },
});
