import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/close.svg'


const _withScopeId = n => (_pushScopeId("data-v-15942bcb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "close"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['full-page-dialog', { dark: _ctx.dark }])
  }, [
    (_ctx.closeButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("img", {
            src: _imports_0,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          })
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "body", {}, () => [
      _createTextVNode("Body content here")
    ], true)
  ], 2))
}