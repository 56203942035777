
import { defineComponent, PropType } from 'vue';
import { EntityConfiguration } from '@/clients/config/model';
import ConfigEditor from '@/components/GenericForm/ConfigEditor.vue';
import { EntityType } from '@/clients/config';

export default defineComponent({
  props: {
    orgConfig: {
      type: Object as PropType<EntityConfiguration>,
      required: true,
    },
  },
  components: {
    ConfigEditor,
  },
  setup(props) {
    return {
      EntityType,
      orgId: props.orgConfig.orgId,
    };
  },
});
