
import { defineComponent, computed, PropType } from 'vue';
import UserController from '@/clients/users';
import { Job } from '@/clients/ops/model';

export default defineComponent({
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true,
    },
  },
  emits: ['updateSelectedJob'],
  setup(props, { emit }) {
    const getUser = (userId) => UserController.Instance.getUser(userId).name;

    const requests = computed(() => {
      return props.job?.children ?? [];
    });

    const updateSelectedJob = (request) => emit('updateSelectedJob', request);

    return {
      getUser,
      requests,
      updateSelectedJob,
    };
  },
});
