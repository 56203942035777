<template>
  <div class="close-window">
    You are being redirected to the admin login page
  </div>
</template>
<style lang="scss" scoped>
.close-window {
  font-size: 18px;
  padding: 20px;
  font-weight: bold;
  &__message {
    margin-top: 10px;
    font-size: 16px;
    font-weight: normal;
  }
}
</style>
