import { reactive } from 'vue';
import { TypedGroup } from './model';
import * as Apollo from './service.apollo';
import { cloneDeep } from 'lodash';

interface StateInterface {
  groups: TypedGroup[];
  members: Record<string, string[]>;
}

export default class Controller {
  private static instance: Controller;
  private state: StateInterface;

  private constructor() {
    /*
     * STATE
     */
    this.state = reactive({
      groups: [],
      members: {},
    });
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  /*
   * ACTIONS/MUTATIONS
   */

  async dispatchGetGroups(labId: string) {
    const groups = await Apollo.getGroups(labId);
    // TODO: shouldn't need this clone once BE is tracking the groups
    this.state.groups = cloneDeep(groups);
    this.state.groups.forEach((group) => {
      this.state.members[group.groupId] = group.members;
    });
  }

  async dispatchCreateGroup(group: TypedGroup) {
    const newGroup = await Apollo.createGroup(group);
    if (newGroup) {
      this.state.groups.push(newGroup);
      this.state.members[newGroup.groupId] = newGroup.members;
    }
  }

  async dispatchDeleteGroup(groupId: string, labId: string) {
    const success = await Apollo.deleteGroup(groupId, labId);
    if (success) {
      this.state.groups = this.state.groups.filter(
        (group) => group.groupId !== groupId
      );
      delete this.state.members[groupId];
    }
  }

  async dispatchUpdateGroup(group: TypedGroup) {
    const updatedGroup = await Apollo.updateGroup(group);
    if (updatedGroup) {
      const index = this.state.groups.findIndex(
        (existingGroup) => existingGroup.groupId === group.groupId
      );
      this.state.groups.splice(index, 1, updatedGroup);
      this.state.members[updatedGroup.groupId] = updatedGroup.members;
    }
  }

  public get groups(): TypedGroup[] {
    return this.state.groups;
  }

  public groupById(id: string): TypedGroup | undefined {
    return this.state.groups.find((group) => group.groupId === id);
  }

  public groupMembers(groupId: string): string[] {
    return this.state.members[groupId] || [];
  }
}
