
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    file: Object,
  },
  setup(props, { emit }) {
    const previewUrl = computed(
      () =>
        `${process.env.VUE_APP_BASE_URL}${props.file?.relativeDownloadPath}?preview=true`
    );
    const close = () => emit('preview', null);
    return {
      previewUrl,
      close,
    };
  },
});
