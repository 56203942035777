import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-418b414b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subjobs" }
const _hoisted_2 = {
  key: 1,
  class: "no-subjobs-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.jobs && _ctx.jobs.length)
      ? (_openBlock(), _createBlock(_component_el_table, {
          key: 0,
          data: _ctx.jobs,
          height: "100%",
          onRowClick: _ctx.goToJob
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              label: "job",
              prop: "name",
              sortable: ""
            }),
            _createVNode(_component_el_table_column, {
              label: "started",
              sortable: ""
            }, {
              default: _withCtx((prop) => [
                _createTextVNode(_toDisplayString(_ctx.getStartTimeDisplay(prop.row)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "finished",
              sortable: ""
            }, {
              default: _withCtx((prop) => [
                _createTextVNode(_toDisplayString(_ctx.getEndTimeDisplay(prop.row)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "owner",
              sortable: ""
            }, {
              default: _withCtx((prop) => [
                _createTextVNode(_toDisplayString(_ctx.getCreatedBy(prop.row)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "status",
              sortable: ""
            }, {
              default: _withCtx((prop) => [
                _createTextVNode(_toDisplayString(_ctx.getJobStateDisplayString(prop.row)), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data", "onRowClick"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, "Empty."))
  ]))
}