import { gql } from '@apollo/client/core';

export const DASHBOARD_ASSISTANTS_QUERY = gql`
  query GetAssistants {
    assistants {
      id
      name
      actionType
      common {
        createdBy
        createdTimestamp
        modifiedTimestamp
        revision
      }
      constraint {
        labId
        creation {
          fromFile
          interactive
          quick
          recurring
          standalone
          programmaticallySchedulable
        }
      }
      schema
      parameterValues
    }
  }
`;

export const WORKFLOW_FIELDS = gql`
  fragment WorkflowFields on Action {
    id
    name
    description
    parentId
    actionType
    definedFrom {
      id
      rev
    }
    constraint {
      labId
      subactionPaths {
        toId
        fromId
      }
      actor {
        executorId
      }
      creation {
        fromFile
        interactive
        quick
        recurring
        standalone
        programmaticallySchedulable
      }
    }
    assistant {
      entrypoint
      compliant
    }
    common {
      createdBy
      createdTimestamp
      modifiedTimestamp
      revision
    }
    schema
    parameterValues
  }
`;

export const DOCUMENT_WORKFLOW_FIELDS = gql`
  fragment DocumentWorkflowFields on Action {
    ...WorkflowFields
    documentWorkflow {
      generatedWorkflowId
    }
  }
  ${WORKFLOW_FIELDS}
`;
