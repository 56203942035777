import { Timelog } from '@/clients/model';
import { isString } from 'lodash';
import moment from 'moment';

const isValidTimelog = (timelog: Timelog): boolean => {
  return !!(
    timelog?.actual?.startTimestamp ||
    timelog?.actual?.endTimestamp ||
    timelog?.estimate?.startTimestamp ||
    timelog?.estimate?.endTimestamp
  );
};

export const getStartDate = (timelog: Timelog): Date | null => {
  if (!isValidTimelog(timelog)) {
    return null;
  }
  if (timelog.actual && timelog.actual.startTimestamp) {
    return new Date(timelog.actual.startTimestamp);
  } else if (timelog.estimate && timelog.estimate.startTimestamp) {
    return new Date(timelog.estimate.startTimestamp);
  }
  return null;
};

export const getDate = (timelog: Timelog): string => {
  if (!isValidTimelog(timelog)) {
    return '';
  }

  const isFuture = timelog.actual?.startTimestamp === '' || !timelog.actual;
  const timestamp = isFuture
    ? timelog.estimate?.startTimestamp
    : timelog.actual?.endTimestamp;

  if (timestamp) {
    const currentDate = moment(new Date());
    const dateToCheck = moment(new Date(timestamp));

    if (moment(currentDate).isSame(dateToCheck, 'days')) {
      return 'Today';
    } else if (isFuture && dateToCheck.diff(currentDate, 'days') <= 1) {
      return 'Tomorrow';
    } else if (!isFuture && currentDate.diff(dateToCheck, 'days') <= 1) {
      return 'Yesterday';
    }
    return moment(dateToCheck).format('MMM D, YYYY');
  }
  return '';
};

export const getTime = (timelog: Timelog): string => {
  if (!isValidTimelog(timelog)) {
    return '--:--';
  }

  const isFuture = timelog.actual?.startTimestamp === '' || !timelog.actual;
  const timestamp = isFuture
    ? timelog.estimate?.startTimestamp
    : timelog.actual?.endTimestamp;
  if (timestamp) {
    return moment(timestamp).format('h:mm A');
  }
  return '--:--';
};

export const createNameHash = async (str: string) => {
  const buf = await crypto.subtle.digest(
    'SHA-1',
    new TextEncoder().encode(new Date().getTime().toString())
  );
  const hash = Array.prototype.map
    .call(new Uint8Array(buf), (x) => ('00' + x.toString(16)).slice(-2))
    .join('');

  return `${str}_${hash.slice(0, 6).toUpperCase()}`;
};

export const generateJobName = async (str: string): Promise<string> => {
  const name = str.replace(/\s|-/g, '_').toUpperCase();
  return createNameHash(name);
};

export const alphaNumericSortBy = (fieldName: string): ((a, b) => number) => {
  return (a, b) => {
    if (
      !(fieldName in a) ||
      !(fieldName in b) ||
      !isString(a[fieldName]) ||
      !isString(b[fieldName])
    ) {
      console.log(
        `Sort failed on object: ${JSON.stringify(a)}, object: ${JSON.stringify(
          b
        )}, field: ${fieldName}`
      );
      return 0;
    }
    return a[fieldName]?.localeCompare(b[fieldName], navigator.language, {
      numeric: true,
    });
  };
};
