
import { defineComponent, onBeforeUnmount } from 'vue';

export default defineComponent({
  props: {
    closeButton: {
      default: true,
      required: false,
    },
    dark: Boolean,
  },
  setup(_, { emit }) {
    const close = () => emit('close');
    const listenForEscape = (e) => {
      if (e.key === 'Escape') {
        close();
      }
    };

    document.addEventListener('keyup', listenForEscape);

    onBeforeUnmount(() =>
      document.removeEventListener('keyup', listenForEscape)
    );

    return {
      close,
    };
  },
});
