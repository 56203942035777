import { v4 as uuid } from 'uuid';
import UsersController from './users';
import OpsController from './ops';
import ActionController from './action';
import UIController from './ui';
import { connectWs } from './service.apollo';

const ClientWriterId = uuid();

export function loadWorld() {
  connectWs();
  UIController.Instance.dispatchSetLoadingWorld(true);
  Promise.allSettled([
    UsersController.Instance.dispatchGetWorld(),
    OpsController.Instance.dispatchGetWorld(),
    ActionController.Instance.dispatchGetWorld(),
  ]).finally(() => {
    UIController.Instance.dispatchSetLoadingWorld(false);
  });
}

export async function loadDashboard() {
  await OpsController.Instance.dispatchGetDashboardData();
  await ActionController.Instance.dispatchGetDashboardData();
}

export function loadOps(labId: string) {
  return Promise.all([
    OpsController.Instance.dispatchGetOpsData(labId),
  ]).finally(() => {
    OpsController.Instance.dispatchGetErrorsByLab(labId);
  });
}

export { ClientWriterId };
