
import { defineComponent, computed, ref, Ref, watchEffect } from 'vue';
import moment from 'moment';
import OpsController from '@/clients/ops/controller';
import ActionController from '@/clients/action/controller';
import { PublicError, Job } from '@/clients/ops/model';

interface Error extends PublicError {
  id?: string;
  job: Job;
  type: string;
  timestamp: string;
  action?: string;
  workflow?: string;
  lab?: string;
}

export default defineComponent({
  props: {
    labId: {
      type: String,
      required: true,
    },
    parentFilterText: String,
  },
  setup(props, { emit }) {
    const errorLog: Ref<HTMLElement | null> = ref(null);
    const tableBody: Ref<HTMLElement | null> = ref(null);
    const openRows: Ref<string[]> = ref([]);

    const isRowOpen = (id) => openRows.value.some((r) => r === id);
    const toggleRow = (id) => {
      const index = openRows.value.indexOf(id);
      if (index > -1) {
        openRows.value.splice(index, 1);
        openRows.value = [...openRows.value];
      } else {
        openRows.value = [...openRows.value, id];
      }
    };

    const filterText = ref(props.parentFilterText || '');

    const errors = computed(() => {
      const labErrors: PublicError[] = OpsController.Instance.getErrorsByLab(
        props.labId
      );
      const mapped: Error[] = labErrors.map((e) => {
        const job = OpsController.Instance.getJob(e.jobId || '');
        const workflow = ActionController.Instance.getAction(
          e.workflowId || ''
        );
        return {
          ...e,
          action: job.action?.name || e.actionId,
          job: job,
          lab: OpsController.Instance.getLab(e.labId)?.name || e.actionId,
          type: e.severity || 'WARNING',
          timestamp: moment(e.timestamp).format('D MMM YYYY k:mm:ss'),
          workflow: workflow?.name || job?.action?.name || e.workflowId,
        };
      });

      return mapped.filter((e) => {
        if (filterText.value) {
          const textString = `${e.job?.name}${e.job.action?.name}${e.errorText}`;
          return textString
            .toLowerCase()
            .includes(filterText.value.toLowerCase());
        }
        return true;
      });
    });

    const errorSummary = (error: PublicError): string => {
      let summary = error.title || error.errorText;
      if (error.sourceFile) {
        summary = `${error.sourceFile}${
          Number.isInteger(error.lineNumber) ? ':' + error.lineNumber : ''
        } - ${summary}`;
      }
      return summary;
    };

    const close = () => emit('close');

    // GRAB HANDLE LOGIC
    const mouseTracker = (e) => {
      const diff = window.innerHeight - e.pageY;
      if (errorLog.value) {
        errorLog.value.style.height = `${diff}px`;
      }

      if (tableBody.value) {
        const tableHeight =
          window.innerHeight - tableBody.value.getBoundingClientRect().top;
        tableBody.value.style.height = `${tableHeight}px`;
      }
    };

    const handleMouseDown = (e) => {
      e.preventDefault();
      // In order to track mouse events over an iframe in this document
      // we have to turn off pointer events for the iframe. We then have
      // to turn pointer events back on in the mouse up handler.
      const viz = document.getElementById('vizualizer');
      if (viz) {
        viz.style.pointerEvents = 'none';
      }
      window.addEventListener('mousemove', mouseTracker);
      window.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseUp = () => {
      const viz = document.getElementById('vizualizer');
      if (viz) {
        viz.style.pointerEvents = 'auto';
      }

      window.removeEventListener('mousemove', mouseTracker);
    };

    watchEffect(() => {
      if (props.parentFilterText) {
        filterText.value = props.parentFilterText;
      }
    });

    return {
      errorLog,
      tableBody,
      filterText,
      errors,
      errorSummary,
      openRows,
      isRowOpen,
      toggleRow,
      close,
      handleMouseDown,
      handleMouseUp,
    };
  },
});
