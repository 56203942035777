
import { defineComponent, computed, ref } from 'vue';
import ActionController from '@/clients/action/controller';
import LeftDrawerLayout from '@/pages/Ops/components/RequestCreation/LeftDrawerLayout.vue';
import WorkflowList from '@/pages/Ops/components/RequestCreation/WorkflowList.vue';
import SearchBar from '@/components/SearchBar.vue';
import { hostname } from '@/clients/utils';

export default defineComponent({
  components: { LeftDrawerLayout, WorkflowList, SearchBar },
  props: {
    labId: String,
  },
  setup(props, { emit }) {
    const goBack = () => {
      emit('goBack');
    };

    const searchBarText = ref('');
    const searchUpdate = (searchText) => {
      searchBarText.value = searchText;
    };

    const assistants = computed(() =>
      ActionController.Instance.actions.filter(
        (a) =>
          (!a.constraint?.labId || a.constraint?.labId === props.labId) &&
          a.assistant
      )
    );

    const selectAction = (id) => {
      const assistantPlayerUrl = `${hostname(
        process.env.VUE_APP_VISUALIZER_URL
      )}/#/?labId=${props.labId}&assistant=${id}&preview=true`;
      window.open(assistantPlayerUrl, '_blank');
    };

    const filteredAssistants = computed(() => {
      if (searchBarText.value) {
        return assistants.value.filter((assistant) =>
          assistant.name
            .toLowerCase()
            .includes(searchBarText.value.toLowerCase())
        );
      }
      return assistants.value;
    });

    const close = () => {
      emit('close');
    };

    return {
      goBack,
      searchUpdate,
      assistants,
      filteredAssistants,
      selectAction,
      close,
    };
  },
});
