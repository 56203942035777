import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/circle-check-blue.svg'
import _imports_1 from '@/assets/icons/circle-check-red.svg'
import _imports_2 from '@/assets/icons/circle-check.svg'


const _withScopeId = n => (_pushScopeId("data-v-ce69eb86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title-row" }
const _hoisted_2 = {
  key: 1,
  src: _imports_0
}
const _hoisted_3 = {
  key: 2,
  src: _imports_1
}
const _hoisted_4 = {
  key: 3,
  src: _imports_1
}
const _hoisted_5 = {
  key: 4,
  src: _imports_2
}
const _hoisted_6 = { class: "name" }
const _hoisted_7 = {
  key: 0,
  class: "name-error"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 2,
  class: "substrate-name"
}
const _hoisted_10 = { class: "date" }
const _hoisted_11 = {
  key: 0,
  class: "finished"
}
const _hoisted_12 = {
  key: 1,
  class: "started"
}
const _hoisted_13 = {
  key: 0,
  class: "children"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_running_dots = _resolveComponent("running-dots")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_action_item = _resolveComponent("action-item", true)!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['action', _ctx.state])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (
          _ctx.state === _ctx.SimplifiedState.RUNNING ||
          _ctx.state === _ctx.SimplifiedState.RUNNING_NEED_ASSISTANCE ||
          _ctx.state === _ctx.SimplifiedState.RUNNING_WITH_ASSISTANCE
        )
        ? (_openBlock(), _createBlock(_component_running_dots, { key: 0 }))
        : (_ctx.state === _ctx.SimplifiedState.FINISHED || _ctx.finished)
          ? (_openBlock(), _createElementBlock("img", _hoisted_2))
          : (_ctx.state === _ctx.SimplifiedState.CANCELLED)
            ? (_openBlock(), _createElementBlock("img", _hoisted_3))
            : (_ctx.state === _ctx.SimplifiedState.ERROR)
              ? (_openBlock(), _createElementBlock("img", _hoisted_4))
              : (_openBlock(), _createElementBlock("img", _hoisted_5)),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", {
          class: _normalizeClass(['step-count', _ctx.state])
        }, _toDisplayString(`${_ctx.formattedStep}`), 3),
        _createVNode(_component_el_tooltip, {
          content: "Copy Action ID",
          placement: "top-end",
          "open-delay": 1000
        }, {
          default: _withCtx(() => [
            (_ctx.isExpert)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  class: "copy-id",
                  icon: "DocumentCopy",
                  onClick: _ctx.copyActionId
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_ctx.action.state === _ctx.SimplifiedState.ERROR)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(`[ERROR] ${_ctx.action.name}`), 1))
          : _createCommentVNode("", true),
        (_ctx.action.actionType === _ctx.ActionType.ASSISTANT)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(` Assistant: ${_ctx.action.name}`), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(` ${_ctx.action.name}`), 1))
      ]),
      (_ctx.action.children && _ctx.action.children.length > 0 && !_ctx.open)
        ? (_openBlock(), _createElementBlock("i", {
            key: 5,
            class: "el-icon-caret-right",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleOpen && _ctx.toggleOpen(...args)))
          }))
        : (_ctx.action.children && _ctx.action.children.length > 0)
          ? (_openBlock(), _createElementBlock("i", {
              key: 6,
              class: "el-icon-caret-bottom",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleOpen && _ctx.toggleOpen(...args)))
            }))
          : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      (_ctx.state === _ctx.SimplifiedState.FINISHED)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(`Completed ${_ctx.completedDate}`), 1))
        : (_ctx.createdBy)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(`By ${_ctx.createdBy} ${_ctx.startedOnDate}`), 1))
          : _createCommentVNode("", true)
    ]),
    (_ctx.action.children && _ctx.action.children.length > 0 && _ctx.open)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.action.children, (child, index) => {
            return (_openBlock(), _createBlock(_component_action_item, {
              key: child.id,
              action: child,
              parent: _ctx.action,
              step: `${_ctx.step}.${index + 1}`,
              finished: _ctx.action.state === _ctx.SimplifiedState.FINISHED
            }, null, 8, ["action", "parent", "step", "finished"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}