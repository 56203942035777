
import { defineComponent, computed, ref, onMounted } from 'vue';
import OpsController from '@/clients/ops';
import ActionController from '@/clients/action';
import { setupTitle } from '@/pages/utils';
import { hostname } from '@/clients/utils';
import { useRoute } from 'vue-router';

interface Props {
  labId: string;
  assistantId?: string;
  workflow?: string;
  edit?: boolean;
}

const VIZ_LAB_QUERY = '/#/?labId=';
const VIZ_ASSISTANT_QUERY = '&assistant=';
const VIZ_ASSISTANT_EDITOR = '&assistantEditor=';
const VIZ_WORKFLOW_EDITOR = '&workflow=';

const buildVizQueryParams = (props: Props, edit: boolean): string => {
  let params = `${VIZ_LAB_QUERY}${props.labId}`;
  if (props.assistantId) {
    if (edit) {
      params = params.concat(`${VIZ_ASSISTANT_EDITOR}${props.assistantId}`);
    } else {
      params = params.concat(`${VIZ_ASSISTANT_QUERY}${props.assistantId}`);
    }
  } else if (props.workflow) {
    params = params.concat(`${VIZ_WORKFLOW_EDITOR}${props.workflow}`);
  }
  return params;
};

export default defineComponent({
  props: {
    labId: {
      type: String,
      required: true,
    },
    assistantId: {
      type: String,
      required: false,
    },
    workflow: {
      type: String,
      required: false,
    },
    edit: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props) {
    const vizSrc = ref('');
    const route = useRoute();
    const lab = computed(() => OpsController.Instance.getLab(props.labId));
    const hasAsstId = !!route.query.assistantId;

    onMounted(async () => {
      if (props.assistantId) {
        const edit =
          await ActionController.Instance.dispatchGetAssistantIsEditable(
            props.assistantId
          );
        vizSrc.value = hostname(
          `${process.env.VUE_APP_VISUALIZER_URL}${buildVizQueryParams(
            props,
            edit
          )}`
        );
      } else {
        vizSrc.value = hostname(
          `${process.env.VUE_APP_VISUALIZER_URL}${buildVizQueryParams(
            props,
            false
          )}`
        );
      }
    });

    return {
      vizSrc,
      hasAsstId,
      cacheBuster: computed(() => Date.now().toString()),
      ...setupTitle(lab),
    };
  },
});
