import { Router } from 'vue-router';
import { reactive } from 'vue';
import { User, nullUser } from '@/clients/users';
import * as Service from './service';
import axios from 'axios';
import { AUTH_URL, COGNITO_CLIENT_ID } from '../utils';
import {
  CALCULATE_ROUTE_ON_LOGIN,
  ENTRY_SIGN_OUT_ROUTE,
  HOME_ROUTE,
} from '@/router/names';

interface State {
  known: boolean;
  loggedIn: boolean;
  user: User;
}

const AUTH_REFRESH_MIN = 30;

export default class Controller {
  private static instance: Controller;
  private state: State;
  private refreshInterval: NodeJS.Timeout | null = null;
  private _router: Router | null = null;
  private constructor() {
    this.state = reactive({
      known: false,
      loggedIn: false,
      user: nullUser(),
    });
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  triggerRouteChange(goHome = false) {
    if (this._router) {
      this._router
        .replace({ name: goHome ? HOME_ROUTE : CALCULATE_ROUTE_ON_LOGIN })
        .catch(() => {
          // Intentionally routing to 'CALCULATE_ROUTE_ON_LOGIN' which would determine where to send the user
          // depending if the user is logged in or not.  Vue-router treats any navigation guard
          // redirects as an error.  This catch will silence that unnecessary error.  More info:
          // https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
        });
    }
  }

  // ACTIONS/MUTATIONS
  async dispatchSetUser() {
    try {
      this.state.user = await Service.userInfo();
      this.state.loggedIn = true;
      this.refreshInterval = setInterval(async () => {
        await axios.get(`${AUTH_URL}/refresh`, {
          params: COGNITO_CLIENT_ID ? { client_id: COGNITO_CLIENT_ID } : {},
          withCredentials: true,
        });
      }, AUTH_REFRESH_MIN * 60 * 1000);
      return true;
    } catch {
      this.state.loggedIn = false;
    } finally {
      this.state.known = true;
      // TODO: REMOVE TRIGGER ROUTE CHANGE
      // this.triggerRouteChange();
    }

    // return false;
  }

  async dispatchLogout() {
    // delete our error log history upon explicit logout
    window.localStorage.removeItem('gqlErrorPayload');
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }

    if (this._router) {
      this._router.push({ name: ENTRY_SIGN_OUT_ROUTE });
    }

    this.state.known = true;
    this.state.loggedIn = false;
    this.state.user = nullUser();
  }

  // GETTERS
  get known() {
    return this.state.known;
  }

  get loggedIn() {
    return this.state.loggedIn;
  }

  get user() {
    return this.state.user;
  }

  // SETTERS
  set router(router: Router) {
    this._router = router;
  }
}
