import { RouteRecord } from 'vue-router';
import { reactive } from 'vue';
import { cloneDeep } from 'lodash';
import { ElNotification } from 'element-plus';
import { TinyEmitter } from 'tiny-emitter';
import { LOADING_ROUTE, ENTRY_SIGN_IN_ROUTE, HOME_ROUTE } from '@/router/names';
import { DashboardFeatureFlag } from '../model';
import UserController from '@/clients/users';

interface StateInterface {
  globalSearchPattern: string;
  loadingWorld: boolean;
  expertMode: boolean;
  breadcrumbStack: RouteRecord[];
  waitingForCommandCompletion: boolean;
  sortPendingQueueBy: string[];
  sortScheduledQueueBy: string[];
  sortRunningQueueBy: string[];
  longCallsInProgress: string[];
  gqlInFlight: string[];
  displayErrorLog: boolean;
  displayGanttChart: boolean;
  displayRightDrawer: boolean;
  displayLeftDrawer: boolean;
  displayCenterDrawer: boolean;
  displayBottomDrawer: boolean;
  featureFlags: DashboardFeatureFlag[] | undefined;
}

export interface BusMessagePayload {
  merge: (
    payload: BusMessagePayload,
    buffered: BusMessagePayload
  ) => BusMessagePayload;
}

export interface BusMessage<T extends BusMessagePayload = BusMessagePayload> {
  name: string;
  payload: T;
}

export default class Controller {
  private static instance: Controller;
  private state: StateInterface;
  private bus: TinyEmitter;
  private busBuffer: BusMessage[];
  private bufferFlushInterval: NodeJS.Timeout | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private notifications: Dictionary<any> = {};
  private BUS_BUFFER_FLUSH_INTERVAL = 2000;

  private constructor() {
    this.bus = new TinyEmitter(); // app wide message bus
    this.busBuffer = [];
    this.bufferFlushInterval = null;
    /*
     * STATE
     */
    this.state = reactive({
      globalSearchPattern: '',
      loadingWorld: false,
      expertMode: localStorage.getItem('expert') === 'true',
      breadcrumbStack: [],
      waitingForCommandCompletion: false,
      sortPendingQueueBy: [],
      sortScheduledQueueBy: [],
      sortRunningQueueBy: [],
      longCallsInProgress: [],
      gqlInFlight: [],
      displayErrorLog: false,
      displayGanttChart: true,
      displayRightDrawer: false,
      displayLeftDrawer: false,
      displayCenterDrawer: false,
      displayBottomDrawer: false,
      featureFlags: undefined,
    });
    this.startEventLoop();
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  /**
   * This will execute every timeout ms and dispatch any buffered messages
   * @param timeout the time between buffer flushes, defaults to BUS_BUFFER_FLUSH_INTERVAL
   */
  private startEventLoop(timeout = this.BUS_BUFFER_FLUSH_INTERVAL) {
    this.bufferFlushInterval = setInterval(() => {
      if (this.busBuffer.length) {
        for (let i = 0; i < this.busBuffer.length; i += 1) {
          const message = cloneDeep(this.busBuffer[i]);
          this.bus?.emit(message.name, message.payload);
        }
        this.busBuffer.splice(0, this.busBuffer.length);
      }
    }, timeout);
  }

  /**
   * Restart our buffer flush loop with a different timeout. Used for unit testing
   * @param timeout the new timeout in ms
   */
  public restartEventLoop(timeout = this.BUS_BUFFER_FLUSH_INTERVAL) {
    if (this.bufferFlushInterval) {
      clearInterval(this.bufferFlushInterval);
    }
    this.startEventLoop(timeout);
  }

  public notifyError(title: string, msg: string, canClose?: boolean) {
    if (!this.notifications[title + msg]) {
      console.warn(`displaying global error: ${title + msg}`);
      // @ts-ignore
      this.notifications[title + msg] = ElNotification.error({
        title,
        message: msg,
        showClose: canClose,
        duration: 0,
      });
    }
  }

  public clearError(title: string, msg: string) {
    if (this.notifications[title + msg]) {
      console.log(`clearing global error: ${title + msg}`);
      this.notifications[title + msg].close();
      delete this.notifications[title + msg];
    }
  }

  // ACTIONS/MUTATIONS
  public async dispatchSetGlobalSearchPattern(pattern: string) {
    return (this.state.globalSearchPattern = pattern);
  }

  public async dispatchSetLoadingWorld(loading: boolean) {
    return (this.state.loadingWorld = loading);
  }

  public pushBreadcrumbRoute(route) {
    if (route.name === HOME_ROUTE) {
      this.state.breadcrumbStack = [];
    } else if (
      route.name !== LOADING_ROUTE &&
      route.name !== ENTRY_SIGN_IN_ROUTE
    ) {
      const routeIdx = this.state.breadcrumbStack.findIndex(
        (bc) => bc.path === route.path
      );
      if (routeIdx >= 0) {
        this.state.breadcrumbStack = this.state.breadcrumbStack.slice(
          0,
          routeIdx
        );
      }
      this.state.breadcrumbStack.push(route);
    }
  }

  public popBreadcrumbRoute(): RouteRecord | undefined {
    return this.state.breadcrumbStack.pop();
  }

  public addLongNetworkCall(name: string) {
    if (!this.state.longCallsInProgress.find((c) => c === name)) {
      this.state.longCallsInProgress.push(name);
    }
  }

  public finishLongNetworkCall(name: string) {
    const idx = this.state.longCallsInProgress.findIndex((c) => c === name);
    if (idx >= 0) {
      this.state.longCallsInProgress.splice(idx, 1);
    }
  }

  public clearAllNetworkCalls() {
    this.state.longCallsInProgress.splice(
      0,
      this.state.longCallsInProgress.length
    );
  }

  public addGqlInFlight(name: string) {
    this.state.gqlInFlight = [...this.state.gqlInFlight, name];
  }

  public removeGqlInFlight(name: string) {
    const index = this.state.gqlInFlight.indexOf(name);
    if (index > -1) {
      this.state.gqlInFlight.splice(index, 1);
    }
  }

  public isGqlInFlight(name: string) {
    return this.state.gqlInFlight.some((request) => request === name);
  }

  /**
   * Send a message over the global message bus.
   * Note: this will not send the message immediately, but rather buffer it up
   * to send every BUS_BUFFER_FLUSH_INTERVAL ms. This avoids flooding listeners
   * with messages that may come in several times/second.
   * @param message the message to send
   */
  public sendMessage<T extends BusMessagePayload>(message: BusMessage<T>) {
    const bufferedIdx = this.busBuffer.findIndex(
      (m) => m.name === message.name
    );
    if (bufferedIdx >= 0) {
      this.busBuffer.splice(bufferedIdx, 1, {
        name: message.name,
        payload: message.payload.merge(
          message.payload,
          this.busBuffer[bufferedIdx].payload
        ),
      });
    } else {
      this.busBuffer.push(message);
    }
  }

  public sendMessageImmediate<T extends BusMessagePayload>(
    message: BusMessage<T>
  ) {
    this.messageBus?.emit(message.name, message.payload);
  }

  // GETTERS
  get messageBus(): TinyEmitter {
    return this.bus;
  }

  get breadcrumbStack(): RouteRecord[] {
    return this.state.breadcrumbStack;
  }

  get globalSearchPattern(): string {
    return this.state.globalSearchPattern;
  }

  get loadingWorld(): boolean {
    return this.state.loadingWorld;
  }

  get expert(): boolean {
    return (
      this.state.expertMode || UserController.Instance.isActingAsArtificialUser
    );
  }

  set expert(isExpert: boolean) {
    this.state.expertMode = isExpert;
    localStorage.setItem('expert', isExpert ? 'true' : '');
  }

  get longNetworkCallInProgress(): boolean {
    return this.state.longCallsInProgress.length > 0;
  }

  get waitingForCommandCompletion(): boolean {
    return this.state.waitingForCommandCompletion;
  }

  set waitingForCommandCompletion(val: boolean) {
    this.state.waitingForCommandCompletion = val;
  }

  get sortPendingQueueBy(): string[] {
    return this.state.sortPendingQueueBy;
  }

  set sortPendingQueueBy(value: string[]) {
    this.state.sortPendingQueueBy = value;
  }

  get sortScheduledQueueBy(): string[] {
    return this.state.sortScheduledQueueBy;
  }

  set sortScheduledQueueBy(value: string[]) {
    this.state.sortScheduledQueueBy = value;
  }

  get sortRunningQueueBy(): string[] {
    return this.state.sortRunningQueueBy;
  }

  set sortRunningQueueBy(value: string[]) {
    this.state.sortRunningQueueBy = value;
  }

  get gqlInFlight() {
    return this.state.gqlInFlight;
  }

  set displayErrorLog(value: boolean) {
    this.state.displayErrorLog = value;
  }

  get displayErrorLog() {
    return this.state.displayErrorLog;
  }

  set displayGanttChart(value: boolean) {
    this.state.displayGanttChart = value;
  }

  get displayGanttChart() {
    return this.state.displayGanttChart;
  }

  set displayRightDrawer(value: boolean) {
    this.state.displayRightDrawer = value;
  }

  get displayRightDrawer() {
    return this.state.displayRightDrawer;
  }

  set displayLeftDrawer(value: boolean) {
    this.state.displayLeftDrawer = value;
  }

  get displayLeftDrawer() {
    return this.state.displayLeftDrawer;
  }

  set displayCenterDrawer(value: boolean) {
    this.state.displayCenterDrawer = value;
  }

  get displayCenterDrawer() {
    return this.state.displayCenterDrawer;
  }

  set displayBottomDrawer(value: boolean) {
    this.state.displayBottomDrawer = value;
  }

  get displayBottomDrawer() {
    return this.state.displayBottomDrawer;
  }

  /**
   * List of feature flags that have been set for this instance
   */
  public get featureFlags(): DashboardFeatureFlag[] {
    if (this.state.featureFlags === undefined) {
      throw new Error(
        'Attempted to access DashboardFeatureFlag before they were set.'
      );
    }
    return this.state.featureFlags;
  }

  public set featureFlags(val: DashboardFeatureFlag[]) {
    this.state.featureFlags = val;
  }

  /**
   * Whether a particular feature flag has been set
   */
  public hasFeatureFlag(flag: DashboardFeatureFlag): boolean {
    return this.featureFlags.includes(flag);
  }
}
