import { JSONSchema7 } from 'json-schema';

export enum WidgetType {
  RADIO = 'radio',
  SWITCH = 'switch',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  PASSWORD = 'password',
  COLOR = 'color',
  NUMERIC = 'numeric',
  FILE = 'file',
  JSON_FILE = 'jsonFile',
  TABLE = 'table',
  ARRAY = 'array',
  DATE = 'date',
  DATE_TIME = 'date-time',
  SECTION = 'section',
  GROUP = 'group',
  NONE = 'none',
}

export interface UiEnumSpec {
  id: string;
  name: string;
  disabled: boolean;
  description: string;
}

export interface UiOptions {
  widget?: WidgetType;
  title?: string;
  width?: number;
  description?: string;
  formatting?: string;
  enum?: UiEnumSpec[];
  alwaysDisplay?: boolean;
  'hooks:preCreate'?: boolean;
  'hooks:postCreate'?: boolean;
  'hooks:preBatch'?: boolean;
  'hooks:postBatch'?: boolean;
  'hooks:preSchedule'?: boolean;
  'hooks:postSchedule'?: boolean;
}

export interface RequestJSONSchema extends JSONSchema7 {
  'ui:options'?: UiOptions;
  'ui:title'?: string;
  'ui:widget'?: WidgetType;
  'ui:width'?: number;
  'ui:formatting'?: string;
  'ui:enum'?: UiEnumSpec[];
  'ui:hooks:preCreate'?: boolean;
  'ui:hooks:postCreate'?: boolean;
  'ui:hooks:preBatch'?: boolean;
  'ui:hooks:postBatch'?: boolean;
  'ui:hooks:preSchedule'?: boolean;
  'ui:hooks:postSchedule'?: boolean;
  input?: boolean;
}

export interface SimpleResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export enum InteractivityHookStage {
  INTERACTIVITY_HOOK_UNKNOWN = 'INTERACTIVITY_HOOK_UNKNOWN',
  INTERACTIVITY_HOOK_PRE_CREATE = 'INTERACTIVITY_HOOK_PRE_CREATE',
  INTERACTIVITY_HOOK_POST_CREATE = 'INTERACTIVITY_HOOK_POST_CREATE',
  INTERACTIVITY_HOOK_PRE_BATCH = 'INTERACTIVITY_HOOK_PRE_BATCH',
  INTERACTIVITY_HOOK_POST_BATCH = 'INTERACTIVITY_HOOK_POST_BATCH',
  INTERACTIVITY_HOOK_PRE_SCHEDULE = 'INTERACTIVITY_HOOK_PRE_SCHEDULE',
  INTERACTIVITY_HOOK_POST_SCHEDULE = 'INTERACTIVITY_HOOK_POST_SCHEDULE',
}
