import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-972329ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "data-records" }
const _hoisted_2 = { class: "banner" }
const _hoisted_3 = { class: "record-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner = _resolveComponent("banner")!
  const _component_summary_content = _resolveComponent("summary-content")!
  const _component_resources_content = _resolveComponent("resources-content")!
  const _component_results_content = _resolveComponent("results-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_banner, {
        job: _ctx.job,
        onUpdateSelectedDataRecordTab: _ctx.updateSelectedDataRecordTab
      }, null, 8, ["job", "onUpdateSelectedDataRecordTab"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.selectedDataRecordTab === 'SUMMARY')
        ? (_openBlock(), _createBlock(_component_summary_content, {
            key: 0,
            job: _ctx.job
          }, null, 8, ["job"]))
        : (_ctx.selectedDataRecordTab === 'RESOURCES')
          ? (_openBlock(), _createBlock(_component_resources_content, {
              key: 1,
              job: _ctx.job
            }, null, 8, ["job"]))
          : (_openBlock(), _createBlock(_component_results_content, {
              key: 2,
              job: _ctx.job
            }, null, 8, ["job"]))
    ])
  ]))
}