import OpsController from '../ops';
import ActionController from '../action';
import * as Service from './service';

export default class Controller {
  private static instance: Controller;

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  /*
   * Workflows/Mutations
   */

  /**
   * Reset all our internal state
   */
  async dispatchReset() {
    await Service.reset();
    OpsController.Instance.reset();
    ActionController.Instance.reset();
  }

  /**
   * Import workflows from a file
   * MUTATAION
   * @param actions the file containing workflow definitions in JSON format
   */
  async dispatchImportWorkflows(actions: File) {
    await Service.importWorkflows(actions);
    // TODO:  Get new actions (not all actions)
    await ActionController.Instance.dispatchGetWorkflows();
  }

  /**
   * Export a set of workflows
   * @param id TODO: what the heck is this? labId?
   * @returns
   */
  dispatchExportWorkflows(id?: string) {
    return Service.exportWorkflows(id);
  }

  /**
   * Import a lab from a file
   * MUTATION
   * @param lab the file containing the lab defintion
   */
  async dispatchImportLab(lab: File) {
    await Service.importLab(lab);
    // TODO:  Get single lab (not all labs)
    await OpsController.Instance.dispatchGetLabs();
  }

  /**
   * Export a lab to a file
   * @param id the ID of the lab
   * @returns
   */
  dispatchExportLab(id: string) {
    return Service.exportLab(id);
  }
}
