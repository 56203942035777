
import { defineComponent, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import ActionController from '@/clients/action';
import UIController from '@/clients/ui';
import LabOpsController, { RequestStates } from '../planningLocalController';

export default defineComponent({
  setup() {
    const route = useRoute();

    const labId = computed(() =>
      Array.isArray(route.params.labId)
        ? route.params.labId[0]
        : route.params.labId
    );
    const isOpen = ref(false);
    const createMenu = ref();
    const handleStateChange = (menuState: boolean) =>
      (isOpen.value = menuState);
    const openMenu = () => createMenu.value.handleOpen();

    const handleCommand = (command: RequestStates) => {
      console.log(`handle command: ${command}`);
      UIController.Instance.displayLeftDrawer = true;
      LabOpsController.Instance.requestState = command;
    };

    const anyFromFileWorkflows = computed(
      () =>
        ActionController.Instance.getCreationConstrainedWorkflows(
          'fromFile',
          labId.value
        ).length > 0
    );

    const anyInteractiveWorkflows = computed(
      () =>
        ActionController.Instance.getCreationConstrainedWorkflows(
          'interactive',
          labId.value
        ).length > 0
    );

    const anyQuickWorkflows = computed(
      () =>
        ActionController.Instance.getCreationConstrainedWorkflows(
          'quick',
          labId.value
        ).length > 0
    );
    return {
      isOpen,
      openMenu,
      createMenu,
      handleStateChange,
      handleCommand,
      anyFromFileWorkflows,
      anyInteractiveWorkflows,
      anyQuickWorkflows,
      RequestStates,
    };
  },
});
