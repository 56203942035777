
import { defineComponent, ref, Ref } from 'vue';

export default defineComponent({
  props: {
    fileType: String,
  },
  setup(_, { emit }) {
    const fileInput: Ref<HTMLElement | null> = ref(null);
    const fileName = ref('');

    const isOverDropZone = ref(false);

    const handleDragChange = (e, status) => {
      e.stopPropagation();
      e.preventDefault();
      isOverDropZone.value = status;
    };

    const handleClick = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    const clearFile = () => {
      emit('update', null);
      fileName.value = '';
    };

    const handleFile = (e, clicked) => {
      handleDragChange(e, false);
      const file = clicked ? e.target.files[0] : e.dataTransfer.files[0];

      if (file) {
        fileName.value = file.name;
      }
      emit('update', file);
    };
    return {
      fileInput,
      fileName,
      isOverDropZone,
      handleDragChange,
      handleClick,
      clearFile,
      handleFile,
    };
  },
});
