import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15a5917c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "data-records__summary" }
const _hoisted_2 = { class: "category-filters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_category_selector = _resolveComponent("category-selector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_category_selector, {
        categories: _ctx.categories,
        fontInter: true,
        onSetCategory: _ctx.updateSelectedCategory
      }, null, 8, ["categories", "onSetCategory"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['table-wrapper', _ctx.selectedCategory.name])
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.selectedCategory.component), { job: _ctx.job }, null, 8, ["job"]))
    ], 2)
  ]))
}