
import { defineComponent, ref } from 'vue';
import CategorySelector from '@/components/CategorySelector.vue';
import InputsOutputsTable from './InputsOutputsTable.vue';
import ListIcon from '@/assets/icons/list.svg';

export default defineComponent({
  components: {
    CategorySelector,
    InputsOutputsTable,
  },
  props: {
    job: Object,
  },
  setup() {
    const categories = [
      {
        name: 'inputs',
        display: 'Inputs',
        image: ListIcon,
        component: InputsOutputsTable,
      },
      {
        name: 'outputs',
        display: 'Outputs',
        image: ListIcon,
        component: InputsOutputsTable,
      },
    ];
    const selectedCategory = ref(categories[0]);
    const updateSelectedCategory = (category) =>
      (selectedCategory.value = category);

    return {
      categories,
      selectedCategory,
      updateSelectedCategory,
    };
  },
});
