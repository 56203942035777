import { reactive } from 'vue';
import ActionController, { Action } from '../action';
import { EventTemplate } from './model';
import * as Apollo from './service.apollo';

interface StateInterface {
  cronJobs: Record<string, EventTemplate>;
}

export default class Controller {
  private static instance: Controller;
  private state: StateInterface;

  private constructor() {
    /*
     * STATE
     */
    this.state = reactive({
      cronJobs: {},
    });
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  public async dispatchGetCronJobs(labId: string): Promise<EventTemplate[]> {
    const cronJobs = await Apollo.getCronJobs(labId);
    cronJobs.forEach((j) => {
      this.state.cronJobs[j.id] = j;
    });
    return this.getCronJobs(labId);
  }

  public async dispatchCreateCronJob(
    cronJob: EventTemplate
  ): Promise<EventTemplate | undefined> {
    const newJob = await Apollo.createCronJob(cronJob);
    this.state.cronJobs[newJob.id] = newJob;
    return this.getCronJob(newJob.id);
  }

  public async dispatchDeleteCronJob(cronId: string) {
    const success = await Apollo.deleteCronJob(cronId);
    if (success) {
      delete this.state.cronJobs[cronId];
    }
  }

  public async dispatchUpdateCronJob(
    cronJob: EventTemplate
  ): Promise<EventTemplate | undefined> {
    let existingCron = this.getCronJob(cronJob.id);
    if (existingCron) {
      const updatedCron = await Apollo.updateCronJob(cronJob);
      existingCron = {
        ...updatedCron,
      };
    }
    return cronJob;
  }

  get possibleRecurringActions(): Action[] {
    return ActionController.Instance.recurringActions;
  }

  getCronJobs(labId: string): EventTemplate[] {
    return Object.values(this.state.cronJobs).filter((j) => j.labId === labId);
  }

  getCronJob(id: string): EventTemplate | undefined {
    return this.state.cronJobs[id];
  }
}
