import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e0ba91a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-layout" }
const _hoisted_2 = { class: "table-wrapper" }
const _hoisted_3 = { class: "custom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "table", {}, () => [
        _createTextVNode("Insert table")
      ], true)
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "#navbar" }, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "navbar", {}, undefined, true)
      ])
    ]))
  ]))
}