<template>
  <div class="close-window">
    You have logged into Artificial!
    <div class="close-window__message">You may now close this window.</div>
  </div>
</template>
<style lang="scss" scoped>
.close-window {
  font-size: 18px;
  padding: 20px;
  font-weight: bold;
  &__message {
    margin-top: 10px;
    font-size: 16px;
    font-weight: normal;
  }
}
</style>
