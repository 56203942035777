import { isArray, isEmpty, merge } from 'lodash';
import { reactive } from 'vue';
import {
  Job,
  nullJob,
  Lab,
  File,
  nullLab,
  CommandState,
  JobState,
  JobValidationResponse,
  PublicError,
  PublicErrorSeverity,
  SchedulingType,
  JobFilters,
  JobTableData,
  Note,
  LastFailedActionInfo,
  JobEvent,
  JobEventResponse,
  JobEventType,
  JobEventTypeFilter,
  GanttJob,
  HeartbeatSubscription,
  ScheduleResponse,
} from './model';
import { nullCommon } from '@/clients/model';
import { SimplifiedState } from '../model';
import { Action } from '../action';
import * as Apollo from './service.apollo';
import UIController from '../ui';
import NotificationsController from '../notifications';
import {
  ActionType,
  TimelineActionTypeFilters,
  nullAction,
} from '../action/model';
import {
  InteractivityHookStage,
  RequestJSONSchema,
  SimpleResponse,
} from '@/components/GenericForm/types';
import {
  filterSchemaToDirection,
  parseAndFilterSchemaToHook,
} from '@/components/GenericForm/utils';
import {
  JobSetChangeMessagePayload,
  JobSetChangeType,
  JobStateChangeMessagePayload,
  JOB_SET_CHANGE,
  JOB_STATE_CHANGE,
} from './messages';
import { AVAILABLE_REQUESTS_QUERY, JOB_NAMES_QUERY } from './queries';
import { convertActionParameterIntegerValues } from '../parameterUtils';
import AdapterController from '../adapter';

enum EntityType {
  Lab = 'labs',
  Job = 'jobs',
  Action = 'actions',
}
interface LabMap {
  [key: string]: Lab;
}

interface JobMap {
  [key: string]: Job;
}

interface ActionMap {
  [key: string]: Action;
}
interface NormalizedEntities {
  [EntityType.Lab]: LabMap;
  [EntityType.Job]: JobMap;
  [EntityType.Action]: ActionMap;
}

interface ErrorMap {
  [key: string]: PublicError[];
}

interface ProgramGraphInfo {
  numEdges: number;
  numNodes: number;
  hasError: boolean;
  errorMessage: string;
  returnPlot: string;
}

interface ProgramGraphMap {
  [key: string]: ProgramGraphInfo;
}

interface StateInterface {
  selectedLabId: string;
  normalized: NormalizedEntities;
  errors: ErrorMap;
  programGraphs: ProgramGraphMap;
  currentJobsPage: Job[];
  currentJobsPageOffset: number;
  currentJobsPageTotalCount: number;
  currentJobsPageLimit: number;
  requests: Job[]; // temporary list used for available requests table in scheduling wizard
  legacySkipRetry: boolean;
}

export default class Controller {
  private static instance: Controller;
  private state: StateInterface;
  private watchedChangesUnsubscribeFn: (() => void) | null = null;
  private watchedStateUnsubscribeFn: (() => void) | null = null;
  private watchedErrorsUnsubscribeFn: (() => void) | null = null;
  private jobEventsSubscription: HeartbeatSubscription | null = null;
  private JOB_EVENTS_HEARTBEAT_INTERVAL = 10000;

  private constructor() {
    /*
     * STATE
     */
    this.state = reactive({
      selectedLabId: '',
      normalized: {
        labs: {},
        jobs: {},
        actions: {},
      },
      errors: {},
      programGraphs: {},
      currentJobsPage: [],
      currentJobsPageOffset: 0,
      currentJobsPageLimit: 0,
      currentJobsPageTotalCount: 0,
      requests: [],
      legacySkipRetry: false,
    });
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  /**
   * Helper Functions to make sure our stored state is consistent
   * and that new jobs/actions/labs coming in don't blap over the data
   * we already may have retrieved from another source. Any access to our normalized
   * data stores MUST go through one of these functions
   */
  private getEntity(
    type: EntityType,
    id: string
  ): Lab | Job | Action | undefined {
    return this.state.normalized[type][id];
  }

  private getEntities(type: EntityType): Lab[] | Job[] | Action[] {
    return Object.values(this.state.normalized[type]);
  }

  private mergeAction(existing: Action, incoming: Action): Action {
    // custom merger for actions to make sure we react on new ordered child actions
    if (incoming.children && incoming.children.length > 0) {
      existing['children'] = incoming.children;
    }
    if (incoming.schema) {
      existing['schema'] = incoming.schema;
    }
    if (incoming.parameterValues) {
      existing['parameterValues'] = incoming.parameterValues;
    }
    return existing;
  }

  private mergeLab(existing: Lab, incoming: Lab): Lab {
    if (isArray(incoming.connections)) {
      existing['connections'] = incoming.connections;
    }
    if (isArray(incoming.adapterIds)) {
      existing['adapterIds'] = incoming.adapterIds;
    }
    if (isArray(incoming.bannedConnections)) {
      existing['bannedConnections'] = incoming.bannedConnections;
    }
    return existing;
  }

  private mergeEntities(
    type: EntityType,
    existing: Lab | Job | Action,
    incoming: Lab | Job | Action
  ): Lab | Job | Action {
    const merged = merge(existing, incoming);
    if (type === EntityType.Action) {
      this.mergeAction(existing as Action, incoming as Action);
    }
    if (type === EntityType.Lab) {
      this.mergeLab(existing as Lab, incoming as Lab);
    }
    return merged;
  }

  private nullEntity(type: EntityType): Lab | Job | Action {
    switch (type) {
      case EntityType.Lab:
        return nullLab();
      case EntityType.Job:
        return nullJob();
      case EntityType.Action:
        return nullAction();
    }
  }

  private storeEntity(type: EntityType, incoming: Lab | Job | Action) {
    if (incoming.id) {
      const existing = this.getEntity(type, incoming.id);
      if (existing) {
        this.mergeEntities(type, existing, incoming);
        this.state.normalized[type][existing.id] = existing;
      } else {
        // before we initialize the object as far as Vue is concerned,
        // we need to make sure it has every field it COULD ever have.
        // Doing so, ensures that Vue adds its getter/setter and watchers
        // for each field. Without this, we have to call Vue.set on any
        // field buried in any Job or Action that might not be initiallly filled in.
        // const allFields = merge(this.nullEntity(type), incoming);
        this.state.normalized[type][incoming.id] = incoming;
      }
    }
  }

  private storeEntities(type: EntityType, incoming: Lab[] | Job[] | Action[]) {
    incoming.forEach((entity) => this.storeEntity(type, entity));
  }

  private removeEntity(type: EntityType, id: string) {
    const entity = this.getEntity(type, id);
    if (entity) {
      delete this.state.normalized[type][id];
    }
  }

  private removeEntities(type: EntityType, ids: string[]) {
    ids.forEach((id) => this.removeEntity(type, id));
  }

  public reset() {
    this.state.normalized.labs = {};
    this.state.normalized.jobs = {};
    this.state.normalized.actions = {};
  }
  /**
   * End state helper functions
   */

  // ACTIONS/MUTATIONS
  public dispatchGetWorld() {
    Apollo.startSubscribeLabState('', '', (_: boolean, labsState: Lab[]) => {
      this.storeEntities(EntityType.Lab, labsState);
    });
    this.dispatchGetDashboardData();
  }

  /*
   * LABS
   */

  /**
   * Get all labs with only the data required to display the landing page
   * @returns a list of labs with appropriate data
   */
  public async dispatchGetDashboardData(): Promise<Lab[]> {
    const labs = await Apollo.getLabsForDashboard();
    this.storeEntities(EntityType.Lab, labs);
    return labs;
  }

  /**
   * This will get labs for import/export
   * TODO: Do we still need this?
   * @returns a list of labs with data for import/export
   */
  public async dispatchGetLabs(): Promise<Lab[]> {
    const labs = await Apollo.getLabs();
    this.storeEntities(EntityType.Lab, labs);
    return labs;
  }

  /**
   * Get one lab with only its core fields
   * @param labId the id of the lab to retrieve
   * @returns the lab
   */
  public async dispatchGetLab(labId: string): Promise<Lab> {
    const lab = await Apollo.getLab(labId);
    this.storeEntity(EntityType.Lab, lab);
    return lab;
  }

  /**
   * Get all labs with data appropriate for the main settings page
   * @returns the list of labs
   */
  public async dispatchGetLabsForSettings(): Promise<Lab[]> {
    const labs = await Apollo.getLabsForSettings();
    this.storeEntities(EntityType.Lab, labs);
    return labs;
  }

  /**
   * Create a new lab
   * MUTATION
   * @param name the name of the lab
   * @param location a timezone string following the IANA 2022g specification (https://www.iana.org/time-zones), Ex: "America/New_York"
   * @param description a descriptive string for the lab
   * @param version unused
   * @param label unused
   */
  public async dispatchAddLab(
    name: string,
    location: string,
    description: string,
    version: string,
    label: string
  ): Promise<Lab> {
    const lab = await Apollo.newLab({
      id: '',
      name,
      description,
      state: SimplifiedState.UNKNOWN,
      location,
      label,
      version,
      jobs: [],
      common: nullCommon(),
      thumbnailUrl: '',
    });

    this.storeEntity(EntityType.Lab, lab);
    return lab;
  }

  /**
   * Rename a lab
   * MUTATION/IDEMPOTENT
   * @param labId the ID of the lab
   * @param name the new name
   * @returns the lab with its new name or null if the lab does not exist
   */
  public async dispatchRenameLab(
    labId: string,
    name: string
  ): Promise<Lab | null> {
    const lab = this.getLab(labId);
    if (lab.id) {
      lab.name = name;
      const newLab = await Apollo.updateLab(lab);
      this.storeEntity(EntityType.Lab, newLab);
      return newLab;
    }
    return null;
  }

  /**
   * Rename a lab
   * MUTATION/IDEMPOTENT
   * @param labId the ID of the lab
   * @param location a timezone string following the IANA 2022g specification (https://www.iana.org/time-zones), Ex: "America/New_York"
   * @returns the lab with the new location or null if the lab does not exist
   */
  public async dispatchUpdateLabTimezone(
    labId: string,
    location: string
  ): Promise<Lab | null> {
    const lab = this.getLab(labId);
    if (lab.id && location !== lab.location && lab.common) {
      lab.location = location;
      const newLab = await Apollo.updateLab(lab);
      this.storeEntity(EntityType.Lab, newLab);
      return newLab;
    }
    return null;
  }

  /**
   * Delete a lab
   * MUTATION
   * @param id the ID of the lab
   * @returns the lab ID if successful
   */
  public async dispatchDeleteLab(id: string) {
    const r = await Apollo.deleteLab(id);
    this.removeEntity(EntityType.Lab, id);
    return r;
  }

  /**
   * Get a lab with the data necessary for display in Ops
   * @param labId the ID of the lab
   * @returns the lab
   */
  public async dispatchGetOpsData(labId: string): Promise<Lab> {
    const lab = await Apollo.getLabForOps(labId);
    this.storeEntity(EntityType.Lab, lab);
    return lab;
  }

  /**
   * Get lab connection health data
   * @param labId the ID of the lab
   * @returns connections info
   */
  public async dispatchGetLabConnectionHealth(labId: string): Promise<Lab> {
    const lab = await Apollo.getLabConnectionHealth(labId);
    this.storeEntity(EntityType.Lab, lab);
    return lab;
  }

  /*
   * JOBS
   */

  /**
   * Get one page of jobs suitable for display in a paginated/virtual table
   * @param offset offset into the set to start at
   * @param limit how many to retreive
   * @param filters how to filter the results, see: JobFilters
   * @param orderBy how to sort the results as an array of strings of the form [dir, fieldName, dir, fieldName] where dir is 'asc' or 'desc'
   * @returns the results of the query. see: JobTableData
   */
  public async dispatchGetJobsByPage(
    offset: number,
    limit: number,
    filters: JobFilters,
    orderBy: string[]
  ): Promise<JobTableData> {
    const jobsResponse = await Apollo.getJobsByPage(
      offset,
      limit,
      filters,
      orderBy
    );
    this.storeEntities(EntityType.Job, jobsResponse.jobs);
    // now create a projection of the normalized jobs onto our paginated collection
    // TODO: is this necessary or can we just splice the response directly?
    const jobsProjection = jobsResponse.jobs.map((job) => this.getJob(job.id));
    this.state.currentJobsPage.splice(
      0,
      this.state.currentJobsPage.length,
      ...jobsProjection
    );
    this.state.currentJobsPageLimit = jobsResponse.limit;
    this.state.currentJobsPageOffset = jobsResponse.offset;
    this.state.currentJobsPageTotalCount = jobsResponse.totalCount;

    return jobsResponse;
  }

  /**
   * Get the specified number of jobs.  Currently fake job data for dev.
   * @param howMany how many fake jobs to return.
   * @returns the requested number of fake jobs.
   */
  public async dispatchGetRandomGanttJobs(
    howMany: number
  ): Promise<GanttJob[]> {
    const ganttJobsResponse = await Apollo.getRandomGanttJobs(howMany);

    // store it in state

    return ganttJobsResponse;
  }

  /**
   * Get the specified number of jobs.  Currently fake job data for dev.
   * @param labID the lab to query jobs on.
   * @param completedAfter the left edge time bound.
   * @param startedBefore the right edge time bound.
   * @param limit maximum number of jobs returned (max is 99)
   * @param offset how many jobs to skip before returning "limit" number of jobs.
   * @returns the requested number of fake jobs.
   */
  public async dispatchGetGanttJobs(
    labId: string,
    completedAfter: string,
    startedBefore: string,
    limit: number,
    offset: number
  ): Promise<GanttJob[]> {
    const ganttJobsResponse = await Apollo.getGanttJobs(
      labId,
      completedAfter,
      startedBefore,
      limit,
      offset
    );

    // store it in state

    return ganttJobsResponse;
  }

  // the new endpoint from Watson
  /**
   * Get the specified number of jobs.  Currently fake job data for dev.
   * @param labID the lab to query jobs on.
   * @param completedAfter the left edge time bound.
   * @param startedBefore the right edge time bound.
   * @param limit maximum number of jobs returned (max is 99)
   * @param offset how many jobs to skip before returning "limit" number of jobs.
   * @returns the requested number of fake jobs.
   */
  public async dispatchGetGanttJobsEx(
    labId: string,
    completedAfter: string,
    startedBefore: string,
    limit: number,
    offset: number
  ): Promise<GanttJob[]> {
    const ganttJobsResponse = await Apollo.getGanttJobsEx(
      labId,
      completedAfter,
      startedBefore,
      limit,
      offset
    );

    // store it in state

    return ganttJobsResponse;
  }

  /**
   * To schedule a job, we first need a list of all requests. Because we only fetch jobs
   * for the current page we're displaying, we likely won't have all the requests so that
   * the user can choose the one they want.
   * This function will query for ALL jobs, optionally filtered on workflow, in the
   * "Pending"/CREATED state so that we can populate the list of jobs for the user to choose from.
   * @param labId the lab the user is trying to schedule a job on
   * @param workflowId optionally the results can be filtered on this workflow
   * @returns the list of requests (Jobs)
   */
  public async dipatchGetRequestsForScheduleWizard(
    labId: string,
    workflowId?: string
  ): Promise<Job[]> {
    const filters: JobFilters = {
      workflowId,
      labId,
      states: [SimplifiedState.CREATED],
    };
    const response = await Apollo.getFilteredJobsNoLimit(
      filters,
      ['desc', 'createdTimestamp'],
      AVAILABLE_REQUESTS_QUERY
    );
    // a special dedicated store for requests (jobs in the "Pending"/CREATED state)
    // this will be blapped every time this function is called
    this.state.requests.splice(0, this.state.requests.length, ...response.jobs);
    // we can ignore this here because the query we just executed is guaranteed to include the top level action
    // @ts-ignore
    const allWorkflows: Action[] = response.jobs.flatMap((j) => ({
      ...j.action,
      jobId: j.id,
    }));
    this.storeEntities(EntityType.Job, response.jobs);
    this.storeEntities(EntityType.Action, allWorkflows);

    return response.jobs;
  }

  /**
   * For displays where we have to show some basic job info (name, created date)
   * but may not have fetched them yet (ex: they aren't on the current page)
   * @param jobIds the ids of the jobs to retreive
   * @returns the jobs with name, and common data
   */
  public async dispatchGetJobNames(jobIds: string[]): Promise<Job[]> {
    const jobFilters: JobFilters = {
      jobIds,
    };
    const jobsData = await Apollo.getFilteredJobsNoLimit(
      jobFilters,
      [],
      JOB_NAMES_QUERY
    );
    this.storeEntities(EntityType.Job, jobsData.jobs);
    return jobsData.jobs;
  }

  /**
   * Retrieve a job with all of its data suitable for display in a detailed view
   * Note: Expensive query
   * @param jobId the ID of the job
   * @returns the Job
   */
  public async dispatchGetJobDetailsData(
    jobId: string,
    legacy = false
  ): Promise<Job> {
    const job = await Apollo.getJobForJobDetails(jobId, legacy);
    if (job.action) {
      const actions = [job.action];
      if (job.action.children && job.action.children.length > 0) {
        actions.push(...job.action.children);
      }
      this.storeEntities(EntityType.Action, actions);
    }

    this.storeEntity(EntityType.Job, job);

    // get the basic lab data if we don't already have it
    let lab = this.getLab(job.labId);
    if (!lab.id) {
      lab = await Apollo.getLabForJobDetails(job.labId);
      this.storeEntity(EntityType.Lab, lab);
    }
    return job;
  }

  /**
   * Issue a job command (PAUSE/RESUME/CANCEL)
   * MUTATION/IDEMPOTENT?
   * @param jobId the ID of the job
   * @param command the command to issue
   * @returns the jobId if the command was successful
   * @throws an error if the command is not supported
   */
  public async dispatchJobCommand(
    jobId: string,
    command: CommandState
  ): Promise<string> {
    const job = this.getJob(jobId);
    if (job.id) {
      if (command === CommandState.CANCEL) {
        await Apollo.cancelJob(jobId);
      } else if (command === CommandState.PAUSE) {
        await Apollo.pauseJob(jobId);
      } else if (command === CommandState.RESUME) {
        await Apollo.resumeJob(jobId);
      } else {
        throw new Error(`Unsupported command: ${command}`);
      }
      this.storeEntity(EntityType.Job, job);
    }
    return jobId;
  }

  /**
   * Issue commands on an array jobs
   * MUTATION/IDEMPOTENT?
   * @param jobIds array of job id strings
   */
  public async dispatchBatchedJobCommand(
    jobIds: string[],
    command: CommandState
  ) {
    let response;
    if (command === CommandState.CANCEL) {
      response = await Apollo.batchCancelJobs(jobIds);
    } else if (command === CommandState.PAUSE) {
      response = await Apollo.batchPauseJobs(jobIds);
    } else if (command === CommandState.RESUME) {
      response = await Apollo.batchResumeJobs(jobIds);
    } else if (command === CommandState.DELETE) {
      response = await Apollo.batchDeleteJobs(jobIds);
      const jobsDeletePayload = new JobSetChangeMessagePayload(
        jobIds.map((id) => ({
          id,
          type: JobSetChangeType.DELETE,
        }))
      );
      UIController.Instance.sendMessage<JobSetChangeMessagePayload>({
        name: JOB_SET_CHANGE,
        payload: jobsDeletePayload,
      });
    } else if (command === CommandState.UNSCHEDULE) {
      response = await this.dispatchUnscheduleJobs(jobIds);
    } else {
      throw `Unsupported command: ${command}`;
    }
    return response;
  }

  /**
   * Issue a program level (lab) command
   * MUTATION/IDEMPOTENT?
   * @param labId the ID of the lab
   * @param command the command to issue
   */
  public async dispatchProgramCommand(labId: string, command: CommandState) {
    if (command === CommandState.CANCEL) {
      await Apollo.cancelProgram(labId);
    } else if (command === CommandState.PAUSE) {
      await Apollo.pauseProgram(labId);
    } else if (command === CommandState.RESUME) {
      await Apollo.resumeProgram(labId);
    }
  }

  /**
   * Delete a job
   * MUTATION/IDEMPOTENT
   * @param id the ID of the job
   * @returns the ID of the job if successful
   */
  public async dispatchDeleteJob(id: string) {
    const r = await Apollo.deleteJob(id);
    // delete any actions that might be associated with this job
    // more to free up memory than anything else. They shouldn't cause any harm
    const actionsToRemove = this.getActionsByJob(id).map((a) => a.id);
    this.removeEntities(EntityType.Action, actionsToRemove);
    this.removeEntity(EntityType.Job, id);
    UIController.Instance.sendMessage<JobSetChangeMessagePayload>({
      name: JOB_SET_CHANGE,
      payload: new JobSetChangeMessagePayload([
        {
          id,
          type: JobSetChangeType.DELETE,
        },
      ]),
    });
    return r;
  }

  /**
   * Delete all jobs for a given lab
   * MUTATION/IDEMPOTENT
   * @param labId the ID of the lab
   * @returns the ids of all the jobs that were deleted
   */
  public async dispatchDeleteAllJobs(labId: string): Promise<string[]> {
    const ids = await Apollo.deleteJobs(labId);
    // delete any actions that might be associated with those jobs
    // more to free up memory than anything else. They shouldn't cause any harm
    const actionsToRemove: string[] = ids.flatMap((jobId) =>
      this.getActionsByJob(jobId).map((a) => a.id)
    );
    this.removeEntities(EntityType.Action, actionsToRemove);
    this.removeEntities(EntityType.Job, ids);
    // now notify anyone who cares that the job set changed
    const deletedJobSet = new JobSetChangeMessagePayload(
      ids.map((jid) => ({
        id: jid,
        type: JobSetChangeType.DELETE,
      }))
    );
    UIController.Instance.sendMessage<JobSetChangeMessagePayload>({
      name: JOB_SET_CHANGE,
      payload: deletedJobSet,
    });

    return ids;
  }

  /**
   * Create a new job from an action
   * MUTATION
   * @param name the name of the new job
   * @param description description
   * @param actionId the ID of the workflow
   * @param file if job is created from a file, the file contents
   * @param actionsToCancel list of action IDs to skip during execution
   * @returns Promise containing the new Job or null if failed
   */
  public async dispatchCreateJobFromAction(
    name: string,
    _description: string,
    actionId: string,
    file?: string,
    actionsToCancel?: string[],
    labId?: string,
    parameterValues?: SimpleResponse | null
  ): Promise<Job | null> {
    if (this.state.selectedLabId || labId) {
      const selectedLabId = this.state.selectedLabId || labId || '';
      const job = await Apollo.newJob(
        name,
        selectedLabId,
        actionId,
        file,
        actionsToCancel,
        JSON.stringify(parameterValues)
      );
      this.storeEntity(EntityType.Job, job);
      if (job.actions) {
        this.storeEntities(EntityType.Action, job.actions);
      }
      if (job.action) {
        this.storeEntity(EntityType.Action, job.action);
        if (job.action.children && job.action.children.length > 0) {
          this.storeEntities(EntityType.Action, job.action.children);
        }
      }
      // now let listeners know that a new job has been created and let them decide
      // what to do about it, if anything.
      UIController.Instance.sendMessage<JobSetChangeMessagePayload>({
        name: JOB_SET_CHANGE,
        payload: new JobSetChangeMessagePayload([
          {
            id: job.id,
            type: JobSetChangeType.ADD,
          },
        ]),
      });
      return job || null;
    }
    return null;
  }

  /**
   * Set the values of a set of Action's ActionParameters
   * MUTATION/IDEMPOTENT
   * @param actionId the ID of the action to which the parameters are attached
   * @param values standard JS object containing K/V pairs with the values
   * @returns A promise containing true if successful
   */
  public async dispatchSetActionParameterValues(
    actionId: string,
    values: SimpleResponse
  ): Promise<boolean> {
    const result = await Apollo.setParameterValues(actionId, values);
    if (result) {
      const action = this.getAction(actionId);
      if (action) {
        // we can't simply set parameterValues here because we may have hook filtered values
        // e.g. - tessera's equipment selection which will then overwrite all the
        // existing parameter values (e.g. lysate plate barcodes)
        let existingValues = {};
        try {
          existingValues = JSON.parse(action.parameterValues || '');
        } catch (_) {
          existingValues = {};
        }
        action.parameterValues = JSON.stringify({
          ...existingValues,
          ...values,
        });
      }
    }
    return result;
  }

  /**
   * Batch two or more jobs
   * MUTATION
   * @param jobIds the IDs of the Jobs to batch
   * @returns Promise containing the new batched "super" Job
   */
  public async dispatchBatchJobs(jobIds: string[]) {
    const batchedJob = await Apollo.batchJobs(jobIds);
    this.storeEntity(EntityType.Job, batchedJob);
    if (batchedJob.action) {
      this.storeEntity(EntityType.Action, batchedJob.action);
    }
    // This query doesn't include actions, so no actions to normalize
    // And you could never start from the ScheduleDialog, so no
    // need to get the basic lab data
    return batchedJob;
  }

  /**
   * Unbatch a previously batched set of jobs
   * MUTATION/IDEMPOTENT?
   * @param jobId the ID of the "super" Job
   * @returns Promise
   */
  public async dispatchUnbatchJob(jobId: string) {
    const response = await Apollo.unbatchJob(jobId);
    const actionsToRemove = this.getActionsByJob(jobId).map((a) => a.id);
    this.removeEntities(EntityType.Action, actionsToRemove);
    this.removeEntity(EntityType.Job, jobId);
    return response;
  }

  /**
   * Finish an assistant (mark it as complete)
   * MUTATION/SHOULD BE IDEMPOTENT (but is not)
   * @param assistantId the ID of the assistant
   * @param userId the user ID of the user that finished it
   * @param finishTime the time it was finished
   * @returns Promise containing the new assistant state
   */
  public async dispatchFinishAssistant(
    assistantId: string,
    userId: string,
    finishTime: string
  ): Promise<SimplifiedState> {
    const newState = await Apollo.finishAssistant(
      assistantId,
      userId,
      finishTime
    );
    return newState;
  }

  /**
   * validates a list of jobs via whatever mechanism (if any) is present on the BE (e.g. a lambda)
   * @param jobIds the list of job IDs to validate
   * @param validationStage when we are attempting this validation in the workflow of creating/scheduling a job
   * @returns see JobValidationResponse
   */
  public async dispatchValidateJobs(
    jobIds: string[],
    validationStage: InteractivityHookStage
  ): Promise<JobValidationResponse> {
    return Apollo.validateJobs(jobIds, validationStage);
  }

  /**
   * SCHEDULER OPERATIONS
   */

  /**
   * Checks the standard scheduler response and throws an exception if scheduing failed
   * @param response the response from the scheduler
   * @param checkSolution if true, this will also check to see if there was a solution found
   * @returns the scheduler ID of the job if successful
   */
  private checkSchedulerResponse(
    response: ScheduleResponse,
    checkSolution = false
  ): string {
    if (response.errors?.length) {
      let errorMessage = 'Could not schedule job: ';
      response.errors.forEach((e) => {
        if (e.errorType?.missingActorAbility?.abilityName) {
          errorMessage += ` - Required ability ${e.errorType.missingActorAbility.abilityName}`;
          if (e.errorType?.missingActorAbility?.actorId) {
            errorMessage += ` of actor ${e.errorType.missingActorAbility.actorId}`;
          }
          errorMessage += ' is not registered.';
        }
      });
      throw new Error(errorMessage);
    }
    if (!response.scheduleId) {
      throw new Error('Scheduler is locked, please try again');
    }
    if (!response?.solutionInfo?.solutionFound && checkSolution) {
      throw new Error('Could not find a scheduling solution');
    }
    return response.scheduleId;
  }

  /**
   * Preview job and associated actions
   * MUTATION
   * @param jobId the ID of the job
   * @param jobIds array of job ids
   * @param constraint asap, or time
   * @param time *optional- used if custom time constraint applied
   * @returns Promise containing the schedule ID of the Job
   */
  public async dispatchPreviewJob(
    jobId: string,
    jobIds: string[],
    labId: string,
    constraint: string,
    time: string
  ) {
    const response = await Apollo.previewJob(
      jobId,
      jobIds,
      labId,
      constraint,
      time
    );
    if (response) {
      return response;
    }
    return null;
  }

  /**
   * Schedule a job
   * MUTATION
   * @param jobId the ID of the job
   * @param jobIds TODO: what is this even for?
   * @param constraint how to schedule the job (specific time or asap)
   * @param time if time scheduled, this is the time to start the job
   * @param previewScheduleId
   * @param scheduleType global or local: defaults to global?
   * @param labId lab the job is scheduled against
   * @returns Promise containing the schedule ID of the Job
   * @throws Error if scheduler could not schedule the job. Error.message will contain the reason.
   */
  public async dispatchScheduleJob(
    jobId: string,
    jobIds: string[],
    constraint: 'time' | 'asap',
    time: string,
    previewScheduleId: string,
    scheduleType?: SchedulingType,
    labId = ''
  ): Promise<string> {
    if (!labId) {
      labId = this.getLab(this.state.selectedLabId)?.id;
    }
    const response = await Apollo.scheduleJob(
      jobId,
      jobIds,
      labId,
      constraint,
      time,
      previewScheduleId,
      scheduleType
    );
    return this.checkSchedulerResponse(response, true);
  }

  /**
   * Unschedule jobs
   * MUTATION
   * @param jobIds a list of job IDs to unschedule
   * @returns the shedulerId of the operation
   * @throws Error if scheduler could not unschedule the jobs
   */
  public async dispatchUnscheduleJobs(jobIds: string[]): Promise<string> {
    const labId = this.getLab(this.state.selectedLabId)?.id;
    const response = await Apollo.unscheduleJobs(jobIds, labId);
    return this.checkSchedulerResponse(response);
  }

  /**
   * Unschedule all scheduled jobs in a lab
   * MUTATION
   * @param labId the ID of the lab
   * @returns a new schedulerId if successful
   * @throws Error if scheduler could not unschedule the jobs
   */
  public async dispatchUnscheduleAllJobs(labId: string): Promise<string> {
    const response = await Apollo.unscheduleAllJobs(labId);
    return this.checkSchedulerResponse(response);
  }

  /**
   * Reschedule all jobs in a given lab
   * MUTATION
   * @param labId the ID of the lab
   * @returns a new schedulerId if successful
   * @throws Error if scheduler could not reschedule the jobs
   */
  public async dispatchRescheduleAllJobs(labId: string): Promise<string> {
    const response = await Apollo.rescheduleAllJobs(labId);
    return this.checkSchedulerResponse(response, true);
  }

  /**
   * Get the last failed action of a job which is not necessarily the action that's marked in ERROR
   * @param jobId the ID of the job
   * @returns last failed action with additional info
   */
  public async dispatchGetLastFailedAction(
    jobId: string
  ): Promise<LastFailedActionInfo | null> {
    const failedActionInfo = await Apollo.getLastFailedActionId(jobId);
    if (failedActionInfo?.actionId) {
      const resolvedAction = this.getAction(failedActionInfo.actionId);
      if (resolvedAction.id) {
        resolvedAction.state = SimplifiedState.ERROR;
        this.storeEntity(EntityType.Action, resolvedAction);
      }
      const job = this.getJob(jobId);
      if (job?.id) {
        job.lastFailedAction = failedActionInfo;
        this.storeEntity(EntityType.Job, job);
      }
    }
    return failedActionInfo;
  }

  /**
   * restart running an action by actionId
   * @param labId the lab on which the action is being run
   * @param actionId id of the action to be reset
   * @returns responseID is the new scheduler id
   * @throws Error if scheduler cannot reset the action. The error message will indicate why
   */
  public async dispatchActionReset(
    labId: string,
    actionId: string
  ): Promise<string> {
    if (this.legacySkipRetry) {
      const response = await Apollo.resetAndRescheduleAction(labId, actionId);
      return this.checkSchedulerResponse(response);
    } else {
      const jobId = this.getAction(actionId)?.jobId;
      if (jobId) {
        const lastFailedAction = await Apollo.getLastFailedActionId(jobId);
        if (lastFailedAction?.canRetry) {
          await Apollo.retryAction(jobId, lastFailedAction.actionId);
          return '';
        }
        throw new Error('Last failed action cannot be retried');
      } else {
        throw new Error('Unable to retrieve jobId from errored action');
      }
    }
  }

  /**
   * Skip an action in error
   * @param actionId the ID of the action to skip
   * @param outputData manually entered output data of the action to be skipped
   * @returns true if success
   */
  public async dispatchSkipAction(
    labId: string,
    actionId: string,
    outputData: SimpleResponse
  ): Promise<boolean | string> {
    if (this.legacySkipRetry) {
      return Apollo.skipAction(labId, actionId, JSON.stringify(outputData));
    } else {
      const jobId = this.getAction(actionId)?.jobId;
      if (jobId) {
        const lastFailedAction = await Apollo.getLastFailedActionId(jobId);
        if (lastFailedAction?.canSkip) {
          await Apollo.skipAction2(
            jobId,
            lastFailedAction.actionId,
            JSON.stringify(outputData)
          );
          return true;
        }
        throw new Error('Unable to calculate last failed action');
      } else {
        throw new Error('Unable to retrieve jobId from errored action');
      }
    }
  }

  /**
   * Get the notes attached to a job
   * @param jobId the ID of the job
   * @returns the job with its notes attached or an empty job if failed
   */
  public async dispatchGetJobNotes(jobId: string): Promise<Job> {
    const job = await Apollo.getJobNotes(jobId);
    if (job) {
      this.storeEntity(EntityType.Job, job);
    }
    return job || nullJob();
  }

  /**
   * Get the names and ids of all actions in a job
   * QUERY
   * @param jobId the id of the job
   * @returns the set of actions with their names filled in
   */
  public async dispatchGetActionNames(jobId: string): Promise<Action[]> {
    const actions = await Apollo.getActionNamesByJob(jobId);
    this.storeEntities(EntityType.Action, actions);
    return actions;
  }

  /**
   * Attach a note to a job
   * MUTATION
   * @param jobId the ID of the job
   * @param text content of the note
   * @returns the job with the new note attached
   */
  public async dispatchAddJobNote(
    jobId: string,
    text: string,
    revision?: string
  ): Promise<Job> {
    const response = await Apollo.addJobNote(jobId, revision || '', text);
    this.storeEntity(EntityType.Job, response);
    return response;
  }

  /**
   * Fetch all file attachements associated with the given job
   * @param jobId the job ID
   * @returns a list of notes attached to that job
   */
  public async dispatchGetJobFiles(jobId: string): Promise<File[]> {
    const response = await Apollo.getFilesByJob(jobId);
    const job = this.getJob(jobId);
    job.files = response;
    return response;
  }

  /**
   * Fetches the current list of errors in the given lab
   * @param labId the lab ID
   * @returns a list of the errors in that lab
   */
  public async dispatchGetErrorsByLab(labId: string): Promise<PublicError[]> {
    const response = await Apollo.getErrorsByLab(labId);
    this.state.errors[labId] = response;
    return response;
  }

  /**
   * Fetches a program graph
   * @param userId the user's ID
   * @param labId the lab ID
   * @param jobId if present, will fetch the job's program graph, if not, it will fetch the lab's graph
   * @returns the program graph or null if not found.
   */
  public async dispatchGetProgramGraph(
    userId: string,
    labId: string,
    jobId?: string
  ): Promise<ProgramGraphInfo | null> {
    const response = await Apollo.getProgramGraph(userId, labId, jobId);
    if (response) {
      this.state.programGraphs[labId] = response;
    }
    return response || null;
  }

  /**
   * Refetch just an action's schema. This is used if some other actor in the system
   * might choose to modify the schema at runtime. Ex: tessera's instruments may become
   * unavailable and we should grey out that instrument choice at runtime.
   * @param actionId the action ID
   */
  public async dispatchRefetchSchema(actionId: string) {
    const action = await Apollo.refetchActionSchema(actionId);
    if (action.schema) {
      this.storeEntity(EntityType.Action, action);
    }
  }

  /**
   * Fetch the already completed job events for the given job
   * @param jobId the ID of the job
   * @param offset pagination offset
   * @param limit pagination limit
   * @returns the events and additional pagination info
   */
  public async dispatchGetJobEvents(
    jobId: string,
    offset = 0,
    limit = 50,
    eventTypes = JobEventTypeFilter,
    actionTypes = TimelineActionTypeFilters,
    forError = false
  ): Promise<JobEventResponse> {
    let job = this.getJob(jobId);
    if (!job.id) {
      job = {
        ...nullJob(),
        id: jobId,
      };
    }
    if (offset === 0) {
      job.events = [];
      job.currentEventOffset = 0;
      job.totalEventCount = 0;
    }
    let response: JobEventResponse;
    if (!forError) {
      response = await Apollo.getJobEvents(
        jobId,
        offset,
        limit,
        eventTypes,
        actionTypes
      );
    } else {
      response = await Apollo.getErroredJobEvents(
        jobId,
        offset,
        limit,
        eventTypes,
        actionTypes
      );
    }

    if (offset === 0) {
      job.events = [...response.jobEvents];
    } else {
      job.events.push(...response.jobEvents);
    }
    job.totalEventCount = response.totalCount;
    if (!job.currentEventOffset) {
      job.currentEventOffset = 0;
    }
    job.currentEventOffset += limit;
    this.storeEntity(EntityType.Job, job);
    return response;
  }

  private mergeSchemasAndValues(
    schemas: RequestJSONSchema[],
    parameterValues: SimpleResponse[]
  ): { schema: RequestJSONSchema; values: SimpleResponse } {
    const schema: RequestJSONSchema = {};
    merge(schema, ...schemas);
    const values: SimpleResponse = {};
    merge(values, ...parameterValues);
    return { schema, values };
  }

  private processJobParameterEvents(
    workflow: Action,
    events: JobEvent[]
  ): void {
    // don't overwrite existing schema and values
    const existingValues = JSON.parse(workflow.parameterValues || '{}');
    const existingSchema = JSON.parse(workflow.schema || '{}');
    const jobParamsAppliedEvents = events.filter(
      (e) => e.type === JobEventType.JOB_PARAMETERS_APPLIED
    );
    const jobTerminalEvents = events.filter(
      (e) =>
        e.type === JobEventType.JOB_OUTPUTS_CREATED ||
        e.type === JobEventType.JOB_CANCELLED
    );
    const inputSchemas = jobParamsAppliedEvents
      .map((e) => e.inputsSchema)
      .filter((s) => s);
    const outputSchemas = jobTerminalEvents
      .map((e) => e.outputsSchema)
      .filter((s) => s);
    const inputValues = jobParamsAppliedEvents
      .map((e) => e.inputValues)
      .filter((v) => v);
    const outputValues = jobTerminalEvents
      .map((e) => e.outputValues)
      .filter((v) => v);
    if (inputSchemas?.length > 0 || outputSchemas?.length > 0) {
      const { schema, values } = this.mergeSchemasAndValues(
        // @ts-ignore
        [existingSchema, ...inputSchemas, ...outputSchemas],
        [existingValues, ...inputValues, ...outputValues]
      );
      workflow.schema = JSON.stringify(schema);
      workflow.parameterValues = JSON.stringify(values);
      this.storeEntity(EntityType.Action, workflow);
    }
  }

  public async dispatchGetJobParameterEvents(
    jobId: string
  ): Promise<JobEvent[]> {
    const job = this.getJob(jobId);
    if (job.action?.id) {
      const action = this.getAction(job.action.id);
      if (isEmpty(action.schema) || isEmpty(action.parameterValues)) {
        const response = await Apollo.getJobParameterEvents(jobId);
        this.processJobParameterEvents(action, response.jobEvents);
        return response.jobEvents;
      }
    }
    return [];
  }

  /**
   * Retrieve the report for the given jobId
   * @param jobId the id of the job
   * @returns JSON document of the job report
   */
  public async dispatchGetJobReport(jobId: string): Promise<unknown> {
    return Apollo.getJobReport(jobId);
  }

  // GETTERS/SETTERS

  getJobEvents(jobId: string): JobEvent[] {
    const job = this.getJob(jobId);
    if (job?.events?.length) {
      return job.events;
    }
    return [];
  }

  getJobEventsCount(jobId: string): number {
    const job = this.getJob(jobId);
    return job.totalEventCount || 0;
  }

  /**
   * Get all labs in the system
   */
  public get labs(): Lab[] {
    const ls = this.getEntities(EntityType.Lab) as Lab[];
    return ls.filter((l) => l.name);
  }

  /**
   * Get a lab by ID
   * @param labId the lab ID
   * @returns the lab or an empty lab if not found
   */
  public getLab(labId: string): Lab {
    const lab = this.getEntity(EntityType.Lab, labId) as Lab;
    return lab || nullLab();
  }

  /**
   * Gets the current state of a lab.
   * @param labId the lab ID
   * @returns the state of the lab
   */
  public getLabState(labId: string): SimplifiedState {
    const lab = this.getLab(labId);
    if (lab.id) {
      return lab.state;
    }
    return SimplifiedState.UNKNOWN;
  }

  /**
   * Get the last failed action in the given job
   * @param jobId the job ID
   * @returns info about the last failed action if found
   */
  public getLastFailedAction(jobId: string): LastFailedActionInfo | null {
    const job = this.getJob(jobId);
    if (job.id) {
      return job.lastFailedAction || null;
    }
    return null;
  }

  /**
   * All jobs in the whole system
   */
  public get jobs(): Job[] {
    return this.getEntities(EntityType.Job) as Job[];
  }

  /**
   * The current page of jobs in a paginated jobs query
   */
  public get currentJobsPage(): Job[] {
    return this.state.currentJobsPage;
  }

  /**
   * The offset into the set of jobs in the current paginated jobs query
   */
  public get currentJobsPageOffset(): number {
    return this.state.currentJobsPageOffset;
  }

  /**
   * The current page size of the paginated jobs query
   */
  public get currentJobsPageLimit(): number {
    return this.state.currentJobsPageLimit;
  }

  /**
   * The total number of jobs matching the current job filter set
   */
  public get currentJobsPageTotalCount(): number {
    return this.state.currentJobsPageTotalCount;
  }

  /**
   * Should we use the old skip/retry logic?
   */
  public get legacySkipRetry(): boolean {
    return this.state.legacySkipRetry;
  }

  public set legacySkipRetry(val: boolean) {
    this.state.legacySkipRetry = val;
  }

  /**
   * We can only skip or retry actions on a job if we have 2 errored actions
   * 1. The actual action that caused the error
   * 2. The error handler expression action
   * @param jobId the job
   * @returns true if the job's actions can be skipped or retried, false otherwise
   */
  public jobHasSkippableOrRetryableAction(jobId: string): boolean {
    if (this.legacySkipRetry) {
      return true;
    }
    const erroredActions = this.getActionsByJob(jobId).filter(
      (a) =>
        a.state === SimplifiedState.ERROR ||
        a.state === SimplifiedState.RUNNING_WITH_ERROR
    );
    return erroredActions.some(
      (a) => a.actionType === ActionType.ERROR_HANDLER
    );
  }

  /**
   * Get a job by ID
   * @param id the job ID
   * @returns the Job or an empty job if not found
   */
  public getJob(id: string): Job {
    const job = this.getEntity(EntityType.Job, id) as Job;
    return job || nullJob();
  }

  /**
   * Gets all jobs belonging to a lab that do not have a parent.
   * This means a job that is the result of a batching operation or
   * a job that isn't batched.
   * @param labId the lab ID
   * @returns a list of "super" jobs
   */
  public getSuperJobs(labId?: string): Job[] {
    const superJobs = this.jobs.filter(
      (j) => !j.parentId && (!labId || j.labId === labId)
    );
    return superJobs;
  }

  /**
   * Gets all jobs in the "Pending" state a.k.a. Requests
   * @param labId the lab ID
   * @param workflowId if provided, will filter the requests to those created from the given workflowId
   * @returns the list of all requests matching the input parameters
   */
  public getPendingJobs(labId?: string, workflowId?: string): Job[] {
    return this.state.requests.filter(
      (r) =>
        (!labId || r.labId === labId) &&
        (!workflowId || r.workflowId === workflowId)
    );
  }

  /**
   * Get the notes associated with a job
   * @param jobId the job ID
   * @returns all the notes attached to the given job
   */
  public getJobNotes(jobId: string): Note[] {
    const job = this.getJob(jobId);
    return job.notes || [];
  }

  /**
   * Get an action by ID
   * @param actionId the action ID
   * @returns the action if found, an empty action otherwise
   */
  public getAction(actionId: string): Action {
    const action = this.getEntity(EntityType.Action, actionId) as Action;
    return action || nullAction();
  }

  /**
   * Gets an assistant IF it is running
   * @param id the assistant ID
   * @returns the assistant or an empty action if not found
   * or if the id does not refer to an assistant or that assistant is not running
   */
  public getRunningAssistant(id: string): Action {
    const assistant = this.getAction(id);
    if (
      assistant?.actionType === ActionType.ASSISTANT &&
      (assistant.state === SimplifiedState.RUNNING ||
        assistant.state === SimplifiedState.RUNNING_NEED_ASSISTANCE ||
        assistant.state === SimplifiedState.RUNNING_WITH_ASSISTANCE)
    ) {
      return assistant;
    }
    return nullAction();
  }

  /**
   * Get an assistant by ID or step or task ID
   * @param id the assistant ID, also can be a step or task ID
   * @returns the assistant action or an empty action if not found
   * or the given ID does not refer to an assistant
   */
  public getAssistant(id: string): Action {
    const action = this.getAction(id);
    if (action.actionType === ActionType.ASSISTANT) {
      return action;
    } else if (action.actionType === ActionType.ASSISTANT_STEP) {
      return this.getAction(action.parentId);
    } else if (action.actionType === ActionType.ASSISTANT_TASK) {
      const step = this.getAction(action.parentId);
      return this.getAction(step.parentId);
    }
    return nullAction();
  }

  /**
   * Determine if an assistant is compliant
   * @param actionId the assistant ID
   * @returns true if compliant, false otherwise
   */
  public isAssistantCompliant(actionId: string): boolean {
    const action = this.getAction(actionId);
    if (action.actionType === ActionType.ASSISTANT) {
      return !!action.assistant?.compliant;
    }
    return false;
  }

  /**
   * Get jobs that are FINISHED or CANCELLED
   * @deprecated use a paginated query instead
   * @param labId the lab ID
   * @returns all jobs on that lab that are terminal
   */
  public getResults(labId?: string) {
    return this.getSuperJobs(labId).filter(
      (j) =>
        j.state === SimplifiedState.FINISHED ||
        j.state === SimplifiedState.CANCELLED
    );
  }

  /**
   * Get all of a job's actions. These will not be ordered nor organized by hierarchy
   * NOTE: This can be expensive when we have a lot of actions saved off
   * @param jobId the ID of the job
   * @returns All of that job's actions
   */
  public getActionsByJob(jobId: string): Action[] {
    const actions = this.getEntities(EntityType.Action) as Action[];
    return actions.filter((a) => a.jobId === jobId);
  }

  /**
   * Get the focused or "important" action. Generally, this is the currently running action
   * in a job, but could also be in error or needs assistance, etc...
   * @param jobId the job ID
   * @returns the action that is currently "important" in the workflow or undefined if there isn't one
   */
  public getFocusedAction(jobId: string): Action | undefined {
    const job = this.getJob(jobId);
    return job?.focusedAction;
  }

  /**
   * Get the actions of a job in execution order
   * @param actionId the ID top level action of the job
   * @returns The children of the top level action (the job's actual executable actions in order)
   */
  public getOrderedActions(actionId: string): Action[] {
    const topLevelAction = this.getAction(actionId);
    if (topLevelAction.children?.length) {
      return topLevelAction.children;
    }
    return [];
  }

  /**
   * Get the parameter schema for a specific action which tells us
   * the shape of the data that contains the values passed to/from that action
   * @param actionId the action ID
   * @param hook if present, will filter the schema and return only the params that match the hook (timing in the job creation flow)
   * @param input if present will filter the schema and return on the fields that are of the same direction (input vs. output)
   * @returns the schema or an empty object if not found
   */
  public getActionSchema(
    actionId: string,
    hook?: InteractivityHookStage,
    input?: boolean
  ): RequestJSONSchema {
    const action = this.getAction(actionId);
    if (action) {
      return filterSchemaToDirection(
        parseAndFilterSchemaToHook(action, hook),
        input
      );
    }
    return {};
  }

  /**
   * Gets the parameter values for a specific action
   * @param actionId the action ID
   * @returns the parameter values for that action or null if none exist
   */
  public getActionParameterValues(actionId: string): SimpleResponse | null {
    const action = this.getAction(actionId);
    if (action?.parameterValues) {
      try {
        return convertActionParameterIntegerValues(action);
      } catch (_) {
        return null;
      }
    }
    return null;
  }

  /**
   * Gets the file attachments for a specific job
   * @param jobId the job ID
   * @returns an array of File attachments
   */
  public getFilesByJob(jobId: string): File[] {
    const job = this.getJob(jobId);
    if (job?.files) {
      return job.files;
    }
    return [];
  }

  /**
   * Gets the program graph for a lab
   * @param labId the lab ID
   * @returns the program graph
   */
  public getProgramGraph(labId: string): ProgramGraphInfo {
    return (
      this.state.programGraphs[labId] || {
        numEdges: 0,
        numNodes: 0,
        hasError: false,
        errorMessage: '',
        returnPlot: '',
      }
    );
  }

  /**
   * Return an array of PublicErrors after filtering by labId
   * @param labId string
   * @returns PublicError[]
   */
  public getErrorsByLab(labId: string): PublicError[] {
    return this.state.errors[labId] || [];
  }

  /**
   * Return an array of PublicErrors after filtering by jobId and labId
   * @param labId string
   * @param jobId string
   * @returns PublicError[]
   */
  public getErrorsByJob(labId: string, jobId: string): PublicError[] {
    return (
      this.state.errors[labId]?.filter((error) => error.jobId === jobId) || []
    );
  }

  /**
   * Clear the current page of data. Used to prevent last job set from flickering
   * when transitioning between views.
   */
  public clearPaginatedJobs() {
    this.state.currentJobsPage = [];
    this.state.currentJobsPageLimit = 20;
    this.state.currentJobsPageOffset = 0;
    this.state.currentJobsPageTotalCount = 0;
  }

  /**
   * set the lab the user has chosen to watch. This will close any existing subscriptions and
   * open new ones if necessary.
   * @param labId the lab ID
   */
  public set selectedLabId(labId: string) {
    if (this.watchedChangesUnsubscribeFn) {
      this.watchedChangesUnsubscribeFn();
      this.watchedChangesUnsubscribeFn = null;
    }
    if (this.watchedStateUnsubscribeFn) {
      this.watchedStateUnsubscribeFn();
      this.watchedStateUnsubscribeFn = null;
    }
    if (this.watchedErrorsUnsubscribeFn) {
      this.watchedErrorsUnsubscribeFn();
      this.watchedErrorsUnsubscribeFn = null;
    }
    this.stopJobEventsSubscription();

    if (labId) {
      this.startJobsInLabSubscription(labId);
      this.startJobsStateSubscription(labId);
      this.startPublicErrorsByLab(labId);
    }

    this.state.selectedLabId = labId;

    AdapterController.Instance.refreshHealthStatus();
  }

  public get selectedLabId() {
    return this.state.selectedLabId;
  }

  /**
   * SUBSCRIPTIONS - real-time changes to the ops system are handled here
   */

  /**
   * Starts the jobsInLab subscription which notifies us when a new job gets created or
   * an existing job gets deleted. Note that we don't need any of the job information
   * in the message besides its ID. This is because it'll just trigger a re-query from
   * the pagniation API (if the UI component deems it necessary)
   * @param id the lab ID
   */
  private startJobsInLabSubscription(labId: string) {
    this.watchedChangesUnsubscribeFn = Apollo.startSubscribeOps(
      labId,
      (op: string, full: boolean, jobs: Job[]) => {
        // ignore the full payload, we'll get that list from the pagniation query
        if (!full) {
          const [job] = jobs;
          if (op === 'UPDATED') {
            this.storeEntity(EntityType.Job, job);
            UIController.Instance.sendMessage<JobSetChangeMessagePayload>({
              name: JOB_SET_CHANGE,
              payload: new JobSetChangeMessagePayload([
                {
                  id: job.id,
                  type: JobSetChangeType.ADD,
                },
              ]),
            });
          } else if (op === 'REMOVED') {
            // if the job was deleted, we don't need to track its actions anymore
            const actionsToRemove = this.getActionsByJob(job.id).map(
              (a) => a.id
            );
            this.removeEntities(EntityType.Action, actionsToRemove);
            this.removeEntity(EntityType.Job, job.id);
            UIController.Instance.sendMessage<JobSetChangeMessagePayload>({
              name: JOB_SET_CHANGE,
              payload: new JobSetChangeMessagePayload([
                {
                  id: job.id,
                  type: JobSetChangeType.DELETE,
                },
              ]),
            });
          }
        }
      }
    );
  }

  /**
   * Is this an action that the user needs to be kept apprised of?
   * @param action the action
   * @returns true if the action should be tracked, false otherwise
   */
  private isActionInteresting(action: Action): boolean {
    if (
      action.actionType === ActionType.WORKFLOW ||
      action.actionType === ActionType.RECURRING ||
      action.actionType === ActionType.DIRECT ||
      action.actionType === ActionType.ERROR_HANDLER ||
      action.actionType === ActionType.ASSISTANT_STEP ||
      action.actionType === ActionType.ASSISTANT_TASK
    ) {
      return false;
    } else if (
      action.state === SimplifiedState.RUNNING ||
      action.state === SimplifiedState.RUNNING_NEED_ASSISTANCE ||
      action.state === SimplifiedState.RUNNING_WITH_ASSISTANCE ||
      action.state === SimplifiedState.ERROR ||
      action.state === SimplifiedState.RUNNING_WITH_ERROR ||
      action.state === SimplifiedState.ON_HOLD ||
      action.state === SimplifiedState.PAUSED
    ) {
      return true;
    }
    return false;
  }

  /**
   * Starts the jobsState subscription. This subscription is probably the most important one
   * in the system, it notifies us in real-time of changes to not only a job's state but of
   * changes in any of its action's states as well.
   * @param labId the lab ID
   */
  public startJobsStateSubscription(labId: string) {
    this.watchedStateUnsubscribeFn = Apollo.startSubscribeState(
      labId,
      (_full: boolean, jobsState: JobState[]) => {
        const jobStateMessage = new JobStateChangeMessagePayload([]);
        jobsState.forEach((jobState) => {
          let oldState = SimplifiedState.UNKNOWN;
          const existingJob = this.getJob(jobState.job.id);
          if (existingJob.id) {
            oldState = existingJob.state;
            existingJob.state = jobState.job.state;
          } else {
            // store (merge) and refetch
            this.storeEntity(EntityType.Job, jobState.job);
          }
          const mergedJob = this.getJob(jobState.job.id);

          jobStateMessage.add({
            id: jobState.job.id,
            oldState,
            newState: jobState.job.state,
          });

          if (jobState.assistants) {
            // despite the name "assistants", this is actually ALL actions in the job
            jobState.assistants.forEach((as) => {
              as.jobId = jobState.job.id;
              // store (merge) and refetch
              this.storeEntity(EntityType.Action, as);
              const mergedAction = this.getAction(as.id);
              if (
                mergedAction.actionType === ActionType.ERROR_HANDLER &&
                mergedAction.state === SimplifiedState.ERROR
              ) {
                this.dispatchGetLastFailedAction(jobState.job.id);
              }
              if (this.isActionInteresting(mergedAction)) {
                mergedJob.focusedAction = mergedAction;
              } else if (
                !this.isActionInteresting(mergedAction) &&
                mergedAction.id === mergedJob.focusedAction?.id
              ) {
                // the action was interesting and now it no longer is
                // Ex: an assistant went from RUNNING_NEED_ASSISTANCE to FINISHED
                mergedJob.focusedAction = undefined;
              }
            });
          }
        });
        // notifiy listeners of all the job state changes so they can for example: re-query the current page
        UIController.Instance.sendMessage<JobStateChangeMessagePayload>({
          name: JOB_STATE_CHANGE,
          payload: jobStateMessage,
        });
      }
    );
  }

  /**
   * This subscription delivers new job events for a running job in real-time
   * @param jobId the ID of the job whose events we want to track
   * @param latestTimestamp the latest (redis) timestamp we got from the static query
   *  the subscription will deliver new events that have a timestamp > this
   */
  public startJobEventsSubscription(
    jobId: string,
    latestTimestamp: string,
    eventTypes = JobEventTypeFilter,
    actionTypes = TimelineActionTypeFilters,
    forError = false
  ) {
    this.stopJobEventsSubscription();
    const job = this.getJob(jobId);
    if (
      job.id &&
      job.state !== SimplifiedState.FINISHED &&
      job.state !== SimplifiedState.CANCELLED
    ) {
      const cb = (event: JobEvent) => {
        job.events.unshift(event);
        const action = this.getAction(job.action?.id || '');
        this.processJobParameterEvents(action, [event]);
        if (
          event.type === JobEventType.JOB_COMPLETED ||
          event.type === JobEventType.JOB_CANCELLED
        ) {
          this.stopJobEventsSubscription();
        }
      };
      if (forError) {
        this.jobEventsSubscription = Apollo.startSubscribeErroredJobEvents(
          jobId,
          latestTimestamp,
          eventTypes,
          actionTypes,
          cb
        );
      } else {
        this.jobEventsSubscription = Apollo.startSubscribeJobEvents(
          jobId,
          latestTimestamp,
          eventTypes,
          actionTypes,
          cb
        );
      }
      this.jobEventsSubscription.heartbeatInterval = setInterval(
        () =>
          Apollo.sendSubscriptionHeartbeat(
            this.jobEventsSubscription?.subscriptionId
          ),
        this.JOB_EVENTS_HEARTBEAT_INTERVAL
      );
    }
  }

  /**
   * In the case of a reconnecting job events subscripton, we need to update eventsAfter
   * in the query or the subscription will replay events we already have. This function gets the
   * timestamp cursor of the most recent event we have for a given job so we don't miss any
   * events while the websocket is busy reconnecting.
   * @param jobId the job
   */
  public getJobEventsSubscriptionCursor(jobId: string): string | null {
    const job = this.getJob(jobId);
    if (job.events?.length) {
      return job.events[0].redisTimestamp;
    }
    return null;
  }

  /**
   * Shutdown an existing job events subscription
   */
  public stopJobEventsSubscription() {
    if (this.jobEventsSubscription?.unsubscribeFn) {
      this.jobEventsSubscription.unsubscribeFn();
    }
    if (this.jobEventsSubscription?.heartbeatInterval) {
      clearInterval(this.jobEventsSubscription.heartbeatInterval);
    }
    this.jobEventsSubscription = null;
  }

  /**
   * This subscription tracks error messages from public-errors in real-time
   * @param labId the lab ID
   */
  private startPublicErrorsByLab(labId: string) {
    this.watchedErrorsUnsubscribeFn = Apollo.startSubscribeErrors(
      labId,
      (publicError: PublicError) => {
        // kick off notification
        let errorTitle = publicError.title || publicError.sourceFile;
        if (!errorTitle) {
          errorTitle = publicError.jobId
            ? this.getJob(publicError.jobId).name
            : this.getLab(publicError.labId).name;
        }

        NotificationsController.Instance.addNewToast(
          errorTitle,
          publicError.labId,
          publicError.severity !== PublicErrorSeverity.INFO &&
            publicError.severity !== PublicErrorSeverity.UNKNOWN,
          () => {
            // the user is opening the error log, there's no reason to
            // continue displaying error toasts once the log is open
            NotificationsController.Instance.removeAllErrorToasts();
            UIController.Instance.displayBottomDrawer = true;
          },
          'View',
          publicError.severity
        );

        const currentLabErrors = this.state.errors[publicError.labId] || [];
        currentLabErrors.unshift(publicError);
        this.state.errors[labId] = currentLabErrors;
      }
    );
  }

  /**
   * This is a special subscription so we can watch the state of a job when a user
   * runs a directly runnable "action" from settings. These are typically used
   * to affect some adapter change and are a one-off operation
   * @param labId the lab ID
   * @returns the callback method to shut down the subscription
   */
  public startThinJobState(labId: string): () => void {
    return Apollo.startSubscribeStateThin(
      labId,
      (full: boolean, jobsState: JobState[]) => {
        if (!full) {
          jobsState.forEach((js) => {
            this.storeEntity(EntityType.Job, js.job);
          });
        }
      }
    );
  }
}
