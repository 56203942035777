
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
  props: {
    action: Function,
  },
  setup(props) {
    const fileInput = ref('');
    const fileDragging = ref(false);
    const fileName = computed(() => {
      if (fileInput.value) {
        return fileInput.value;
      }
      return 'Choose or drag a CSV file...';
    });
    function readFile(file: File) {
      const reader = new FileReader();
      reader.onload = () => {
        fileInput.value = file.name;
        let fileData = reader.result as string;
        // strip off the dataUrl leaving just base64 data
        const dataUrlIdx = fileData.indexOf('base64,');
        if (dataUrlIdx >= 0) {
          fileData = fileData.slice(dataUrlIdx + 7);
        }

        if (props.action) {
          props.action(fileData);
        }
      };
      reader.readAsDataURL(file);
    }

    function fileSelected(event: Event) {
      const fileEvent = event.target as HTMLInputElement;
      if (fileEvent?.files?.length !== 1) {
        return;
      }

      const file = fileEvent.files[0];
      readFile(file);
    }

    return {
      fileInput,
      fileSelected,
      fileName,
      fileDragEnter(e) {
        e.preventDefault();
        fileDragging.value = true;
      },
      fileDragOver(e) {
        e.preventDefault();
        fileDragging.value = true;
      },
      fileDragExit() {
        fileDragging.value = false;
      },
      fileDrop(e) {
        e.preventDefault();
        fileDragging.value = false;
        console.log(e);
        if (e.dataTransfer?.files.length) {
          readFile(e.dataTransfer.files[0]);
        }
      },
      fileDragging,
    };
  },
});
