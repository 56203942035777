import { ActionType } from '@/clients/action';
import { GanttJob, GanttAnnotation, GanttActivity } from '@/clients/ops/model';
import { JobEventType } from '@/clients/ops';
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';

const newJobInterval = 5000;
const minActivityDuration = 5000; // min duration of a single activity in milliseconds
const maxActivityDuration = 10000; // max random duration added to an activity
const minActivityCount = 5;
const maxActivityCount = 10;
const fakeAnnotationCount = 5;

const fakeGanttData: GanttJob[] = [];
export default async function getFakeGanttData(
  initialJobs = 5
): Promise<GanttJob[]> {
  if (fakeGanttData.length === 0) {
    const fakeStartTime = DateTime.now().minus(initialJobs * newJobInterval);
    for (let i = 0; i < initialJobs; i++) {
      fakeGanttData.push(
        generateFakeJob(fakeStartTime.plus(i * newJobInterval))
      );
    }
  } else {
    // modify the existing data
    const latestStartDate = convertTimestamp(
      fakeGanttData[fakeGanttData.length - 1].start
    );
    if (latestStartDate.plus(newJobInterval) < DateTime.now()) {
      // add a new lane every newJobInteral milliseconds
      fakeGanttData.push(generateFakeJob(DateTime.now()));
    }
    // also need a mechanism to drop old lanes
    // for now, just max out at a number of lanes
    if (fakeGanttData.length > 20) {
      fakeGanttData.shift();
    }
  }
  // need to run it through the "current time" filter here
  const test = fakeGanttData
    .map((val) => {
      return trimFakeData(val);
    })
    .filter((val): val is GanttJob => val !== undefined);
  return test;
}

// trims fake future events off of gantt data
function trimFakeData(job: GanttJob): GanttJob | undefined {
  const startTime = convertTimestamp(job.start);
  // if this job hasn't started yet, remove it entirely
  if (startTime > DateTime.now()) {
    return undefined;
  }
  const endTime = convertTimestamp(job.end);
  // if this job is finished, return all of it
  if (endTime < DateTime.now()) {
    return structuredClone(job);
  }

  // this job is still ongoing, filter out any future stuff
  const filteredActivities: GanttActivity[] = [];
  for (const activity of job.activities) {
    const startTime = convertTimestamp(activity.start);
    if (startTime > DateTime.now()) {
      // once we hit an activity in the future, stop doing this
      break;
    }
    const endTime = convertTimestamp(activity.end);
    // if this activity is completed, just push it
    if (endTime < DateTime.now()) {
      filteredActivities.push(activity);
      continue;
    }
    // if this activity is in process, push part of it, then break;
    filteredActivities.push({
      ...activity,
      end: encodeTimestamp(DateTime.now()),
      state: 'ACTIVITY_TYPE_ACTION_RUNNING',
    });
    break;
  }
  const filteredAnnotations: GanttAnnotation[] = [];
  for (const annotation of job.annotations) {
    const time = convertTimestamp(annotation.timestamp);
    if (time < DateTime.now()) {
      filteredAnnotations.push(annotation);
    }
  }
  const filteredJob: GanttJob = {
    name: job.name,
    id: job.id,
    workflowId: job.workflowId,
    start: job.start,
    end: '',
    activities: filteredActivities,
    annotations: filteredAnnotations,
  };
  return filteredJob;
}
let fakeJobCount = 0;
function generateFakeJob(startTime: DateTime): GanttJob {
  // const startTime = DateTime.now().minus(1000); // start it 1 second ago
  // const endTime = DateTime.now().plus((1 - Math.random() * 0.5) * maxJobDuration); // end is from half of max time to max time

  const fakeActivities = generateFakeActivities(
    startTime,
    minActivityCount + Math.random() * (maxActivityCount - minActivityCount)
  );
  const endTimestamp = fakeActivities[fakeActivities.length - 1].end;
  const fakeAnnotations = generateFakeAnnotations(
    startTime,
    convertTimestamp(endTimestamp)
  );
  const ganttJob: GanttJob = {
    name: `Fake_Job_${fakeJobCount}`,
    id: uuid(),
    workflowId: uuid(),
    start: encodeTimestamp(startTime),
    end: endTimestamp,
    activities: fakeActivities,
    annotations: fakeAnnotations,
  };
  fakeJobCount++;

  return ganttJob;
}

function pickRandomState() {
  // RUNNING: '#0B83FF',
  // ACTIVITY_TYPE_ACTION_RUNNING: '#0B83FF',
  // ACTIVITY_TYPE_ASSISTANT_RUNNING: '#0B83FF',

  // FINISHED: '#0B83FF',

  const options = [
    'ACTIVITY_TYPE_ASSISTANCE_NEEDED',
    'ACTIVITY_TYPE_ERROR',
    'ACTIVITY_TYPE_PAUSED',
    'ACTIVITY_TYPE_UNKNOWN',
    'ACTIVITY_TYPE_ON_HOLD',
  ];
  return options[Math.floor(Math.random() * options.length)];
}

function generateFakeActivities(
  startTime: DateTime,
  numActivities: number
): GanttActivity[] {
  const fakeActivities: GanttActivity[] = [];
  let currentTime = startTime;
  for (let i = 0; i < numActivities; i++) {
    //march forward until we create activities all across this job
    const activityDuration =
      minActivityDuration +
      Math.random() * (maxActivityDuration - minActivityDuration);
    fakeActivities.push({
      name: 'activity name ' + i,
      start: encodeTimestamp(currentTime),
      end: encodeTimestamp(currentTime.plus(activityDuration)),
      state: pickRandomState(),
      actionId: uuid(),
      actionType: ActionType.UNKNOWN,
    });
    currentTime = currentTime.plus(activityDuration);
  }
  return fakeActivities;
}

function generateFakeAnnotations(
  startTime: DateTime,
  endTime: DateTime
): GanttAnnotation[] {
  const duration = endTime.diff(startTime, 'minutes').minutes;
  // this is not right yet...
  const fakeAnnotations: GanttAnnotation[] = [];
  for (let i = 0; i < fakeAnnotationCount; i++) {
    const randTime = Math.random() * duration;
    const fakeAnnotation: GanttAnnotation = {
      type: JobEventType.JOB_SPAWNED_CHILD,
      timestamp: encodeTimestamp(startTime.plus({ minutes: randTime })),
    };
    fakeAnnotations.push(fakeAnnotation);
  }
  return fakeAnnotations;
}

function encodeTimestamp(dateTime: DateTime): string {
  const timestamp = dateTime.toUTC().toISO();
  if (timestamp === null) {
    console.error('Failed to convert DateTime to timestamp.');
    return '';
  }
  return timestamp;
}

function convertTimestamp(timestamp: string): DateTime {
  if (timestamp !== '') {
    {
      const dt = DateTime.fromISO(timestamp);
      if (dt.isValid) return dt;
    }
    {
      const dt = DateTime.fromSQL(timestamp);
      if (dt.isValid) return dt;
    }
  }
  throw new Error(`Invalid timestamp:  ${timestamp}`);
}
