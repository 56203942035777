
import {
  defineComponent,
  ref,
  Ref,
  onMounted,
  onUnmounted,
  computed,
  ComputedRef,
  PropType,
} from 'vue';
import { debounce, isEmpty } from 'lodash';
import { Job } from '@/clients/ops/model';
import ConfigController from '@/clients/config';
import ActionController from '@/clients/action';
import OpsController from '@/clients/ops';
import AbbreviatedParams from './AbbreviatedParams.vue';
import {
  InteractivityHookStage,
  RequestJSONSchema,
  SimpleResponse,
} from '@/components/GenericForm/types';
import { formatDateTime } from '@/pages/utils';

export default defineComponent({
  props: {
    jobs: {
      type: Array as PropType<Job[]>,
      required: true,
    },
    workflowId: {
      type: String,
      required: true,
    },
  },
  components: {
    AbbreviatedParams,
  },
  emits: ['update-selected', 'refresh-pending-jobs'],
  setup(props, { emit }) {
    const tableWrapper: Ref<HTMLElement | null> = ref(null);
    const tableHeight = ref(1000);
    const showSampleCount = ref(false);
    const searchString = ref('');
    const updateSelected = (id: string) => emit('update-selected', id);

    const updateTableSize = () => {
      if (tableWrapper.value) {
        tableHeight.value = tableWrapper.value.getBoundingClientRect().height;
      }
    };

    onMounted(() => {
      updateTableSize();
      window.addEventListener('resize', debounce(updateTableSize, 250));
    });

    onUnmounted(() => window.removeEventListener('resize', updateTableSize));

    const refreshPendingJobs = () => emit('refresh-pending-jobs');

    if (props.workflowId) {
      ConfigController.Instance.dispatchGetWorkflowConfig(
        props.workflowId
      ).then((config) => {
        showSampleCount.value = !!config.configValues['sample_count_valid'];
      });
    }

    const workflowSchema: ComputedRef<RequestJSONSchema> = computed(() => {
      let schema: RequestJSONSchema = {};
      // the schema is the same for all the jobs in this context
      schema = ActionController.Instance.getActionSchema(
        props.workflowId,
        InteractivityHookStage.INTERACTIVITY_HOOK_PRE_CREATE,
        true
      );
      if (isEmpty(schema) && props.jobs[0]?.action?.id) {
        schema = OpsController.Instance.getActionSchema(
          props.jobs[0]?.action?.id,
          InteractivityHookStage.INTERACTIVITY_HOOK_PRE_CREATE,
          true
        );
      }
      return schema;
    });

    function getJobParameters(job: Job): SimpleResponse {
      if (job.action?.id) {
        return (
          OpsController.Instance.getActionParameterValues(job.action.id) || {}
        );
      }
      return {};
    }

    const filteredJobs = computed(() => {
      if (searchString.value) {
        return props.jobs.filter((j) =>
          j.name
            .toLocaleLowerCase()
            .includes(searchString.value.toLocaleLowerCase())
        );
      }
      return props.jobs;
    });
    return {
      filteredJobs,
      updateSelected,
      refreshPendingJobs,
      tableWrapper,
      tableHeight,
      showSampleCount,
      searchString,
      workflowSchema,
      getJobParameters,
      formatDateTime,
    };
  },
});
