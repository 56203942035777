export enum TypedGroupType {
  LAB_USERS = 'LAB_USERS',
}

export interface TypedGroup {
  groupId: string;
  labId: string;
  name: string;
  type: TypedGroupType;
  members: string[];
}

export enum TypedGroupChangeType {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export interface TypedGroupChange {
  changeType: TypedGroupChangeType;
  groupId: string;
  entityId: string;
  timestamp: string;
  changedBy: string;
}

export enum TypedGroupErrors {
  GROUP_ALREADY_EXISTS = 'GROUP_ALREADY_EXISTS',
  GROUP_NOT_FOUND = 'GROUP_NOT_FOUND',
  MEMBER_ALREADY_IN_GROUP = 'MEMBER_ALREADY_IN_GROUP',
  MEMBER_NOT_IN_GROUP = 'MEMBER_NOT_IN_GROUP',
  GROUP_TYPE_MISMATCH = 'GROUP_TYPE_MISMATCH',
  INVALID_GROUP_TYPE = 'INVALID_GROUP_TYPE',
}

export const ADMIN_GROUP_ID = 'typedgroup_5267eec6-ab45-4db3-ae83-558c4c795570';
