import { createSchema, morphism } from 'morphism';
import { DateTime } from 'luxon';

export enum Recurs {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKDAYS = 'WEEKDAYS',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  ANUALLY = 'ANUALLY',
}

export enum EventOperationType {
  UNKNOWN = 'UNKNOWN',
  SCHEDULE_JOB = 'SCHEDULE_JOB',
}

export interface EventTemplateBackend {
  id: string;
  labId: string;
  startDate: string;
  startTime: string;
  endDate: string;
  operationDetails: string;
  operationType: EventOperationType;
  recurrenceType: Recurs;
  title: string;
  description: string;
  durationSecs: number;
}

export interface EventTemplate {
  id: string;
  labId: string;
  actionId: string;
  startDateTime: Date | null;
  recurs: Recurs | null;
  operationType: EventOperationType;
  title?: string;
  description?: string;
}

export function nullEventTemplate(): EventTemplate {
  return {
    id: '',
    labId: '',
    actionId: '',
    startDateTime: null,
    recurs: null,
    operationType: EventOperationType.UNKNOWN,
  };
}

/**
 * Schema to map EventTemplateBackend (BE representation) to EventTemplate (FE representation)
 */
const mapFromSchema = createSchema<EventTemplate, EventTemplateBackend>({
  id: 'id',
  labId: 'labId',
  actionId: (input) => {
    try {
      const details = JSON.parse(input.operationDetails);
      if (details.workflowId) {
        return details.workflowId;
      }
      return '';
    } catch {
      return '';
    }
  },
  startDateTime: (input) => {
    return new Date(`${input.startDate}T${input.startTime}`);
  },
  recurs: 'recurrenceType',
  operationType: 'operationType',
  title: 'title',
  description: 'description',
});

/**
 * Schema to map EventTemplate (FE representation) to EventTemplateBackend (BE representation)
 */
const mapToSchema = createSchema<EventTemplateBackend, EventTemplate>({
  id: 'id',
  labId: 'labId',
  startDate: (input) => {
    if (input.startDateTime) {
      const dt = DateTime.fromJSDate(input.startDateTime);
      return dt.toISODate() || '';
    }
    return '';
  },
  startTime: (input) => {
    if (input.startDateTime) {
      const dt = DateTime.fromJSDate(input.startDateTime);
      return (
        dt.toISOTime({
          suppressMilliseconds: true,
          includeOffset: false,
          extendedZone: false,
        }) || ''
      );
    }
    return '';
  },
  operationDetails: (input) => {
    return JSON.stringify({ workflowId: input.actionId });
  },
  operationType: 'operationType',
  recurrenceType: 'recurs',
  endDate: () => '',
  title: 'title',
  description: 'description',
  durationSecs: () => 1,
});

export function mapFromBackend(
  eventTemplate: EventTemplateBackend
): EventTemplate {
  return morphism(mapFromSchema, eventTemplate);
}

export function mapToBackend(
  eventTemplate: EventTemplate
): EventTemplateBackend {
  return morphism(mapToSchema, eventTemplate);
}
