
import { defineComponent, onMounted, ref, Ref, PropType } from 'vue';

// interface Props {
//   initialValue: string[] | number[];
//   type: 'string' | 'number' | 'integer';
// }

export default defineComponent({
  props: {
    initialValue: {
      type: Array as PropType<string[] | number[]>,
      required: true,
    },
    type: String,
  },
  setup(props, { emit }) {
    const arrayValue: Ref<string> = ref('');
    onMounted(() => {
      if (props.initialValue) {
        arrayValue.value = props.initialValue
          .map((v) => v.toString())
          .join(',');
      }
    });

    return {
      arrayValue,
      filterInput(event: KeyboardEvent) {
        if (props.type === 'number' || props.type === 'integer') {
          let numerics = '1234567890,';
          if (props.type === 'number') {
            numerics += '.';
          }
          const key = event.key;
          if (!numerics.includes(key)) {
            event.preventDefault();
          }
        }
      },
      updateArray() {
        const arr = arrayValue.value
          .replaceAll('[', '')
          .replaceAll(']', '')
          .replaceAll('"', '')
          .replaceAll("'", '')
          .split(',')
          .map((v) => v.trim());
        if (props.type === 'integer') {
          const newArrayVal = arr.map((i) => Number.parseInt(i));
          emit('update:array', newArrayVal);
        } else if (props.type === 'number') {
          const newArrayVal = arr.map((n) => Number.parseFloat(n));
          emit('update:array', newArrayVal);
        } else {
          emit('update:array', arr);
        }
      },
    };
  },
});
