export function hostname(serviceUrl = '', isWs = false): string {
  if (serviceUrl.includes('://')) return serviceUrl;
  return `${isWs ? 'wss:' : ''}//${
    process.env.NODE_ENV === 'production' ? location.host : ''
  }${serviceUrl}`;
}

export const AUTH_URL =
  RUNTIME_AUTH_URL === '{{ RUNTIME_AUTH_URL }}'
    ? process.env.VUE_APP_AUTH_URL
    : RUNTIME_AUTH_URL;

export const COGNITO_CLIENT_ID =
  RUNTIME_COGNITO_CLIENT_ID === '{{ RUNTIME_COGNITO_CLIENT_ID }}'
    ? process.env.VUE_APP_COGNITO_CLIENT_ID
    : RUNTIME_COGNITO_CLIENT_ID;

export const COGNITO_IDENTITY_PROVIDER =
  RUNTIME_COGNITO_IDENTITY_PROVIDER ===
  '{{ RUNTIME_COGNITO_IDENTITY_PROVIDER }}'
    ? process.env.VUE_APP_COGNITO_IDENTITY_PROVIDER
    : RUNTIME_COGNITO_IDENTITY_PROVIDER;

export const DEPLOYMENT_ORG_ID =
  RUNTIME_DEPLOYMENT_ORG_ID === '{{ RUNTIME_DEPLOYMENT_ORG_ID }}'
    ? process.env.VUE_APP_DEPLOYMENT_ORG_ID
    : RUNTIME_DEPLOYMENT_ORG_ID;

export const LOGOUT_REDIRECT_URI =
  RUNTIME_LOGOUT_REDIRECT_URI === '{{ RUNTIME_LOGOUT_REDIRECT_URI }}'
    ? process.env.VUE_APP_LOGOUT_REDIRECT_URI
    : RUNTIME_LOGOUT_REDIRECT_URI;

export const ADAPTER_DEPLOYMENTS =
  RUNTIME_ADAPTER_DEPLOYMENTS === '{{ RUNTIME_ADAPTER_DEPLOYMENTS }}'
    ? process.env.VUE_APP_ADAPTER_DEPLOYMENTS
    : RUNTIME_ADAPTER_DEPLOYMENTS;
