import { reactive } from 'vue';
import * as Service from './service';
import { Token } from './model';

export default class Controller {
  private static instance: Controller;
  private state: { tokens: Token[] };

  private constructor() {
    this.state = reactive({ tokens: [] });
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  // ACTIONS / MUTATIONS

  /**
   * Create a new token
   * MUTATION
   * @param name token name
   * @returns hash of the new token
   */
  async dispatchNewToken(name: string) {
    // do some client side data checking
    const response = await Service.createNewToken(name);
    this.state.tokens = [...this.state.tokens, response.token];
    return response.hash;
  }

  async dispatchListTokens() {
    if (this.state.tokens.length === 0) {
      const tokens = await Service.listTokens();
      this.state.tokens = tokens;
    }
    return this.state.tokens;
  }

  /**
   * Delete a token
   * MUTATION/IDEMPOTENT
   * @param id the ID of the token to delete
   * @returns the new list of tokens
   */
  async dispatchDeleteToken(id: string) {
    await Service.deleteToken(id);
    this.state.tokens = this.state.tokens.filter((token) => token.id !== id);
    return this.state.tokens;
  }

  get tokensList(): Token[] {
    return this.state.tokens;
  }
}
