
import { defineComponent, Ref, ref, PropType } from 'vue';

interface Category {
  display: string;
  name: string;
  image: string;
}

export default defineComponent({
  props: {
    categories: {
      type: Array as PropType<Category[]>,
      required: true,
    },
    fontInter: Boolean,
  },
  setup(props, { emit }) {
    const activeCategory: Ref<Category> = ref(props.categories[0] || {});
    const isActive = (tabName) => {
      return tabName === activeCategory.value.name;
    };

    const handleClick = (clickedTab) => {
      activeCategory.value = clickedTab;
      emit('setCategory', clickedTab);
    };

    return {
      activeCategory,
      isActive,
      handleClick,
    };
  },
});
