import { ApolloClient, HttpLink, from } from '@apollo/client/core';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { split } from '@apollo/client/link/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { RetryLink } from '@apollo/client/link/retry';
import { InMemoryCache } from '@apollo/client/cache';
import { hostname } from './utils';
import UIController from '@/clients/ui/controller';
import NotificationsController from '@/clients/notifications/controller';
import {
  DefinitionNode,
  ExecutionResult,
  GraphQLError,
  OperationDefinitionNode,
} from 'graphql';
import OpsController from '@/clients/ops';
import TimerLink from './timerLink';
import ProtectionLink from './protectionLink';

const httpLink = new HttpLink({
  uri: hostname(process.env.VUE_APP_GRAPHQL_URL),
  credentials: 'include',
});

const timerLink = new TimerLink();
const protectionLink = new ProtectionLink();

const retryIf = (error, _operations) => {
  const doNotRetryCodes = [403, 404, 502, 503];

  if (
    error.statusCode === 504 &&
    (_operations.operationName === 'ScheduleJob' ||
      _operations.operationName === 'UnscheduleJobs' ||
      _operations.operationName === 'ProgramInfo')
  ) {
    // WE NEED A BETTER SOLUTION THAN THIS THAT INCLUDES
    // WORK FROM THE BACKEND TO LET US KNOW THE REAL
    // STATUS OF THE SCHEDULER IF IT WILL TAKE FOREVER
    // TO GENERATE AS SCHEDULE.
    NotificationsController.Instance.addNewToast(
      `The scheduler is taking longer than expected.`,
      _operations.variables.labId,
      false
    );
    return false;
  }
  return !!error && !doNotRetryCodes.includes(error.statusCode);
};

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf,
  },
});

const handleGQLErrorMessage = (error: GraphQLError): string => {
  if (error.extensions?.exception?.code === 'ECONNREFUSED') {
    return `There was a problem connecting to ${error.extensions?.exception?.config?.url}`;
  }
  return error.message;
};

const MAX_ERROR_HISTORY = 40;

export interface ErrorPayload {
  query: DefinitionNode[];
  response: ExecutionResult;
  errorMessage: string;
  timestamp: string;
}

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    const def = operation.query.definitions.find(
      (d) => d.kind === 'OperationDefinition' && d.operation
    ) as OperationDefinitionNode;
    let newErrorPayload: ErrorPayload[] = [];
    const context = operation.getContext();
    if (
      graphQLErrors &&
      !context.ignoreErrorNotifications &&
      (def?.operation === 'mutation' || UIController.Instance.expert)
    ) {
      UIController.Instance.notifyError(
        'Something went wrong',
        handleGQLErrorMessage(graphQLErrors[0]),
        true
      );

      newErrorPayload = [
        {
          query: [...operation.query.definitions],
          response: response || {},
          errorMessage: handleGQLErrorMessage(graphQLErrors[0]),
          timestamp: new Date().toISOString(),
        },
      ];
    } else if (networkError) {
      UIController.Instance.notifyError(
        'Network Error',
        UIController.Instance.expert
          ? networkError.message
          : 'Check your network connection',
        true
      );
      newErrorPayload = [
        {
          query: [...operation.query.definitions],
          response: response || {},
          errorMessage: networkError.message || networkError.name,
          timestamp: new Date().toISOString(),
        },
      ];
    }
    if (newErrorPayload.length) {
      const currentErrorPayload = localStorage.getItem('gqlErrorPayload');
      if (currentErrorPayload) {
        newErrorPayload = [
          ...newErrorPayload,
          ...JSON.parse(currentErrorPayload),
        ];
      }
      // set a limit on number of errors to store or we'll eventually run out of space
      newErrorPayload = newErrorPayload.slice(0, MAX_ERROR_HISTORY);

      localStorage.setItem('gqlErrorPayload', JSON.stringify(newErrorPayload));
    }
  }
);

const apollo = new ApolloClient({
  link: from([protectionLink, timerLink, retryLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Action: {
        fields: {
          timelog: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  },
});

function connectWs() {
  // Don't open the websocket until the user has been authenticated and the artificial-org cookie has been set.
  const wsClient = new SubscriptionClient(
    hostname(process.env.VUE_APP_GRAPHQL_WS_URL, true),
    {
      reconnect: true,
    }
  );
  wsClient.onDisconnected(() => {
    console.log(
      'GraphQL subscriptions: websocket disconnected',
      wsClient.operations
    );
  });
  wsClient.onReconnecting(() => {
    console.log(
      'GraphQL subscriptions: websocket reconnecting',
      wsClient.operations
    );
    // for the job events subscription, modify the cursor timestamp so we don't
    // replay events we've already received.
    const ops = Object.values(wsClient.operations);
    const subscription = ops.find(
      (o) => o.options.operationName === 'JobEventsSubscription'
    );
    if (subscription?.options?.variables?.['eventsAfter']) {
      // first try to get the "correct" timestamp cursor from OpsController
      const cursor =
        OpsController.Instance.getJobEventsSubscriptionCursor(
          subscription.options.variables['jobId'] || ''
        ) || new Date().toISOString();
      subscription.options.variables['eventsAfter'] = cursor;
    }
  });
  wsClient.onReconnected(() => {
    console.log(
      'GraphQL subscriptions: websocket reconnected',
      wsClient.operations
    );
  });
  const wsLink = new WebSocketLink(wsClient);

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  apollo.setLink(
    from([protectionLink, timerLink, retryLink, errorLink, splitLink])
  );
}

export { apollo, connectWs };
