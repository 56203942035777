
import { defineComponent } from 'vue';
import TokenController from '@/clients/tokens';
import CreationModal from './CreationModal.vue';
import Leftbar from '../../navbar/Leftbar.vue';

export default defineComponent({
  data() {
    return {
      showModal: false,
    };
  },
  components: {
    Leftbar,
    CreationModal,
  },
  computed: {
    tokens() {
      return TokenController.Instance.tokensList;
    },
  },
  setup() {
    return {
      title: 'Token Management',
    };
  },
  methods: {
    showCreationModal() {
      this.showModal = true;
    },
    copyToClipboard(string) {
      navigator.clipboard.writeText(string);
    },
    async createToken(name) {
      await TokenController.Instance.dispatchNewToken(name);
    },
    async deleteToken(id) {
      await TokenController.Instance.dispatchDeleteToken(id);
    },
  },
});
