
import { uniqBy } from 'lodash';
import { defineComponent, computed, ref, Ref, ComputedRef } from 'vue';
import { Router } from 'vue-router';
import { Job } from '@/clients/ops';
import { SimplifiedState } from '@/clients/model';
import OpsController from '@/clients/ops';
import { getJobStateDisplayString } from '@/pages/utils';
import { JOB_DETAILS_ROUTE } from '@/router/names';
import Attachments from './Attachments.vue';
import Subjobs from './Subjobs.vue';
import IoList from './IoList.vue';
import ErrorsAndWarnings from './ErrorsAndWarnings.vue';
import RecentActivity from './RecentActivity.vue';
import { getStartTimeFromTimeLog, getEndTimeFromTimeLog } from '../utils';
import UserController from '@/clients/users/controller';
import { useRouter } from 'vue-router';

function setupGeneralInfo(job: ComputedRef<Job>) {
  // const workflow = computed(() => {
  //   return WorkflowController.Instance.getWorkflow(job.value.workflowid);
  // });

  // function getProtocolName(id: string): string {
  //   return (
  //     ProtocolController.Instance.getProtocol(id).name || 'unknown protocol'
  //   );
  // }

  function getLabName(id: string): string {
    return OpsController.Instance.getLab(id).name || 'unknown lab';
  }

  const hasTimestamps = computed(() => {
    return (
      getStartTimeFromTimeLog(job.value) || getEndTimeFromTimeLog(job.value)
    );
  });

  const labName = computed(() => {
    return getLabName(job.value.labId);
  });

  const userName = computed(() => {
    return (
      UserController.Instance.getUser(job.value.common.createdBy)?.name ||
      'unknown user'
    );
  });

  return {
    // workflow,
    // getProtocolName,
    labName,
    userName,
    hasTimestamps,
    getStartTimeFromTimeLog,
    getEndTimeFromTimeLog,
  };
}

function setupSubjobs(job: ComputedRef<Job>, router: Router) {
  const containedJobs = computed(() => job.value.children);

  function goToSubJob(subJobId: string) {
    router?.push({
      name: JOB_DETAILS_ROUTE,
      params: { jobId: subJobId },
    });
  }

  return {
    containedJobs,
    goToSubJob,
  };
}

function setupIos(job: ComputedRef<Job>, activeTab: Ref<string>) {
  const selectedInputs: Ref<string[]> = ref([]);
  const selectedOutputs: Ref<string[]> = ref([]);

  const allInputs = computed(() => {
    if (job.value.inputs) {
      const jobInputs = job.value.inputs.filter((input) => !!input.name);
      return uniqBy(jobInputs, 'id');
    }
    return [];
  });

  const allOutputs = computed(() => {
    if (job.value.outputs) {
      const jobOutputs = job.value.outputs.filter((output) => !!output.name);
      return uniqBy(jobOutputs, 'id');
    }
    return [];
  });

  function selectAction(actionId: string) {
    const action = job.value.actions?.find((a) => a.id === actionId);
    if (action) {
      selectedInputs.value = action?.inputs?.map((i) => i.id) || [];
      selectedOutputs.value = action?.outputs?.map((o) => o.id) || [];
      if (selectedInputs.value.length > 0 || selectedOutputs.value.length > 0) {
        if (selectedInputs.value.length >= selectedOutputs.value.length) {
          activeTab.value = 'inputs';
        } else {
          activeTab.value = 'outputs';
        }
      }
    } else {
      selectedInputs.value = [];
      selectedOutputs.value = [];
    }
  }

  return {
    allInputs,
    allOutputs,
    selectAction,
    selectedInputs,
    selectedOutputs,
  };
}

function setupStatus(job: ComputedRef<Job>) {
  const attachments = computed(() => {
    return [];
    // const entities = job.value.outputsList.flatMap((o) => o.entitiesList);
    // const entitiesWithFiles = entities.filter((e) => e.fileinstance);
    // const fileInstances = entitiesWithFiles.map((ef) => ef.fileinstance);
    // return fileInstances;
  });

  // const hasErrorsOrWarnings = computed(() => {
  //   return !!job.value.errorsorwarningsList?.length;
  // });

  const numWarnings = computed(() => 0);

  const numErrors = computed(() => 0);

  const statusClass = computed(() => {
    let icon, color, tagType;
    if (
      job.value.state === SimplifiedState.FINISHED
      // || job.value.state === JobState.UNLOADED
    ) {
      icon = 'icon-checked';
      color = 'job-success';
      tagType = 'success';
      // } else if (job.value.state === JobState.CANCELLED) {
      //   icon = 'icon-cross';
      //   color = 'job-failure';
      //   tagType = 'danger';
    } else if (job.value.state === SimplifiedState.INITIALIZED) {
      icon = 'icon-queued';
      color = 'job-success';
      tagType = 'success';
    } else if (job.value.state === SimplifiedState.RUNNING) {
      icon = 'icon-queue-active';
      color = 'job-success';
      tagType = 'success';
    } else {
      icon = 'icon-queue-pending';
      color = 'job-incomplete';
      tagType = 'info';
    }

    // if (hasErrorsOrWarnings.value) {
    //   if (job.value.errorsorwarningsList.some((e) => e.error)) {
    //     color = 'job-failure';
    //     tagType = 'danger';
    //   } else {
    //     color = 'job-warning';
    //     tagType = 'warning';
    //   }
    // }

    return { color, icon, tagType };
  });

  return {
    attachments,
    // hasErrorsOrWarnings,
    numWarnings,
    numErrors,
    statusClass,
    getJobStateDisplayString,
  };
}

function setupActivity(job: ComputedRef<Job>) {
  const allDisplayableActions = computed(() => {
    const actions = OpsController.Instance.getActionsByJob(job.value.id).filter(
      (a) =>
        // a.state !== SimplifiedState.UNKNOWN &&
        // a.state !== SimplifiedState.CREATED &&
        a.name
    );
    return actions;
  });

  return {
    allDisplayableActions,
  };
}

export default defineComponent({
  props: {
    jobId: {
      type: String,
      required: true,
    },
  },
  components: {
    Attachments,
    Subjobs,
    IoList,
    ErrorsAndWarnings,
    RecentActivity,
  },
  setup(props) {
    OpsController.Instance.dispatchGetJobDetailsData(props.jobId);
    const router = useRouter();

    const superJob = computed(() => {
      const r = OpsController.Instance.getJob(props.jobId);
      return r;
    });
    const activeTab = ref('jobs');

    return {
      superJob,
      activeTab,
      ...setupGeneralInfo(superJob),
      ...setupSubjobs(superJob, router),
      ...setupIos(superJob, activeTab),
      ...setupStatus(superJob),
      ...setupActivity(superJob),
    };
  },
});
