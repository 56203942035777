import { createApp } from 'vue';
import { watch } from 'vue';
import AuthController from '@/clients/auth';
import TokenController from '@/clients/tokens';
import UIController from '@/clients/ui';
import OpsController from '@/clients/ops';
import { loadWorld } from '@/clients';
import { createI18n } from 'vue-i18n';
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import 'element-plus/dist/index.css';
import VueHeadful from 'vue-headful';
import 'element-plus/dist/index.css';
import { getBrowserLocales } from './utils';

import router from './router';
import App from './App.vue';
import './config';
import messages from './i18n/index';
import { DashboardFeatureFlag } from './clients/model';

// Vue.config.productionTip = false;
const i18n = createI18n({
  locale: getBrowserLocales(),
  fallback: 'en',
  legacy: false,
  messages,
});

const hideGanttChart =
  RUNTIME_HIDE_GANTT_CHART === '{{ RUNTIME_HIDE_GANTT_CHART }}'
    ? !!process.env.VUE_APP_HIDE_GANTT_CHART
    : !!RUNTIME_HIDE_GANTT_CHART;

if (hideGanttChart) {
  console.log('gantt chart is hidden');
  UIController.Instance.displayGanttChart = false;
} else {
  console.log('gantt chart is visible');
}

const legacySkipRetry =
  RUNTIME_LEGACY_SKIP_RETRY === '{{ RUNTIME_LEGACY_SKIP_RETRY }}'
    ? !!process.env.VUE_APP_LEGACY_SKIP_RETRY
    : !!RUNTIME_LEGACY_SKIP_RETRY;
OpsController.Instance.legacySkipRetry = legacySkipRetry;

const featureFlags =
  (RUNTIME_ART_FEATURE_FLAGS === '{{ RUNTIME_ART_FEATURE_FLAGS }}'
    ? process.env.VUE_APP_ART_FEATURE_FLAGS
    : RUNTIME_ART_FEATURE_FLAGS
  )?.split(',') || [];
UIController.Instance.featureFlags = featureFlags as DashboardFeatureFlag[];

if (featureFlags) {
  console.log(`feature flags: ${featureFlags}`);
}

// AuthController needs access to router to initiate route changes
// when user logs in or out.
AuthController.Instance.router = router;

const app = createApp(App);
AuthController.Instance.dispatchSetUser().then(() => {
  app.use(router).use(ElementPlus).use(i18n);

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
  }

  app.component('vue-headful', VueHeadful).mount('#app');
});

watch(
  () => AuthController.Instance.loggedIn,
  (isLoggedIn) => {
    if (isLoggedIn) {
      loadWorld();
      TokenController.Instance.dispatchListTokens();
    }
  },
  { immediate: true }
);
