
import { defineComponent, computed, ref, Ref, PropType } from 'vue';
import moment from 'moment';
import { uniq } from 'lodash';
import { formatDateTime } from '@/pages/utils';
import OpsController, { Job } from '@/clients/ops';
import UserController from '@/clients/users';
import SearchBar from '@/components/SearchBar.vue';
// @ts-ignore
import StateTag, { StateTagSize } from '@/components/StateTag.vue';
import AssistantDetails from './AssistantDetails.vue';
import { Action, ActionType } from '@/clients/action/model';

export default defineComponent({
  components: {
    SearchBar,
    StateTag,
    AssistantDetails,
  },
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true,
    },
  },
  setup(props) {
    const getDuration = (start, completed) => {
      if (start && completed) {
        return moment.duration(moment(completed).diff(moment(start)));
      }
      return 0;
    };

    const formatDuration = (duration) => {
      if (duration === 0) {
        return '--';
      } else if (duration.asDays() > 1) {
        const days = Math.ceil(duration.asDays());
        const hours = Math.ceil(duration.asHours() % 24);
        const minutes = Math.ceil(duration.asMinutes() % (24 * 60));
        return `${days} days, ${hours} hours, ${minutes} minutes`;
      } else if (duration.asHours() > 1) {
        const hours = Math.ceil(duration.asHours() % 24);
        const minutes = Math.ceil(duration.asMinutes() % (24 * 60));
        return `${hours} hours, ${minutes} minutes`;
      }
      const minutes = Math.ceil(duration.asMinutes());
      return `${minutes} minutes`;
    };

    const getUser = (userId) => {
      if (userId) {
        return UserController.Instance.getUser(userId).name;
      }
      return '--';
    };

    const assistants = computed(() => {
      let asstList: Action[] = [];
      if (props.job?.action?.actionType === ActionType.ASSISTANT) {
        asstList = [props.job.action];
      } else {
        asstList = OpsController.Instance.getActionsByJob(
          props.job?.id || ''
        ).filter((a) => a.actionType === ActionType.ASSISTANT);
      }

      return asstList?.map((a) => {
        return {
          ...a,
          completedBy: getUser(a.completedBy),
          start: formatDateTime(a.timelog?.actual?.startTimestamp || '')
            .datetime,
          completed: formatDateTime(a.timelog?.actual?.endTimestamp || '')
            .datetime,
          duration: getDuration(
            a.timelog?.actual?.startTimestamp,
            a.timelog?.actual?.endTimestamp
          ),
        };
      });
    });

    const users = computed(() => {
      if (assistants.value) {
        const uniqueUsers = uniq(assistants.value.map((a) => a.completedBy));
        return ['All', ...uniqueUsers];
      }
      return [];
    });

    const assistantsFilterText = ref('');

    const stateFilter = ref('ALL');
    const stateFilterOpts = [
      { label: 'All', value: 'ALL' },
      { label: 'Pending', value: 'CREATED' },
      { label: 'Scheduled', value: 'INITIALIZED' },
      { label: 'Running', value: 'RUNNING' },
      { label: 'Canceled', value: 'CANCELLED' },
      { label: 'Completed', value: 'FINISHED' },
    ];

    const startDateFilter = ref('');
    const endDateFilter = ref('');

    const completedBy = ref('All');

    const filteredAssistants = computed(() => {
      let filtered = assistants.value || [];

      if (assistantsFilterText.value.length > 0) {
        filtered = filtered.filter((a) =>
          a.name
            .toLowerCase()
            .includes(assistantsFilterText.value.toLowerCase())
        );
      }

      if (stateFilter.value !== '' && stateFilter.value !== 'ALL') {
        filtered = filtered.filter((j) => j.state === stateFilter.value);
      }

      if (completedBy.value !== 'All' && completedBy.value !== '') {
        filtered = filtered.filter((j) => j.completedBy === completedBy.value);
      }

      if (startDateFilter.value) {
        filtered = filtered.filter((j) => {
          if (j.start) {
            const jDate = new Date(j?.start).getDate();
            const sDate = new Date(startDateFilter.value).getDate();
            return jDate === sDate;
          }
        });
      }

      if (endDateFilter.value) {
        filtered = filtered.filter((j) => {
          if (j.completed) {
            const jDate = new Date(j?.completed).getDate();
            const sDate = new Date(endDateFilter.value).getDate();
            return jDate === sDate;
          }
        });
      }

      if (sortAscending.value) {
        filtered.sort((a, b) =>
          a[sortColumn.value].localeCompare(b[sortColumn.value])
        );
      } else {
        filtered.sort((a, b) =>
          b[sortColumn.value].localeCompare(a[sortColumn.value])
        );
      }

      return filtered;
    });

    const sortAscending = ref(true);
    const sortColumn = ref('name');
    const setSortValues = (column) => {
      if (column === sortColumn.value) {
        sortAscending.value = !sortAscending.value;
      } else {
        sortColumn.value = column;
        sortAscending.value = true;
      }
    };

    const sortDirection = (column) => {
      if (column === sortColumn.value && sortAscending.value === false) {
        return 'descending';
      }
      return 'ascending';
    };

    const selectedAssistant: Ref<Action | null> = ref(null);
    const selectAssistant = (assistant) => {
      if (
        selectedAssistant.value &&
        assistant &&
        selectedAssistant.value.id === assistant.id
      ) {
        selectedAssistant.value = null;
      } else {
        selectedAssistant.value = assistant;
      }
    };

    return {
      formatDuration,
      filteredAssistants,
      users,
      startDateFilter,
      completedBy,
      endDateFilter,
      assistantsFilterText,
      stateFilter,
      stateFilterOpts,
      sortAscending,
      sortDirection,
      setSortValues,
      selectAssistant,
      selectedAssistant,
      StateTagSize,
    };
  },
});
