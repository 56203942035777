import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ac73d7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "category-selector" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass([
        'category',
        { selected: _ctx.isActive(category.name), inter: _ctx.fontInter },
      ]),
        key: category.name,
        onClick: ($event: any) => (_ctx.handleClick(category))
      }, [
        (category.image)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "category-icon",
              src: category.image
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(category.display), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}