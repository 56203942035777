
import { defineComponent, ref, PropType } from 'vue';

interface MenuValue {
  label: string;
  value: string;
}

// interface Props {
//   menuValues: [MenuValue];
//   expand: boolean;
//   border: boolean;
// }

export default defineComponent({
  props: {
    menuValues: {
      type: Array as PropType<MenuValue[]>,
      required: false,
    },
    border: {
      type: Boolean,
      default: true,
    },
    expand: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const searchInput = ref();
    const isInputVisbile = ref(false);
    const isMenuVisible = ref(
      props.menuValues && props?.menuValues?.length > 0
    );
    const inputValue = ref('');
    const menuValue = ref(
      props.menuValues && props.menuValues.length > 0
        ? props.menuValues[0].label
        : ''
    );

    const focusOnInput = () => {
      if (props.menuValues) {
        if (!isInputVisbile.value) {
          searchInput.value.focus();
        }
        if (!inputValue.value) {
          isInputVisbile.value = !isInputVisbile.value;
        }
      } else if (searchInput.value) {
        searchInput.value.focus();
      }
    };

    const toggleVisibility = () => {
      const status = isInputVisbile.value;
      if (!props.menuValues) {
        if (status && !inputValue.value) {
          isInputVisbile.value = false;
        } else if (!status) {
          isInputVisbile.value = true;
        }
      }
    };

    const inputUpdate = () => {
      let filterType = '';
      if (props.menuValues) {
        filterType =
          props.menuValues.find((v) => v.label === menuValue.value)?.value ||
          '';
      }
      emit('searchBarUpdate', inputValue.value, filterType);
    };

    const menuUpdate = (filterType: string) => {
      menuValue.value =
        props.menuValues?.find((v) => v.value === filterType)?.label || '';
      emit('searchBarUpdate', inputValue.value, filterType);
    };

    return {
      focusOnInput,
      toggleVisibility,
      inputUpdate,
      isMenuVisible,
      menuUpdate,
      inputValue,
      menuValue,
      searchInput,
      isInputVisbile,
    };
  },
});
