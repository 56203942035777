
import { defineComponent, computed, ref } from 'vue';
import OpsController from '@/clients/ops/controller';
import Banner from './components/Banner.vue';
import SummaryContent from './Summary/Summary.vue';
import ResourcesContent from './Resources/Resources.vue';
import ResultsContent from './Results/Results.vue';
import { useRoute } from 'vue-router';
export default defineComponent({
  components: {
    Banner,
    SummaryContent,
    ResourcesContent,
    ResultsContent,
  },
  setup() {
    const route = useRoute();
    let jobId = Array.isArray(route.params.jobId)
      ? route.params.jobId[0]
      : route.params.jobId;
    OpsController.Instance.dispatchGetJobDetailsData(jobId);
    const job = computed(() => OpsController.Instance.getJob(jobId));

    const dataRecordTabs = ['SUMMARY', 'RESOURCES', 'RESULTS'];
    const selectedDataRecordTab = ref('SUMMARY');
    const updateSelectedDataRecordTab = (tabName) => {
      selectedDataRecordTab.value = tabName;
    };

    return {
      job,
      dataRecordTabs,
      selectedDataRecordTab,
      updateSelectedDataRecordTab,
    };
  },
});
