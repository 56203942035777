import axios from 'axios';
import { hostname } from '../utils';

export function reset() {
  // return service.resetAll(new Empty());
}

export async function importLab(lab: File) {
  const fd = new FormData();
  fd.append('lab', lab);
  return axios.post(
    hostname(process.env.VUE_APP_DATA_SERVICE_URL) + '/import-lab',
    fd,
    {
      withCredentials: true,
      headers: { 'content-type': 'application/octet-stream' },
    }
  );
}

export async function exportLab(id: string) {
  const searchParams = new URLSearchParams();
  searchParams.append('id', id);
  searchParams.append('excludeops', 'true');
  const href =
    hostname(process.env.VUE_APP_DATA_SERVICE_URL) +
    '/export-lab?' +
    searchParams.toString();
  const res = await axios.get(href, { withCredentials: true });
  return res.data;
}

export async function importWorkflows(actions: File) {
  const fd = new FormData();
  fd.append('workflows', actions);
  return axios.post(
    hostname(process.env.VUE_APP_DATA_SERVICE_URL) + '/import-workflows',
    fd,
    {
      withCredentials: true,
      headers: { 'content-type': 'application/octet-stream' },
    }
  );
}

export async function exportWorkflows(id?: string) {
  let href =
    hostname(process.env.VUE_APP_DATA_SERVICE_URL) + '/export-workflows';
  if (id) {
    const searchParams = new URLSearchParams();
    searchParams.append('id', id);
    href += `?${searchParams.toString()}`;
  }
  const res = await axios.get(href, { withCredentials: true });
  return res.data;
}
