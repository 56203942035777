import { gql } from '@apollo/client/core';
import { apollo } from '../service.apollo';
import {
  ConfigBackend,
  EntityConfiguration,
  ServiceVersion,
  mapFromBackend,
  mapToBackend,
  nullConfig,
} from './model';

const CONFIG_FIELDS = gql`
  fragment ConfigFields on ConfigValuesDocumentEnvelope {
    entityType
    entityId
    schemaVersion
    schemaTimestamp
    schemaDocument
    configValuesVersion
    configValuesTimestamp
    configValuesDocument
    labId
    orgId
    resolved
  }
`;

export async function getOrgConfig(): Promise<EntityConfiguration> {
  const result = await apollo.query<{
    getCurrentOrgConfiguration: ConfigBackend;
  }>({
    query: gql`
      query GetCurrentOrgConfiguration {
        getCurrentOrgConfiguration {
          ...ConfigFields
        }
      }
      ${CONFIG_FIELDS}
    `,
  });
  if (result.data?.getCurrentOrgConfiguration) {
    return mapFromBackend(result.data.getCurrentOrgConfiguration);
  }
  return nullConfig();
}

export async function getLabConfig(
  labId: string
): Promise<EntityConfiguration> {
  const result = await apollo.query<{
    getCurrentLabConfiguration: ConfigBackend;
  }>({
    variables: {
      labId,
    },
    query: gql`
      query GetCurrentLabConfiguration($labId: ID!) {
        getCurrentLabConfiguration(labId: $labId) {
          ...ConfigFields
        }
      }
      ${CONFIG_FIELDS}
    `,
  });
  if (result.data?.getCurrentLabConfiguration) {
    return mapFromBackend(result.data.getCurrentLabConfiguration);
  }
  return nullConfig();
}

export async function getWorkflowConfig(
  workflowId: string,
  labId: string
): Promise<EntityConfiguration> {
  const result = await apollo.query<{
    getCurrentWorkflowConfiguration: ConfigBackend;
  }>({
    variables: {
      labId,
      workflowId,
    },
    query: gql`
      query GetCurrentWorkflowConfiguration($labId: ID!, $workflowId: ID!) {
        getCurrentWorkflowConfiguration(
          labId: $labId
          workflowId: $workflowId
        ) {
          ...ConfigFields
        }
      }
      ${CONFIG_FIELDS}
    `,
  });
  if (result.data?.getCurrentWorkflowConfiguration) {
    return mapFromBackend(result.data.getCurrentWorkflowConfiguration);
  }
  return nullConfig();
}

export async function getJobConfig(
  jobId: string,
  labId: string
): Promise<EntityConfiguration> {
  const result = await apollo.query<{
    getCurrentJobConfiguration: ConfigBackend;
  }>({
    variables: { jobId, labId },
    query: gql`
      query GetCurrentJobConfiguration($labId: ID!, $jobId: ID!) {
        getCurrentJobConfiguration(labId: $labId, jobId: $jobId) {
          ...ConfigFields
        }
      }
      ${CONFIG_FIELDS}
    `,
  });
  if (result.data?.getCurrentJobConfiguration) {
    return mapFromBackend(result.data.getCurrentJobConfiguration);
  }
  return nullConfig();
}

export async function getAdapterConfig(
  labId: string,
  adapterId: string
): Promise<EntityConfiguration> {
  const result = await apollo.query<{
    getCurrentAdapterConfiguration: ConfigBackend;
  }>({
    variables: { labId, adapterId },
    query: gql`
      query GetCurrentAdapterConfiguration($labId: ID!, $adapterId: ID!) {
        getCurrentAdapterConfiguration(labId: $labId, adapterId: $adapterId) {
          ...ConfigFields
        }
      }
      ${CONFIG_FIELDS}
    `,
    context: { ignoreLongCalls: true },
  });
  if (result.data?.getCurrentAdapterConfiguration) {
    return mapFromBackend(result.data.getCurrentAdapterConfiguration);
  }
  return nullConfig();
}

export async function updateConfig(
  config: EntityConfiguration
): Promise<number | null> {
  const mappedConfig = mapToBackend(config);
  const result = await apollo.mutate<{
    updateConfigValues: { configValuesVersion: number };
  }>({
    variables: {
      configValuesDocument: mappedConfig.configValuesDocument,
      configValuesVersion: mappedConfig.configValuesVersion,
      entityId: mappedConfig.entityId,
      entityType: mappedConfig.entityType,
      schemaVersion: mappedConfig.schemaVersion,
    },
    mutation: gql`
      mutation UpdateConfigValues(
        $configValuesDocument: String!
        $configValuesVersion: Int!
        $entityId: ID!
        $entityType: EntityType!
        $schemaVersion: Int!
      ) {
        updateConfigValues(
          configValuesDocument: $configValuesDocument
          configValuesVersion: $configValuesVersion
          entityId: $entityId
          entityType: $entityType
          schemaVersion: $schemaVersion
        ) {
          configValuesVersion
        }
      }
    `,
  });
  if (result.data?.updateConfigValues) {
    return result.data.updateConfigValues.configValuesVersion;
  }
  return null;
}

export async function deleteConfig(
  config: EntityConfiguration
): Promise<boolean> {
  const result = await apollo.mutate<{ setEntitiesArchivedFlag: boolean }>({
    variables: {
      entityIds: [config.entityId],
      entityType: config.entityType,
      labId: config.labId,
      archivedFlag: true,
    },
    mutation: gql`
      mutation SetEntitiesArchivedFlag(
        $entityIds: [ID]!
        $entityType: EntityType!
        $labId: ID!
        $archivedFlag: Boolean
      ) {
        setEntitiesArchivedFlag(
          entityIds: $entityIds
          entityType: $entityType
          labId: $labId
          archivedFlag: $archivedFlag
        )
      }
    `,
  });
  if (result.data?.setEntitiesArchivedFlag) {
    return result.data.setEntitiesArchivedFlag;
  }
  return false;
}

export async function getServiceVersions(): Promise<ServiceVersion[]> {
  const result = await apollo.query<{ serviceVersions: ServiceVersion[] }>({
    query: gql`
      query GetServiceVersions {
        serviceVersions {
          name
          version
          semanticVersion {
            major
            minor
            patch
            build
          }
        }
      }
    `,
  });
  return result.data?.serviceVersions || [];
}
