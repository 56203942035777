
import { defineComponent, computed, ref, Ref, PropType } from 'vue';
import { uniq, uniqBy } from 'lodash';
import SearchBar from '@/components/SearchBar.vue';
// @ts-ignore
import StateTag from '@/components/StateTag.vue';
import MetaDataDialog from './MetaDataDialog.vue';
import ArrowLink from '@/components/ArrowLink.vue';
import { IO, IoType, UserField } from '@/clients/model';
import { getIoTypeDisplayString, getUnitDisplayString } from '@/pages/utils';

export default defineComponent({
  components: {
    SearchBar,
    StateTag,
    MetaDataDialog,
    ArrowLink,
  },
  props: {
    io: {
      type: Array as PropType<IO[]>,
      required: true,
    },
  },
  setup(props) {
    const ioList = computed(() => {
      return uniqBy(
        props.io.map((io) => {
          return {
            id: io.id,
            name: io.name,
            unit: io.actualquantity?.unit || '',
            value: io.actualquantity?.value || '',
            samples: io.samples,
            assets: io.assets,
            type: io.type,
            userFields: io.userFields || [],
          };
        }),
        'id'
      );
    });

    const ioFilterText = ref('');
    const valueFilter = ref('');

    const typeFilter = ref('ALL');
    const typeFilterOpts = [
      { label: 'All', value: 'ALL' },
      ...Object.values(IoType)
        .map((t) => ({
          label: getIoTypeDisplayString(t),
          value: t,
        }))
        .filter((o) => o.value !== IoType.OTHER_IO),
    ];

    const unitFilter = ref('ALL');
    const unitFilterOpts = computed(() => {
      return [
        { label: 'All', value: 'ALL' },
        ...uniq(ioList.value.map((io) => io.unit))
          .filter(Boolean)
          .map((u) => ({
            label: getUnitDisplayString(u),
            value: u,
          })),
      ];
    });

    const filteredIo = computed(() => {
      let filtered = ioList.value;

      if (ioFilterText.value.length > 0) {
        filtered = filtered.filter((a) =>
          a.name.toLowerCase().includes(ioFilterText.value.toLowerCase())
        );
      }

      if (typeFilter.value !== '' && typeFilter.value !== 'ALL') {
        filtered = filtered.filter((j) => j.type === typeFilter.value);
      }

      if (unitFilter.value !== '' && unitFilter.value !== 'ALL') {
        filtered = filtered.filter((j) => j.unit === unitFilter.value);
      }

      if (valueFilter.value.length > 0) {
        filtered = filtered.filter((a) =>
          a.value.toString().includes(valueFilter.value)
        );
      }

      if (sortAscending.value) {
        const opts = { numeric: false };
        filtered.sort((a, b) => {
          if (typeof a[sortColumn.value] === 'number') {
            opts.numeric = true;
          }
          return new Intl.Collator(undefined, opts).compare(
            a[sortColumn.value].toString().toString(),
            b[sortColumn.value].toString()
          );
        });
      } else {
        const opts = { numeric: false };
        filtered.sort((a, b) => {
          if (typeof b[sortColumn.value] === 'number') {
            opts.numeric = true;
          }
          return new Intl.Collator(undefined, opts).compare(
            b[sortColumn.value].toString(),
            a[sortColumn.value].toString()
          );
        });
      }

      return filtered;
    });

    const sortAscending = ref(true);
    const sortColumn = ref('name');
    const setSortValues = (column) => {
      if (column === sortColumn.value) {
        sortAscending.value = !sortAscending.value;
      } else {
        sortColumn.value = column;
        sortAscending.value = true;
      }
    };

    const sortDirection = (column) => {
      if (column === sortColumn.value && sortAscending.value === false) {
        return 'descending';
      }
      return 'ascending';
    };

    const expandedRow: Ref<string | null> = ref(null);
    const toggleRowExpansion = (rowId: string | null) => {
      const param = ioList.value.find((io) => io.id === rowId);
      if (rowId && expandedRow.value !== rowId && param?.samples?.length) {
        expandedRow.value = rowId;
      } else if ((rowId && expandedRow.value === rowId) || !rowId) {
        expandedRow.value = null;
      }
    };

    const selectedMetaData = ref(null);
    const setSelectedMetaData = (metadata) => {
      selectedMetaData.value = metadata;
    };

    const setSelectedMetaDataKV = (kvPairs: UserField[]) => {
      // this is a bit ugly, but we're massaging the nice k/v pairs that apollo gives us
      // back into stringified JSON so we can reuse the same metadata dialog.
      // in any case, using Io.metadatas wouldn't work either because those are an array
      // so I'd have to massage that data as well.
      const metadata = {};
      kvPairs?.forEach((kv) => {
        metadata[kv.key] = kv.value;
      });
      setSelectedMetaData(JSON.stringify(metadata));
    };

    return {
      filteredIo,
      ioFilterText,
      valueFilter,
      getIoTypeDisplayString,
      getUnitDisplayString,
      typeFilter,
      typeFilterOpts,
      unitFilter,
      unitFilterOpts,
      sortAscending,
      sortDirection,
      setSortValues,
      expandedRow,
      toggleRowExpansion,
      selectedMetaData,
      setSelectedMetaData,
      setSelectedMetaDataKV,
    };
  },
});
