
import { defineComponent, computed, ref, watch, Ref } from 'vue';
import { DateTime } from 'luxon';
import GanttChartVue from './GanttChartVue.vue';

export default defineComponent({
  components: {
    GanttChartVue,
  },
  props: {
    data: Array,
    customDate: Date,
    initialZoom: {
      type: Number,
      default: 3,
    },
  },
  setup(props) {
    const startDate: Ref<DateTime> = ref(DateTime.now().minus({ hour: 2 }));
    const endDate: Ref<DateTime> = ref(startDate.value.plus({ hour: 8 }));
    const zoomValues = [
      {
        id: 0,
        label: '1 Month',
        scaleValue: 210,
        rangeValue: 21,
        duration: 24 * 60 * 30,
      },
      {
        id: 1,
        label: '1 Week',
        scaleValue: 7,
        rangeValue: 21,
        duration: 24 * 60 * 7,
      },
      {
        id: 2,
        label: '1 day',
        scaleValue: 3,
        rangeValue: 21,
        duration: 24 * 60,
      },
      {
        id: 3,
        label: '8 hours',
        scaleValue: 1,
        rangeValue: 8,
        duration: 480,
      },
      {
        id: 4,
        label: '4 hours',
        scaleValue: 0.5,
        rangeValue: 8,
        duration: 240,
      },
      {
        id: 5,
        label: '2 hours',
        scaleValue: 0.25,
        rangeValue: 8,
        duration: 120,
      },
      {
        id: 6,
        label: '1 hour',
        scaleValue: 0.125,
        rangeValue: 8,
        duration: 60,
      },
      {
        id: 7,
        label: '30 minutes',
        scaleValue: 0.0625,
        rangeValue: 8,
        duration: 30,
      },
      {
        id: 8,
        label: '5 minutes',
        scaleValue: 0.0625 / 6,
        rangeValue: 8,
        duration: 5,
      },
    ];
    const selectedZoomId = ref(props.initialZoom);

    const calcDateDisplay = computed(() => {
      if (!props.customDate) {
        return '';
      }
      const diff =
        DateTime.fromJSDate(props.customDate).day - DateTime.now().day;

      if (
        DateTime.fromJSDate(props.customDate).hasSame(DateTime.now(), 'day')
      ) {
        return `Today`;
      } else if (diff === 1) {
        return 'Tomorrow';
      } else if (diff === -1) {
        return 'Yesterday';
      } else {
        return '';
      }
    });

    const jobDate = computed(() => {
      if (!props.customDate) {
        return '';
      }
      return DateTime.fromJSDate(props.customDate).toFormat('MMMM d, yyyy');
    });

    const shiftStartDate = (forward: boolean) => {
      const scaledDuration = zoomValues[selectedZoomId.value].scaleValue * 6;
      if (forward) {
        startDate.value = startDate.value.plus({ hours: scaledDuration });
        endDate.value = endDate.value.plus({ hours: scaledDuration });
      } else {
        startDate.value = startDate.value.minus({ hours: scaledDuration });
        endDate.value = endDate.value.minus({ hours: scaledDuration });
      }
    };

    const increment = () => {
      if (selectedZoomId.value < 7) {
        selectedZoomId.value = selectedZoomId.value + 1;
      }
    };

    const decrement = () => {
      if (selectedZoomId.value > 0) {
        selectedZoomId.value = selectedZoomId.value - 1;
      }
    };

    const moveToDate = (customDate?: string | Date | null) => {
      if (!customDate) {
        return '';
      }
      const date = customDate
        ? DateTime.fromJSDate(new Date(customDate))
        : DateTime.now();

      startDate.value = date.minus({
        hour: zoomValues[selectedZoomId.value].scaleValue * 2,
      });
      endDate.value = startDate.value.plus({
        minute: zoomValues[selectedZoomId.value].duration + 1,
      });
    };

    const handleEventZoom = (startTime: string) => {
      const newStart = DateTime.fromJSDate(new Date(startTime));
      if (newStart.isValid) {
        increment();
        startDate.value = newStart;
        moveToDate(startTime);
      }
    };

    const handleZoomSelection = (e) => {
      selectedZoomId.value = parseInt(e.target.value);
      moveToDate(props.customDate);
    };

    watch(
      () => props.customDate,
      (newDate) => {
        moveToDate(newDate);
      }
    );

    return {
      zoomValues,
      selectedZoomId,
      startDate,
      endDate,
      calcDateDisplay,
      shiftStartDate,
      jobDate,
      increment,
      decrement,
      moveToDate,
      handleEventZoom,
      handleZoomSelection,
    };
  },
});
