import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["title", "onClick"]
const _hoisted_2 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'top-filter-bar',
      _ctx.getSize(),
      {
        'no-border': !_ctx.border,
        'left-align': _ctx.leftAlign,
        transparent: _ctx.transparent,
      },
    ])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabNames, (tab) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass([
        'tab',
        {
          active: _ctx.isActive(tab),
          'left-align': _ctx.leftAlign,
          'white-text': _ctx.whiteText,
        },
      ]),
        title: tab,
        key: tab,
        onClick: ($event: any) => (_ctx.handleClick(tab))
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(tab), 1),
        _createElementVNode("div", {
          class: _normalizeClass(['bottom-bar', _ctx.getSize(), { active: _ctx.isActive(tab) }])
        }, null, 2)
      ], 10, _hoisted_1))
    }), 128))
  ], 2))
}