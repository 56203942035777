
import { defineComponent, computed } from 'vue';
import { Parser } from 'json2csv';
import { useRoute } from 'vue-router';
import {
  titleFromSchema,
  uiOptionsFromSchema,
} from '@/components/GenericForm/parse';
import OpsController from '@/clients/ops/controller';

export default defineComponent({
  setup() {
    const route = useRoute();
    const jobId = Array.isArray(route.params.jobId)
      ? route.params.jobId[0]
      : route.params.jobId;
    const property = (route.query['property'] as string) || null;
    OpsController.Instance.dispatchGetJobDetailsData(jobId);

    const job = computed(() => OpsController.Instance.getJob(jobId));

    const params = computed(() => {
      if (property && job.value?.action?.parameterValues) {
        try {
          const parsed = JSON.parse(job.value?.action?.parameterValues);
          return parsed[property];
        } catch (e) {
          console.error('There was a problem parsing the action schema', e);
          return;
        }
      }
      return;
    });

    const schema = computed(() => {
      if (property && job.value?.action?.schema) {
        try {
          const parsed = JSON.parse(job.value?.action?.schema);
          return parsed.properties[property];
        } catch (e) {
          console.error('There was a problem parsing the action schema', e);
          return {};
        }
      }
      return {};
    });

    const mappedValues = computed(() => {
      return params.value.map((row) => {
        const keys = Object.keys(schema.value.items.properties);
        const tableRow = {
          id: Math.floor(Math.random() * 999999),
          values: {},
        };
        keys.forEach((k) => {
          tableRow.values[k] = row[k] || '--';
        });
        return tableRow;
      });
    });

    function download(content, fileName, contentType) {
      const a = document.createElement('a');
      const file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
      a.remove();
    }

    const handleJSONDownload = () => {
      download(JSON.stringify(params.value), `${property}.json`, 'text/plain');
    };

    const handleCSVDownload = () => {
      const opts = { fields: Object.keys(schema.value.items.properties) };
      try {
        const parser = new Parser(opts);
        const csv = parser.parse(params.value);
        console.log(csv);
        download(csv, `${property}.csv`, 'text/plain');
      } catch (e) {
        console.error(
          'There was an error creating a csv from the parameters',
          e
        );
      }
    };

    return {
      titleFromSchema,
      uiOptionsFromSchema,
      job,
      property,
      params,
      schema,
      mappedValues,
      handleJSONDownload,
      handleCSVDownload,
    };
  },
});
