
import { defineComponent } from 'vue';
import { Job } from '@/clients/ops';
import { getJobStateDisplayString } from '@/pages/utils';
import { JOB_DETAILS_ROUTE } from '@/router/names';
import { getStartTimeFromTimeLog, getEndTimeFromTimeLog } from '../utils';
import { useRouter } from 'vue-router';
export default defineComponent({
  props: {
    jobs: Array,
  },
  setup() {
    const router = useRouter();
    function getStartTimeDisplay(job: Job): string {
      const starttime = getStartTimeFromTimeLog(job);
      if (starttime) {
        let prefix = '';
        if (starttime.estimate) {
          prefix = 'Estimated start time ';
        }
        return `${prefix}${starttime.time.toLocaleString()}`;
      }
      return 'Schedule this job to estimate a start time';
    }

    function getEndTimeDisplay(job: Job): string {
      const endtime = getEndTimeFromTimeLog(job);
      if (endtime) {
        let prefix = '';
        if (endtime.estimate) {
          prefix = 'Estimated end time ';
        }
        return `${prefix}${endtime.time.toLocaleString()}`;
      }
      return 'Schedule this job to estimate an end time';
    }

    return {
      getJobStateDisplayString,
      goToJob(job: Job) {
        router.push({
          name: JOB_DETAILS_ROUTE,
          params: { jobId: job.id },
        });
      },
      getStartTimeDisplay,
      getEndTimeDisplay,
      getCreatedBy(job: Job): string {
        return job.common?.createdBy || 'unknown user';
      },
    };
  },
});
