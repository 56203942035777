
import { defineComponent, computed, ref, PropType, ComputedRef } from 'vue';
import { SimplifiedState } from '@/clients/model';
import { CommandState } from '@/clients/ops/model';
import {
  isJobCancelable,
  isJobPausable,
  isJobResumable,
  isJobUnschedulable,
} from '@/pages/utils';

interface ActionOption {
  label: string;
  command: CommandState;
  disable: boolean;
}

interface ActionOptions {
  resume: ActionOption;
  pause: ActionOption;
  cancel: ActionOption;
  unschedule: ActionOption;
}

export default defineComponent({
  props: {
    state: {
      type: String as PropType<SimplifiedState>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selectedAction = ref('');
    const actionOptions: ComputedRef<ActionOptions> = computed(() => {
      return {
        resume: {
          label: 'RESUME',
          command: CommandState.RESUME,
          disable: !isJobResumable(props.state),
        },
        pause: {
          label: 'PAUSE',
          command: CommandState.PAUSE,
          disable: !isJobPausable(props.state),
        },
        cancel: {
          label: 'CANCEL',
          command: CommandState.CANCEL,
          disable: !isJobCancelable(props.state),
        },
        unschedule: {
          label: 'UNSCHEDULE',
          command: CommandState.UNSCHEDULE,
          disable: !isJobUnschedulable(props.state),
        },
      };
    });

    const handleCommand = (command: string): void => {
      emit('command', command);
    };

    return {
      selectedAction,
      actionOptions,
      handleCommand,
    };
  },
});
