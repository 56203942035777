
import {
  computed,
  defineComponent,
  ref,
  Ref,
  watch,
  nextTick,
  PropType,
} from 'vue';
import { isEqual } from 'lodash';
import { OutputUnit } from '@hyperjump/json-schema/draft-07';
import ConfigController, {
  EntityConfiguration,
  EntityType,
} from '@/clients/config';
import GenericForm from './GenericForm.vue';
import { RequestJSONSchema, SimpleResponse } from './types';
import { titleFromSchema } from './parse';
import { cloneDeep } from '@apollo/client/utilities';

// interface Props {
//   configType: EntityType;
//   configId: string;
//   contextId: string;
//   config?: EntityConfiguration;
//   retreiveConfig: boolean;
//   handleUpdate: boolean;
//   confirmEdit: boolean;
//   saveButtonText: string;
//   saveButtonPosition: 'top' | 'bottom';
//   hideButtonOnChange: boolean;
//   action?: (parameters) => void;
// }

export default defineComponent({
  props: {
    configType: {
      type: String as PropType<EntityType>,
      required: true,
    },
    configId: {
      type: String,
      required: false,
    },
    contextId: {
      type: String,
      required: false,
    },
    config: {
      type: Object as PropType<EntityConfiguration | null>,
      required: false,
    },
    retreiveConfig: {
      type: Boolean,
      default: true,
    },
    handleUpdate: {
      type: Boolean,
      default: false,
    },
    confirmEdit: {
      type: Boolean,
      default: false,
    },
    saveButtonText: {
      type: String,
      default: 'Save',
    },
    saveButtonPosition: {
      type: String,
      default: 'top',
    },
    hideButtonOnChange: {
      type: Boolean,
      default: false,
    },
    action: Function,
  },
  components: {
    GenericForm,
  },
  setup(props, { emit }) {
    let responseData: SimpleResponse = {};
    const noConfig = ref(false);
    const validationErrors = ref(false);
    const configChanged = ref(false);
    const readOnlyMode = ref(props.confirmEdit);
    const configSchema: Ref<RequestJSONSchema> = ref({});
    const configValues: Ref<SimpleResponse> = ref({});

    function getConfig() {
      if (
        props.retreiveConfig &&
        (props.configId || props.configType === EntityType.ENTITY_TYPE_ORG)
      ) {
        ConfigController.Instance.dispatchGetConfig(
          props.configType,
          props.configId || '',
          props.contextId
        )
          .then((config) => {
            noConfig.value = config.schemaVersion === 0;
            if (!noConfig.value) {
              configSchema.value = config.schema;
              configValues.value = config.configValues;
            }
          })
          .catch(() => {
            noConfig.value = true;
          });
      } else if (props.config) {
        noConfig.value = false;
        configSchema.value = props.config.schema;
        configValues.value = props.config.configValues;
        nextTick(() => {
          // let the initial values percolate through the emit chain
          // and then disable the save button because we _know_ we've just
          // loaded a brand new config so there can't be any changes yet.
          configChanged.value = false;
        });
      } else {
        noConfig.value = true;
      }
    }

    getConfig();

    const headerTitle = computed(
      () => titleFromSchema(configSchema.value) || 'Configuration'
    );

    const canSave = computed(
      () => !validationErrors.value && configChanged.value
    );

    function handleUpdateValues(newConfig: SimpleResponse) {
      if (!isEqual(configValues.value, newConfig)) {
        responseData = newConfig;
        configChanged.value = true;
        emit('update:responseData', newConfig);
        if (props.action) {
          props.action({ updateType: 'responseData', data: newConfig });
        }
      } else {
        configChanged.value = false;
      }
    }

    function handleValidationError(event: OutputUnit) {
      validationErrors.value = !event.valid;
      emit('error:responseData', event);
      if (props.action) {
        props.action({ updateType: 'responseError', data: event });
      }
    }

    async function handleButtonClick() {
      if (props.handleUpdate && props.configId) {
        await ConfigController.Instance.dispatchUpdateConfig(
          props.configType,
          props.configId,
          responseData
        );
        configValues.value = cloneDeep(responseData);
        configChanged.value = false;
        if (props.confirmEdit) {
          readOnlyMode.value = !readOnlyMode.value;
        }
      }
    }

    watch(
      () => props.configId,
      () => {
        getConfig();
      }
    );

    watch(
      () => props.config,
      () => {
        if (!props.retreiveConfig) {
          getConfig();
        }
      },
      { immediate: true, deep: true }
    );
    function cancelChanges() {
      readOnlyMode.value = true;
      // TODO: reset to initial values
    }

    return {
      configSchema,
      configValues,
      headerTitle,
      validationErrors,
      readOnlyMode,
      configChanged,
      noConfig,
      canSave,
      handleUpdateValues,
      handleValidationError,
      handleButtonClick,
      cancelChanges,
    };
  },
});
