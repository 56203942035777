import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b95a8b48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "job-summary" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  key: 1,
  class: "section-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_job_lifecycle_summary = _resolveComponent("job-lifecycle-summary")!
  const _component_pending_summary = _resolveComponent("pending-summary")!
  const _component_requests_details = _resolveComponent("requests-details")!
  const _component_params = _resolveComponent("params")!
  const _component_config_editor = _resolveComponent("config-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_job_lifecycle_summary, {
        job: _ctx.job,
        onUpdateSelectedJob: _ctx.updateSelectedJob
      }, null, 8, ["job", "onUpdateSelectedJob"]),
      (_ctx.isRequest)
        ? (_openBlock(), _createBlock(_component_pending_summary, {
            key: 0,
            job: _ctx.job,
            onCreateJob: _ctx.handleCreateJob,
            onUpdateSelectedJob: _ctx.updateSelectedJob
          }, null, 8, ["job", "onCreateJob", "onUpdateSelectedJob"]))
        : _createCommentVNode("", true),
      _createVNode(_component_requests_details, {
        job: _ctx.job,
        onUpdateSelectedJob: _ctx.updateSelectedJob
      }, null, 8, ["job", "onUpdateSelectedJob"]),
      _createVNode(_component_params, {
        job: _ctx.job,
        input: true
      }, null, 8, ["job"]),
      (_ctx.isJobFinished)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Configuration"))
        : _createCommentVNode("", true),
      (!_ctx.isRequest)
        ? (_openBlock(), _createBlock(_component_config_editor, {
            key: 2,
            configId: _ctx.job.id,
            configType: _ctx.EntityType.ENTITY_TYPE_JOB,
            "handle-update": !_ctx.isJobFinished,
            "confirm-edit": true,
            class: "job-config-editor"
          }, null, 8, ["configId", "configType", "handle-update"]))
        : _createCommentVNode("", true)
    ])
  ]))
}