
import { defineComponent, computed, ref, onUnmounted } from 'vue';
import SearchBar from '@/components/SearchBar.vue';

export default defineComponent({
  components: {
    SearchBar,
  },
  props: {
    metadata: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const close = () => {
      emit('update:metadata', null);
    };

    const opened = ref(false);
    const outsideClickClose = (event) => {
      if (
        opened.value &&
        (event.target.matches('.meta-data') || event.target.matches('.close'))
      ) {
        close();
      }
      opened.value = true;
    };

    document.addEventListener('click', outsideClickClose);
    onUnmounted(() => document.removeEventListener('click', outsideClickClose));

    const parsedData = computed(() => {
      try {
        const parsed = JSON.parse(props.metadata);
        return parsed;
      } catch (e) {
        console.log(e);
        return {};
      }
    });

    const nameFilterText = ref('');
    const updateNameFilterText = (text) => (nameFilterText.value = text);
    const valueFilterText = ref('');
    const valueNameFilterText = (text) => (valueFilterText.value = text);
    const sortAscending = ref(false);

    const filteredData = computed(() => {
      let filtered = parsedData.value;
      if (nameFilterText.value) {
        filtered = Object.keys(filtered)
          .filter((key) =>
            key.toLowerCase().includes(nameFilterText.value.toLowerCase())
          )
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: filtered[key] });
          }, {});
      }

      if (valueFilterText.value) {
        filtered = Object.keys(filtered)
          .filter((key) =>
            JSON.stringify(filtered[key])
              .toLowerCase()
              .includes(valueFilterText.value.toLowerCase())
          )
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: filtered[key] });
          }, {});
      }

      if (sortAscending.value) {
        filtered = Object.keys(filtered)
          .sort((a, b) => new Intl.Collator().compare(b, a))
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: filtered[key] });
          }, {});
      } else {
        filtered = Object.keys(filtered)
          .sort((a, b) => new Intl.Collator().compare(a, b))
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: filtered[key] });
          }, {});
      }

      return filtered;
    });

    return {
      close,
      filteredData,
      nameFilterText,
      updateNameFilterText,
      valueFilterText,
      valueNameFilterText,
      sortAscending,
    };
  },
});
