
import { defineComponent, ref, Ref, PropType, computed } from 'vue';
import { Action } from '@/clients/action';
import { ActionType } from '@/clients/action/model';
import { Job } from '@/clients/ops/model';
import OpsController from '@/clients/ops/controller';
import NotesInput from '@/components/NotesInput.vue';

export interface RetryActionData {
  action: Action | null;
  job: Job | null;
  labId: string | null;
  error: string | null;
}

export default defineComponent({
  components: {
    NotesInput,
  },
  props: {
    data: {
      type: Object as PropType<RetryActionData | null>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const actionName = computed(() => props.data?.action?.name || '');
    const jobName = computed(() => props.data?.job?.name || '');
    const isWorkflow = computed(
      () => props?.data?.job?.action?.actionType === ActionType.WORKFLOW
    );
    const workflowName = computed(() => props?.data?.job?.action?.name || '');
    const disableSubmit = computed(() => props.data?.action === null);

    const retryError: Ref<Error | null> = ref(null);
    const noteInput = ref('');

    const errorMessage = computed(() => {
      if (retryError.value) {
        return retryError.value.message;
      }

      if (!props.data?.action) {
        return 'While the job was in an error state, no action was found in an error state.';
      }
    });

    const close = () => emit('close');

    const addNote = () => {
      if (noteInput.value && props.data?.job) {
        OpsController.Instance.dispatchAddJobNote(
          props.data.job.id,
          noteInput.value
        );
      }
    };

    const submitRetry = async () => {
      if (props.data?.action && props.data.labId) {
        try {
          await OpsController.Instance.dispatchActionReset(
            props.data.labId,
            props.data.action.id
          );
          addNote();
          close();
        } catch (e) {
          retryError.value = e as Error;
        }
      }
    };
    return {
      actionName,
      jobName,
      isWorkflow,
      workflowName,
      disableSubmit,
      errorMessage,
      noteInput,
      close,
      submitRetry,
    };
  },
});
