
import { defineComponent, PropType, ShallowRef, Component } from 'vue';

export interface TreeData {
  id: string;
  label: string;
  component?: ShallowRef<Component>;
  items?: TreeData[];
  artificialOnly?: boolean;
}

export default defineComponent({
  props: {
    data: Array as PropType<TreeData[]>,
    selected: String as PropType<string>,
  },
  emits: ['select-item'],
  setup(props, { emit }) {
    const handleClickItem = (id) => {
      emit('select-item', id);
    };

    const isSelected = (id) => props.selected === id;

    return {
      handleClickItem,
      isSelected,
    };
  },
});
