
import { defineComponent, ref } from 'vue';
import CategorySelector from '@/components/CategorySelector.vue';
import AssistantsTable from './AssistantsTable.vue';
import ActivityTable from './ActivityTable.vue';
import RequestsTable from './RequestsTable.vue';
import Notes from '@/pages/Ops/components/JobDetails/Notes.vue';
import AsstIcon from '@/assets/icons/assistant.svg';
import ActivityIcon from '@/assets/icons/vector.svg';
import RequestsIcon from '@/assets/icons/requests.svg';
import NotesIcon from '@/assets/icons/notes.svg';
// import LogsIcon from '@/assets/icons/logs.svg';

export default defineComponent({
  components: {
    CategorySelector,
    RequestsTable,
    Notes,
  },
  props: {
    job: Object,
  },
  setup() {
    const categories = [
      {
        name: 'activity',
        display: 'Activity',
        image: ActivityIcon,
        component: ActivityTable,
      },
      {
        name: 'assistants',
        display: 'Assistants',
        image: AsstIcon,
        component: AssistantsTable,
      },
      {
        name: 'requests',
        display: 'Requests',
        image: RequestsIcon,
        component: RequestsTable,
      },
      {
        name: 'notes',
        display: 'Notes',
        image: NotesIcon,
        component: Notes,
      },
      // {
      //   name: 'logs',
      //   display: 'System Logs',
      //   image: LogsIcon,
      //   component: AssistantsTable,
      // },
    ];
    const selectedCategory = ref(categories[0]);
    const updateSelectedCategory = (category) =>
      (selectedCategory.value = category);

    return {
      categories,
      selectedCategory,
      updateSelectedCategory,
    };
  },
});
