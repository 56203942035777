import {
  RequestJSONSchema,
  SimpleResponse,
} from '@/components/GenericForm/types';
import { cloneDeepWith, isString } from 'lodash';
import { morphism, createSchema } from 'morphism';

export enum EntityType {
  ENTITY_TYPE_ADAPTER = 'ENTITY_TYPE_ADAPTER',
  ENTITY_TYPE_JOB = 'ENTITY_TYPE_JOB',
  ENTITY_TYPE_LAB = 'ENTITY_TYPE_LAB',
  ENTITY_TYPE_ORG = 'ENTITY_TYPE_ORG',
  ENTITY_TYPE_UNKNOWN = 'ENTITY_TYPE_UNKNOWN',
  ENTITY_TYPE_WORKFLOW = 'ENTITY_TYPE_WORKFLOW',
}

interface JSONValue {
  value: string;
}

// this is what is returned by config-service
export interface ConfigBackend {
  configValuesTimestamp: string;
  configValuesVersion: number;
  configValuesDocument: string;
  entityId: string;
  entityType: EntityType;
  labId: string;
  orgId: string;
  schemaTimestamp: string;
  schemaVersion: number;
  schemaDocument: string;
  resolved: boolean;
}

// this is the new version of the config as returned by the adapter API
export interface AdapterConfigBackend {
  configValuesTimestampISO: string;
  configValuesVersion: number;
  configValuesDocument: JSONValue;
  schemaTimestampISO: string;
  schemaVersion: number;
  schemaDocument: JSONValue;
  resolved: boolean;
}

export interface EntityConfiguration {
  configValuesTimestamp: Date;
  configValuesVersion: number;
  configValues: SimpleResponse;
  entityId: string;
  entityType: EntityType;
  labId: string;
  orgId: string;
  schemaTimestamp: Date;
  schemaVersion: number;
  schema: RequestJSONSchema;
  resolved: boolean;
}

export interface SemanticVersion {
  major: number;
  minor: number;
  patch: number;
  build?: string;
}

export interface ServiceVersion {
  name: string;
  version: string;
  semanticVersion?: SemanticVersion;
}

export function escapeStringFieldVale(input: string): string {
  // for now, we have a use case where some fields have "\" characters in them
  // e.g. windows paths
  return input.replaceAll('\\', '\\\\');
}

export function unescapeStringFieldValue(input: string): string {
  return input.replaceAll('\\\\', '\\');
}

export function customCloneFrom(val: unknown): unknown | void {
  if (isString(val)) {
    return escapeStringFieldVale(val);
  }
}

function customCloneTo(val: unknown): unknown | void {
  if (isString(val)) {
    return unescapeStringFieldValue(val);
  }
}

/**
 * Schema to map ConfigBackend (BE representation) to EntityConfiguration (FE representation)
 */
const mapFromSchema = createSchema<EntityConfiguration, ConfigBackend>({
  configValuesTimestamp: (input) => new Date(input.configValuesTimestamp),
  configValuesVersion: 'configValuesVersion',
  configValues: (input) => {
    try {
      const values = JSON.parse(input.configValuesDocument);
      // this will escape relevent characters for display purposes
      return cloneDeepWith(values, customCloneFrom);
    } catch (_) {
      console.warn('invalid configValuesDocument', input.configValuesDocument);
      return {};
    }
  },
  entityId: 'entityId',
  entityType: 'entityType',
  labId: 'labId',
  orgId: 'orgId',
  schemaTimestamp: (input) => new Date(input.schemaTimestamp),
  schemaVersion: 'schemaVersion',
  schema: (input) => {
    try {
      const s = JSON.parse(input.schemaDocument);
      s.$schema = 'http://json-schema.org/draft-07/schema#';
      return s;
    } catch (_) {
      console.warn('invalid config schema', input.schemaDocument);
      return {};
    }
  },
  resolved: 'resolved',
});

/**
 * Schema to map EntityConfiguration (FE representation) to ConfigBackend (BE representation)
 */
const mapToSchema = createSchema<ConfigBackend, EntityConfiguration>({
  configValuesTimestamp: (input) => input.configValuesTimestamp.toISOString(),
  configValuesVersion: 'configValuesVersion',
  configValuesDocument: (input) => {
    // this will unescape relevent characters for storage purposes
    const values = cloneDeepWith(input.configValues, customCloneTo);
    return JSON.stringify(values);
  },
  entityId: 'entityId',
  entityType: 'entityType',
  labId: 'labId',
  orgId: 'orgId',
  schemaTimestamp: (input) => input.schemaTimestamp.toISOString(),
  schemaVersion: 'schemaVersion',
  schemaDocument: (input) => {
    return JSON.stringify(input.schema);
  },
  resolved: 'resolved',
});

export function mapFromBackend(source: ConfigBackend): EntityConfiguration {
  const config = morphism(mapFromSchema, source);
  return config;
}

export function mapToBackend(source: EntityConfiguration): ConfigBackend {
  const config = morphism(mapToSchema, source);
  return config;
}

export function nullConfig(): EntityConfiguration {
  return {
    configValuesTimestamp: new Date(),
    configValuesVersion: 0,
    configValues: {},
    entityId: '',
    entityType: EntityType.ENTITY_TYPE_UNKNOWN,
    labId: '',
    orgId: '',
    schemaTimestamp: new Date(),
    schemaVersion: 0,
    schema: {},
    resolved: false,
  };
}
