
import { defineComponent, computed } from 'vue';
import UIController from '@/clients/ui';
import { SimplifiedState } from '@/clients/model';
import RunningDots from '@/components/RunningDots.vue';

export default defineComponent({
  components: {
    RunningDots,
  },
  props: {
    label: String,
    id: String,
    state: String,
    allowActions: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'lg',
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const isInFlight = computed(() =>
      UIController.Instance.isGqlInFlight('FinishAssistant')
    );
    const isErrorLogOpen = computed(
      () => UIController.Instance.displayErrorLog
    );

    const isRunnable = computed(
      () =>
        props.state === SimplifiedState.RUNNING_NEED_ASSISTANCE ||
        props.state === SimplifiedState.RUNNING_WITH_ASSISTANCE
    );

    const skipAssistant = () => {
      if (!isInFlight.value) {
        emit('skipAssistant');
      }
    };

    const goToAssistant = () => emit('goToAssistant');
    const retryAction = () => emit('retryAssistant');
    const openErrorLog = () => emit('openErrorLog');

    const runMainAction = () => {
      if (
        props.state === SimplifiedState.RUNNING_NEED_ASSISTANCE ||
        props.state === SimplifiedState.RUNNING_WITH_ASSISTANCE
      ) {
        goToAssistant();
      }
    };

    const handleCommand = (command: string) => {
      if (command === 'run') {
        goToAssistant();
      } else if (command === 'skip') {
        skipAssistant();
      } else if (command === 'retry') {
        retryAction();
      } else if (command === 'logs') {
        openErrorLog();
      }
    };

    const hasActions = computed(() => {
      return (
        props.state === SimplifiedState.RUNNING ||
        props.state === SimplifiedState.RUNNING_NEED_ASSISTANCE ||
        props.state === SimplifiedState.RUNNING_WITH_ASSISTANCE ||
        props.state === SimplifiedState.RUNNING_WITH_ERROR
      );
    });

    return {
      handleCommand,
      isInFlight,
      isErrorLogOpen,
      isRunnable,
      runMainAction,
      hasActions,
      SimplifiedState,
    };
  },
});
