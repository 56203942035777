export interface ComplianceData {
  cookiesAccepted: boolean | null;
  digitalSignatureAccepted: boolean | null;
}

export interface UserExt {
  hasLoggedIn: boolean;
  roles: UserRole[];
}

export interface User {
  id: string;
  name: string;
  email: string;
  orgId: string;
  profileImage: string;
  companyLogo: string;
  totpEnabled: boolean;
  userExt: UserExt;
  complianceData: ComplianceData;
}

export enum UserRole {
  ADMIN = 'USER_ROLE_ADMIN',
  MEMBER = 'USER_ROLE_MEMBER',
  ARTIFICIAL = 'USER_ROLE_INTERNAL_DEVELOPER',
  NONE = 'USER_ROLE_NO_ACCESS',
}

export function nullUser(): User {
  return {
    id: '',
    name: '',
    email: '',
    orgId: '',
    profileImage: '',
    companyLogo: '',
    totpEnabled: false,
    userExt: {
      hasLoggedIn: false,
      roles: [],
    },
    complianceData: {
      cookiesAccepted: true, // default to true so we don't keep showing the cookie banner
      digitalSignatureAccepted: null,
    },
  };
}
