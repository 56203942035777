import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/caret.svg'
import _imports_1 from '@/assets/icons/search.svg'


const _withScopeId = n => (_pushScopeId("data-v-28dee9b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "select-search",
  ref: "selectSearch",
  id: "select-search"
}
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "options-search" }
const _hoisted_4 = {
  key: 0,
  src: _imports_1
}
const _hoisted_5 = { class: "options-list" }
const _hoisted_6 = ["tabindex", "onKeydown", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['select-search__selected', { disabled: _ctx.disabled }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShowOptions && _ctx.toggleShowOptions(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(['current-selection', { disabled: _ctx.disabled }])
      }, _toDisplayString(_ctx.selected), 3),
      (!_ctx.disabled)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _imports_0,
            alt: "down-arrow",
            class: _normalizeClass({ active: _ctx.showOptions })
          }, null, 2))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(['select-search__options', { active: _ctx.showOptions }]),
      id: `search-options-${_ctx.searchGUID}`
    }, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputString) = $event)),
          onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.inputFocus = true)),
          onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.inputFocus = false)),
          tabindex: "1",
          ref: "searchInput"
        }, null, 544), [
          [_vModelText, _ctx.inputString]
        ]),
        (!_ctx.inputFocus && !_ctx.inputString)
          ? (_openBlock(), _createElementBlock("img", _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (value, key, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "search-option tabable",
            key: key,
            tabindex: (index + 2).toString(),
            onKeydown: [
              _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.moveToPrevious && _ctx.moveToPrevious(...args)), ["up"])),
              _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.moveToNext && _ctx.moveToNext(...args)), ["down"])),
              _withKeys(($event: any) => (_ctx.selectOption(key)), ["enter"])
            ],
            onClick: ($event: any) => (_ctx.selectOption(key))
          }, _toDisplayString(value), 41, _hoisted_6))
        }), 128))
      ])
    ], 10, _hoisted_2)
  ], 512))
}