
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    errors: {
      required: true,
      default: [],
      type: Array,
    },
  },
});
