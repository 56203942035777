
import { map } from 'lodash';
import { defineComponent, computed, PropType } from 'vue';

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    metadata: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const metadataArray = computed(() => {
      if (props.metadata) {
        return map(props.metadata, (v, k) => ({ key: k, value: v }));
      }
      return [];
    });
    return {
      metadataArray,
      handleClose() {
        emit('close');
      },
    };
  },
});
