import { reactive } from 'vue';
import { Message, MessageBusOptions } from './model';

interface IState {
  postMessage: Message | null;
}

export default class Controller {
  private static instance: Controller;
  private state: IState;
  receiverId: string;
  sender: string;
  listener: void;

  private constructor() {
    this.state = reactive({
      postMessage: null,
    });
    this.receiverId = 'vizualizer';
    this.sender = 'dashboard';
    this.listener = window.addEventListener('message', (event) => {
      const msg: Message = event.data;
      if (msg.to === this.sender) {
        this.state['postMessage'] = msg;
      }
    });
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }
    return Controller.instance;
  }

  public send(message: Message, options?: MessageBusOptions) {
    const rId = options?.receiverId ?? this.receiverId;
    const senderName = options?.sender ?? this.sender;
    const origin = options?.useOrigin ? location.origin : '*';
    const payload = {
      ...message,
      sender: senderName,
      receiverId: rId,
    };
    if (rId) {
      const receiver: HTMLIFrameElement | null = document.getElementById(
        rId
      ) as HTMLIFrameElement;
      if (receiver && receiver.contentWindow) {
        // @ts-ignore
        receiver.contentWindow.postMessage(payload, origin);
      }
    } else {
      window.parent.postMessage(payload, origin);
    }
  }

  get message() {
    return this.state.postMessage;
  }
}
