
import _ from 'lodash';
import { defineComponent, computed } from 'vue';
import UIController from '@/clients/ui/controller';
import Management from './Management.vue';
import Help from './Help.vue';
import Filepath from './Filepath.vue';
import * as ROUTE_NAMES from '@/router/names';
import { Wormhole } from 'portal-vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  components: {
    Management,
    Filepath,
    Help,
  },
  props: {
    showRightArtificialIcon: Boolean,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const canGoBack = computed(() => route.meta && route.meta.canGoBack);
    const useTecanBranding =
      UIController.Instance.hasFeatureFlag('USE_TECAN_BRANDING');
    const showComplianceModeIcon =
      UIController.Instance.hasFeatureFlag('COMPLIANCE_MODE');

    const path = computed(() => {
      let title = '';
      if (route.meta?.title) {
        title = route.meta.title.toString();
      } else if (route.meta?.breadcrumbName instanceof Function) {
        title = route.meta?.breadcrumbName(route);
      } else if (route.meta?.breadcrumbName) {
        title = route.meta?.breadcrumbName.toString();
      }

      const query = route.query.t;
      if (title !== 'aLab') {
        if (route.params.assistantId) {
          return route.meta?.breadcrumbName(route);
        }
        return title;
      }
      return _.upperFirst(query as string);
    });

    const showBackArrow = () => {
      return route.name === ROUTE_NAMES.LAB_EDITOR_ROUTE;
    };

    const expertMode = computed(() => UIController.Instance.expert);
    // portal-vue didn't provide all the necessary types for wormhole,
    // so just ignore it until this gets swapped for Vue 3's internal Teleport
    // @ts-ignore
    const portalHasContent = (target) => Wormhole.hasContentFor(target);

    const goToArtificial = () => {
      window.open('https://artificial.com/', '_blank');
    };

    return {
      useTecanBranding,
      showComplianceModeIcon,
      path,
      clickHome() {
        router.push('/');
      },
      goToRoute(route) {
        router.push({
          name: route.name,
          params: { ...route.params },
          query: { ...route.query },
        });
      },
      expertMode,
      portalHasContent,
      showBackArrow,
      canGoBack,
      goBack() {
        history.back();
      },
      goToArtificial,
    };
  },
});
