
import { PropType, defineComponent, ref, watch } from 'vue';

export interface BaseJobFields {
  name: string;
  description: string;
}

export default defineComponent({
  props: {
    name: String,
    description: String,
    action: Function as PropType<
      ({ name, description }: BaseJobFields) => void
    >,
    inReview: Boolean,
  },
  setup(props) {
    const localName = ref(props.name || '');
    const localDescription = ref(props.description || '');
    // we need to watch these because this component is instantiated before the name
    // is generated from the workflow + a hash
    watch(
      () => props.name,
      () => {
        localName.value = props.name || '';
      }
    );
    watch(
      () => props.description,
      () => {
        localDescription.value = props.description || '';
      }
    );
    return {
      localName,
      localDescription,
      updateBaseInfo() {
        if (props.action) {
          props.action({
            name: localName.value,
            description: localDescription.value,
          });
        }
      },
    };
  },
});
