import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d24575b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "data-records__resources" }
const _hoisted_2 = { class: "category-filters" }
const _hoisted_3 = { class: "table-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_category_selector = _resolveComponent("category-selector")!
  const _component_inputs_outputs_table = _resolveComponent("inputs-outputs-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_category_selector, {
        categories: _ctx.categories,
        fontInter: true,
        onSetCategory: _ctx.updateSelectedCategory
      }, null, 8, ["categories", "onSetCategory"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.selectedCategory.name === 'inputs')
        ? (_openBlock(), _createBlock(_component_inputs_outputs_table, {
            key: 0,
            io: _ctx.job.inputs
          }, null, 8, ["io"]))
        : (_openBlock(), _createBlock(_component_inputs_outputs_table, {
            key: 1,
            io: _ctx.job.outputs
          }, null, 8, ["io"]))
    ])
  ]))
}